<template>
    <div class="modal modal-width-fixed modal-change-password on">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-nav">
                        <a class="back"><i /><span class="blind">뒤로가기</span></a>
                    </div>
                    <div class="header-title">
                        <span>비밀번호 변경</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->

            <!-- modal-body -->
            <div class="modal-body">
                <div class="form-box">
                    <div class="field-box">
                        <div class="label">
                            현재 비밀번호
                        </div>
                        <div class="field">
                            <input
                                v-model="worker.passwd"
                                type="password"
                                class="txt"
                                placeholder="현재 비밀번호를 입력하세요."
                            />
                        </div>
                    </div>
                    <div class="field-box">
                        <div class="label">
                            새 비밀번호
                        </div>
                        <div class="field">
                            <input
                                v-model="worker.newpasswd"
                                type="password"
                                class="txt"
                                placeholder="새 비밀번호를 입력하세요."
                            />
                        </div>
                    </div>
                    <div class="field-box">
                        <div class="label">
                            비밀번호 재입력
                        </div>
                        <div class="field">
                            <input
                                v-model="worker.renewpasswd"
                                type="password"
                                class="txt"
                                placeholder="비밀번호를 한번 더 입력하세요."
                            />
                        </div>
                    </div>
                    <div class="btn-wrapper">
                        <a class="modal-close" @click="close"><span>취소</span></a>
                        <a class="modal-confirm" @click="passwd"><span>확인</span></a>
                    </div>
                </div>
            </div>
            <!-- // modal-body -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import WorkerApi from '../../api/WorkerApi';

export default {
    name: 'Passwd',
    components: {},
    data: () => {
        return {
            worker: {
                passwd: '',
                newpasswd: '',
                renewpasswd: ''
            }
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        close() {
            this.$emit('update:show', false);
        },
        async passwd() {
            if (!this.worker.passwd.trim()) {
                alert('현재 비밀번호를 입력하세요.');
                return;
            }

            if (this.worker.newpasswd !== this.worker.renewpasswd) {
                alert('새로 입력하신 비밀번호가 일치하지 않습니다.');
                return;
            }
            await WorkerApi.passwd(this.basic.id, this.worker);
            this.close();
            //이 부분의 오류,성공처리에 대해서 공통작업이 필요함
        }
    }
};
</script>
