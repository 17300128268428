import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';
import authHeader from './Header';

const detail = workerId =>
    httpClient.get(`/appsetting/${workerId}`, { headers: authHeader() }).then(res => res.data.content);
const upsert = param => httpClient.post(`/appsetting`, param, { headers: authHeader() }).then(res => res.data);

export default {
    detail: wrapHttpClient(detail),
    upsert: wrapHttpClient(upsert)
};
