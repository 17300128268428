<template>
    <div ref="filterShareRoomLayer" class="modal modal-width-fixed modal-select-copygroup">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-title">
                        <span>그룹 공유</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="confirm" @click="shareWork"><span>확인</span></a>
                    <span class="gap" />
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->

            <!-- modal-body -->
            <div class="modal-body">
                <div class="search-box">
                    <div class="search">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="그룹명, 방장 검색"
                            @keyup="setFilterRoom"
                        />
                    </div>
                </div>

                <div class="list-box">
                    <div class="select-box">
                        <input id="chk-copygroup-all" type="checkbox" class="sel-user" @click="allSelect()" />
                        <label for="chk-copygroup-all">전체선택</label>
                    </div>
                    <div class="list-scroll-box">
                        <div class="user-list">
                            <ul>
                                <li v-for="(room, index) in filterRooms" :key="index">
                                    <input
                                        :id="'chk-copygroup-' + index"
                                        v-model="roomIds"
                                        type="checkbox"
                                        class="sel-user"
                                        :value="room.id"
                                    />
                                    <label class="user-summary" :for="'chk-copygroup-' + index">
                                        <div class="user-thumb">
                                            <span class="thumb">
                                                <img
                                                    :src="room.master.imgUrl | profileUrl"
                                                    onerror="javascript:this.src='/images/example/img-profile.png'"
                                                />
                                            </span>
                                        </div>
                                        <div class="user-info">
                                            <p class="user-nm">{{ room.name }}</p>
                                            <div class="user-member">
                                                <div>
                                                    <span class="owner">방장 {{ room.master.name }}</span>
                                                    <span class="member">{{ substr(room.workers) }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- // modal-body -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
//import { WORK_TYPE, WORK_TYPE_CSS } from '../../const';
import WorkApi from '../../api/WorkApi';
export default {
    name: 'ShareRoom',
    components: {},
    data: () => {
        return {
            workId: null,
            filterRooms: [],
            room: {},
            searchText: '',
            roomIds: [],
            isAll: false
        };
    },
    computed: {
        ...mapState({
            rooms: state => state.work.roomList,
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        this.bind();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['getMyRooms', 'getWorkOfRoom', 'showPopShareRoom']),

        setFilterRoom() {
            this.filterRooms = this.rooms.filter(
                room => room.name.indexOf(this.searchText) > -1 || room.master.name.indexOf(this.searchText) > -1
            );
        },
        delSearchText() {
            this.searchText = '';
            this.setFilterRoom();
        },
        allSelect() {
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.roomIds = this.filterRooms.map(item => item.id);
            } else {
                this.roomIds = [];
            }
        },
        bind() {
            //await this.getMyRooms();
            this.filterRooms = this.rooms.map(item => item);
        },
        close() {
            //this.showPopShareRoom(false);
            this.$refs.filterShareRoomLayer.classList.remove('on');
        },
        substr(value) {
            if (value && value.length > 20) return value.substring(0, 20) + '..';
            return value;
        },
        open(id) {
            this.isAll = false;
            this.searchText = '';
            this.workId = id;
            this.bind();
            this.$refs.filterShareRoomLayer.classList.add('on');
        },
        async shareWork() {
            const param = this.roomIds.map(roomId => {
                return { roomId, workId: this.workId };
            });
            await WorkApi.shareWork(param);
            alert('공유하였습니다.');
            this.close();
        }
    }
};
</script>
