export default {
    SET_MY_ROOMS: 'SET_MY_ROOMS',
    REMOVE_MY_ROOMS: 'REMOVE_MY_ROOMS',
    ADD_MY_ROOM_CNT: 'ADD_MY_ROOM_CNT',
    ADD_MY_ALARM_CNT: 'ADD_MY_ALARM_CNT',
    RESET_MY_ROOM_CNT: 'RESET_MY_ROOM_CNT',
    SET_WORK: 'SET_WORK',
    SET_SHOW_WORK: 'SET_SHOW_WORK',
    SET_WORK_OF_ROOM: 'SET_WORK_OF_ROOM',
    ADD_WORK_OF_ROOM: 'ADD_WORK_OF_ROOM',
    SET_PICTURE: 'SET_PICTURE',
    SET_WORK_OF_SEARCH: 'SET_WORK_OF_SEARCH',
    SET_SEARCH_CONDITION: 'SET_SEARCH_CONDITION',
    REMOVE_WORK_OF_ROOM: 'REMOVE_WORK_OF_ROOM',
    SET_TEMP_WORK_CONTENT: 'SET_TEMP_WORK_CONTENT',
    SET_TEMP_WORK_DOCFILE: 'SET_TEMP_WORK_DOCFILE',
    /* set comment */
    SET_SHOW_POP_MANAGE_ROOM: 'SET_SHOW_POP_MANAGE_ROOM',
    SET_SHOW_POP_ADD_WORKER: 'SET_SHOW_POP_ADD_WORKER',
    SET_SHOW_POP_LEAVE_ROOM: 'SET_SHOW_POP_LEAVE_ROOM',
    SET_SHOW_POP_ADD_ROOM: 'SET_SHOW_POP_ADD_ROOM',
    SET_SHOW_POP_SEND_HP: 'SET_SHOW_POP_SEND_HP',
    SET_SHOW_POP_SHARE_ROOM: 'SET_SHOW_POP_SHARE_ROOM',
    /* set id */
    SET_WORK_ID: 'SET_WORK_ID',
    SET_COMMENT_ID: 'SET_COMMENT_ID',
    RESET_ID: 'RESET_ID',
    SET_SHOW_TYPE: 'SET_SHOW_TYPE',
    SET_ACTION_ID: 'SET_ACTION_ID',
    SET_SOCKET_CLT: 'SET_SOCKET_CLT',
    SET_SELCT_ROOM: 'SET_SELCT_ROOM'
};
