<template>
    <div class="content-side-wrapper on">
        <!-- content-header -->
        <header class="content-header">
            <div class="header-box">
                <div class="header-nav">
                    <a class="back" @click="back"><i /><span class="blind">뒤로가기</span></a>
                </div>
                <div class="header-title">
                    <span>{{ boardName[boardType] }} {{ bid ? '수정' : '등록' }}</span>
                </div>
            </div>
            <div class="header-util">
                <a class="write-cancel" @click="refresh"><span>취소</span></a>
                <a class="write-confirm" @click="regist"><span>확인</span></a>
            </div>
        </header>
        <!-- // content-header -->

        <div id="toolbar" class="notice-write-wrapper editor-box">
            <div class="write-subject">
                <input
                    ref="boardTitle"
                    v-model="board.title"
                    type="text"
                    class="txt"
                    placeholder="제목을 입력하세요."
                />
            </div>
            <div class="write-color-box">
                <ul>
                    <li>
                        <a @click="setColor('#000000')">
                            <span class="color-1" />
                        </a>
                    </li>
                    <li>
                        <a @click="setColor('#0070c0')">
                            <span class="color-2" />
                        </a>
                    </li>
                    <li>
                        <a @click="setColor('#ff0000')">
                            <span class="color-3" />
                        </a>
                    </li>
                    <li>
                        <a @click="setColor('#ffc000')">
                            <span class="color-4" />
                        </a>
                    </li>
                    <li>
                        <a @click="setColor('#00b050')">
                            <span class="color-5" />
                        </a>
                    </li>
                    <li>
                        <a @click="setColor('#7030a0')">
                            <span class="color-6" />
                        </a>
                    </li>
                    <li class="gap" />
                    <li>
                        <button style="float: none;" class="tool-bold ql-bold" data-style="bold">
                            <span class="bold" /><span class="blind">진하게</span>
                        </button>
                    </li>
                    <li>
                        <button style="float: none;" href="#" class="tool-italic ql-italic" data-style="italic">
                            <span class="italic" /><span class="blind">기울기</span>
                        </button>
                    </li>
                    <li>
                        <button style="float: none;" href="#" class="tool-under ql-underline" data-style="underline">
                            <span class="underline" /><span class="blind">기울기</span>
                        </button>
                    </li>
                    <li class="gap" />
                    <li>
                        <a @click="pickerFile">
                            <span class="attach" />
                            <span class="blind">첨부파일</span>
                        </a>
                    </li>
                </ul>
            </div>

            <!-- tag-file-list-box -->
            <div class="tag-file-list-box">
                <ul>
                    <li v-for="(file, index) in showFileList" :key="index">
                        <span class="filename">
                            {{ file.name }}
                            <a class="del-file" @click="onClickFileDel(file)">
                                <span class="blind">삭제</span>
                            </a>
                        </span>
                    </li>
                </ul>
            </div>
            <!-- // tag-file-list-box -->

            <div class="write-memo">
                <quill-editor
                    ref="myQuillEditor"
                    v-model="board.content"
                    style="height: 95%;margin-left: 15px;"
                    :options="editorOption"
                    @blur="onEditorBlur($event)"
                    @focus="onEditorFocus($event)"
                    @ready="onEditorReady($event)"
                />
            </div>
        </div>

        <input
            id="docFile"
            ref="docFile"
            type="file"
            :accept="DOC_ALLOW_FILE"
            class="blind"
            @change="doDocFileAttach"
        />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
//import Header from '../header/RegistHeader.vue';
import BoardApi from '../../api/BoardApi';
import ArrayHelper from '../../helper/ArrayHelper';
import FileHelper from '../../helper/FileHelper';

//import { alertDialog } from '../../helper/DialogHelper';

//import MenuHelper from '../../helper/MenuHelper';
import FileApi from '../../api/FileApi';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';
export default {
    name: 'NoticeRegist',
    components: { quillEditor },
    props: {
        bid: {
            type: Number,
            required: false,
            default: null
        }
    },
    data: () => {
        return {
            //bid: null,
            board: {},
            boardName: { notice: '공지', qna: '기술문의' },
            boardType: '',
            editorOption: {
                theme: 'snow',
                modules: {
                    toolbar: '#toolbar'
                }
            },
            content: '',
            addFileList: [],
            delFileList: [],
            showFileList: [],
            DOC_ALLOW_FILE: FileHelper.allowFileExt(),
            fileId: 0,
            fileNo: 0,
            test: ''
        };
    },
    computed: {
        ...mapState({}),
        editor() {
            return this.$refs.myQuillEditor.quill;
        }
    },
    watch: {
        bid: {
            // the callback will be called immediately after the start of the observation
            immediate: true,
            handler(val) {
                if (val) {
                    this.bid = val;
                    this.detail();
                }
            }
        }
    },
    created() {
        this.boardType = this.$route.name.toLowerCase();
    },
    mounted() {
        //this.bid = this.$route.params.id;
        this.boardType = this.$route.meta.type;
        this.board.type = this.boardType;
        this.board.noticeYn = 'N';
        //this.makeQuillUpload(this.editor);
        this.detail();
    },
    beforeDestroy() {
        //console.log('event제거');
    },
    methods: {
        ...mapActions(['showLoading', 'hideLoading']),
        back() {
            this.$emit('back');
        },
        refresh() {
            if (this.bid) this.$emit('refresh', this.bid);
            else this.$emit('back');
        },
        detail() {
            if (this.bid) {
                BoardApi.detail(this.bid).then(res => {
                    this.board = res;
                    if (this.board.fileId) {
                        this.fileId = this.board.fileId;
                        FileApi.list(this.board.fileId).then(fileRes => {
                            this.showFileList = fileRes.map(item => {
                                return { fileId: item.id.fileId, fileNo: item.id.fileNo, name: item.name };
                            });
                            this.fileNo = this.showFileList[this.showFileList.length - 1].fileNo;
                        });
                    }
                    //
                });
            } else {
                this.board = {};
            }
        },
        pickerFile() {
            if (FileHelper.isCount(this.showFileList.length)) this.$refs.docFile.click();
        },
        doDocFileAttach() {
            const fileObject = this.$refs.docFile.files[0];

            if (fileObject) {
                const size = fileObject.size;
                if (FileHelper.isNewSize(size, fileObject.name) && FileHelper.isAllowFile(fileObject.name)) {
                    ++this.fileNo;
                    const formData = new FormData();
                    formData.append('file_id', this.fileId || '');
                    formData.append('file_no', this.fileNo);
                    formData.append('file', fileObject);
                    const addFile = {
                        fileNo: this.fileNo,
                        form: formData
                    };
                    this.addFileList.push(addFile);
                    const fileDetail = {
                        name: fileObject.name,
                        fileId: this.fileId,
                        fileNo: this.fileNo
                    };
                    this.showFileList.push(fileDetail);
                }
            }

            //console.log(this.showFileList);
        },
        onClickFileDel(fileInfo) {
            if (fileInfo.fileId > 0) {
                this.delFileList.push(fileInfo);
            }
            const condition = item => item.fileNo === fileInfo.fileNo;
            ArrayHelper.oneRemove(this.showFileList, condition);
            ArrayHelper.oneRemove(this.addFileList, condition);
        },
        check(board) {
            BoardApi.check(board.id).then(() => {
                const condition = item => item.id === board.id;
                ArrayHelper.oneRemove(this.board, condition);
            });
        },
        async regist() {
            if (!this.board.title) {
                alert('제목을 입력하세요');
                this.$refs.boardTitle.focus();
                return;
            }
            //파일 삭제
            this.showLoading();
            for (var delFile of this.delFileList) {
                delFile.file_id = delFile.fileId;
                delFile.file_no = delFile.fileNo;
                const res = await FileApi.remove(delFile);
                if (res.content) {
                    this.fileId = res.content[0].id.fileId;
                    this.board.fileId = this.fileId;
                }
            }
            //파일 추가

            for (var docFile of this.addFileList) {
                const docFileForm = docFile.form;
                if (this.fileId > 0) docFileForm.set('file_id', this.fileId);
                const res = await FileApi.fileUpload(docFileForm, this.board.type);
                if (res.content) {
                    this.fileId = res.content[0].id.fileId;
                    this.board.fileId = this.fileId;
                }
            }
            this.board.type = this.boardType;
            if (this.bid) {
                BoardApi.modify(this.bid, this.board).then(res => {
                    //this.hideLoading();
                    if (res) {
                        this.$emit('refresh', this.bid);
                        //this.$router.go(-1);
                    }
                });
            } else {
                BoardApi.regist(this.board).then(res => {
                    //this.hideLoading();
                    if (res) {
                        this.$emit('refresh', res.content.id);
                        //this.$router.go(-1);
                    }
                });
            }
        },
        share(board) {
            //sns helper를 만들자
            console.log('share', board);
        },
        remove(board) {
            //권한체크 helper를 만들자
            console.log('remove', board);
        },
        onEditorBlur(quill) {
            console.log('editor blur!', quill);
        },
        onEditorFocus(quill) {
            console.log('editor focus!', quill);
        },
        onEditorReady(quill) {
            console.log('editor ready!', quill);
        },
        onEditorChange({ quill, html, text }) {
            console.log('editor change!', quill, html, text);
            this.content = html;
        },
        setColor(color) {
            this.$refs.myQuillEditor.quill.format('color', color);
        },
        setStyle(style) {
            this.$refs.myQuillEditor.quill.format(style, true);
        }
    }
};
</script>
<style type="text/css">
.ql-container.ql-snow {
    border: 0px;
}
</style>
