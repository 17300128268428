import BaseModel from './BaseModel';

class CountModel extends BaseModel {
    constructor(json, options) {
        super(json, options);
    }

    get alarm() {
        return this.getPayload().alarm || 0;
    }
    get todoWork() {
        return this.getPayload().todoWork || { finishCount: 0, noActionCount: 0 };
    }
    get myWork() {
        return this.getPayload().myWork || { finishCount: 0, noActionCount: 0 };
    }
    get approveWorker() {
        return this.getPayload().approveWorker || 0;
    }
}

export default CountModel;
