<template>
    <div class="modal modal-width-fixed modal-select-group on">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-nav">
                        <a class="back"><i /><span class="blind">뒤로가기</span></a>
                    </div>
                    <div class="header-title">
                        <span>공정 선택</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="confirm" @click="setCode"><span>확인</span></a>
                    <span class="gap" />
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->

            <!-- modal-body -->
            <div class="modal-body">
                <div class="search-box">
                    <div class="search">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="공정명 검색"
                            @keyup="setFilterCodes"
                        />
                    </div>
                </div>

                <div class="list-box">
                    <div class="list-scroll-box">
                        <!-- user-list -->
                        <div class="group-list">
                            <ul>
                                <li v-for="(code, index) in filterCodes" :key="index">
                                    <input
                                        :id="'chk-add-code-' + index"
                                        v-model="item"
                                        type="radio"
                                        class="group-nm"
                                        :value="code"
                                    />
                                    <label :for="'chk-add-code-' + index">
                                        {{ code.name }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <!-- // user-list -->
                    </div>
                </div>
            </div>
            <!-- // modal-body -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
//import ArrayHelper from '../../helper/ArrayHelper';
import CodeApi from '../../api/CodeApi';

export default {
    name: 'PopProcessCode',
    components: {},
    data: () => {
        return {
            searchText: '',
            codes: [],
            filterCodes: [],
            item: {}
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        this.getCodes();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        close() {
            this.$emit('update:show', false);
        },
        async getCodes() {
            const res = await CodeApi.list('0050');
            this.filterCodes = this.codes = res.content;
        },
        setFilterCodes() {
            this.filterCodes = this.codes.filter(item => item.name.indexOf(this.searchText) > -1);
        },
        setItem(item) {
            this.item = item;
        },
        setCode() {
            this.$emit('setData', this.item);
            this.close();
        }
    }
};
</script>
