<template>
    <div ref="filterTagsLayer" class="modal modal-width-fixed modal-select-tags">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-nav">
                        <a class="back" @click="close"><i /><span class="blind">뒤로가기</span></a>
                    </div>
                    <div class="header-title">
                        <span>태그 선택</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="confirm" @click="doConfirm()"><span>확인</span></a>
                    <span class="gap" />
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->

            <!-- modal-body -->
            <div class="modal-body">
                <div class="search-box">
                    <div class="search">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="태그 검색"
                            @input="setFilterTag"
                        />
                    </div>
                </div>

                <div class="list-box">
                    <div class="tag-add-box">
                        <input v-model="addTag" type="text" class="txt" placeholder="태그 직접 입력" />
                        <a @click="addEtcTag"><span class="blind">태그추가</span></a>
                    </div>
                    <div class="select-box">
                        <input
                            :id="tpId + '-chk-tag-all'"
                            v-model="isAll"
                            type="checkbox"
                            class="sel-tag"
                            @click="allSelect()"
                        />
                        <label :for="tpId + '-chk-tag-all'">전체선택</label>
                    </div>
                    <div class="list-scroll-box">
                        <!-- tags-list -->
                        <div class="tags-list">
                            <ul>
                                <li v-for="(tag, index) in filterTags" :key="index">
                                    <input
                                        :id="tpId + '-modal-chk-tag-' + index"
                                        v-model="selectTags"
                                        type="checkbox"
                                        class="sel-tag"
                                        :value="tag"
                                    />
                                    <label :for="tpId + '-modal-chk-tag-' + index"> #{{ tag }}</label>
                                </li>
                            </ul>
                        </div>
                        <!-- // user-list -->
                    </div>
                </div>
            </div>
            <!-- // modal-body -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import WorkTagApi from '../../api/WorkTagApi';

export default {
    name: 'PopTag',
    components: {},
    props: {
        tpId: {
            type: String,
            required: true
        },
        bindTags: {
            type: Array,
            required: false,
            default: null
        }
    },
    data: () => {
        return {
            roomId: 0,
            tags: [],
            filterTags: [],
            selectTags: [],
            searchText: '',
            isAll: false,
            addTag: ''
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    watch: {
        bindTags: {
            // the callback will be called immediately after the start of the observation
            immediate: true,
            handler(val) {
                if (val) this.selectTags = val;
            }
        }
    },
    created() {},
    mounted() {
        this.$emit('setTags', this.selectTags);
        //this.bind();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        close() {
            this.selectTags = this.bindTags;
            this.$refs.filterTagsLayer.classList.remove('on');
        },
        open(id) {
            this.isAll = false;
            this.addTag = '';
            this.searchText = '';
            this.roomId = id;
            this.bind();
            this.$refs.filterTagsLayer.classList.add('on');
        },
        setFilterTag() {
            this.filterTags = this.tags.filter(tag => tag.indexOf(this.searchText) > -1);
        },
        delSearchText() {
            this.searchText = '';
            this.setFilterTag();
        },
        doConfirm() {
            //console.log(this.workerIds);
            this.$emit('setTags', this.selectTags);
            this.$refs.filterTagsLayer.classList.remove('on');
        },
        async bind() {
            let response = { content: [] };
            if (this.roomId) response = await WorkTagApi.tagsOfroom(this.roomId);
            this.tags = response.content;
            this.filterTags = this.tags;
        },
        allSelect() {
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.selectTags = this.filterTags.map(item => item);
            } else {
                this.selectTags = [];
            }
        },
        addEtcTag() {
            if (this.addTag) {
                const addtag = this.addTag.trim();
                //this.selectTags = [];
                this.selectTags.push(addtag);
                this.$emit('setTags', this.selectTags);
                this.$refs.filterTagsLayer.classList.remove('on');
            }
        }
    }
};
</script>
