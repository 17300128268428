import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
    filter: {},
    roomList: [],
    roomSocketClient: null,
    roomWorker: [],
    tagOfRoom: [],
    workId: null,
    commentId: null,
    commentType: 'commentOfwork',
    workActionId: null,
    showType: '',
    showWork: false,

    workOfRoom: {
        roomId: 0,
        page: 1,
        total: 0,
        works: []
    },
    picture: {},
    searchList: [],
    searchCondition: null,
    tempWorkContent: '',
    tempDocFile: [],
    showPopManageRoom: false,
    showPopAddWorker: false,
    showPopLeaveRoom: false,
    showPopAddRoom: false,
    showPopSendHp: false,
    showPopShareRoom: false,
    alarmCnt: 0,
    selectRoomId: 0
};

export default {
    state,
    getters,
    actions,
    mutations
    // plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : []
};
