import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';
import authHeader from './Header';

const joinOfRoomList = id =>
    httpClient.get(`/wkroomjoin/ofroom/${id}`, { headers: authHeader() }).then(res => res.data.content);
const regist = (roomId, workerId) =>
    httpClient.post(`/wkroomjoin/${roomId}/${workerId}`, {}, { headers: authHeader() }).then(res => res.data);
const wkroomjoin = (roomId, workerIds) =>
    httpClient.post(`/wkroomjoin/${roomId}`, workerIds, { headers: authHeader() }).then(res => res.data);
const remove = (roomId, workerId) =>
    httpClient.delete(`/wkroomjoin/${roomId}/${workerId}`, { headers: authHeader() }).then(res => res.data);
const removeInWorkers = (roomId, workerIds) =>
    httpClient.delete(`/wkroomjoin/${roomId}`, { data: workerIds, headers: authHeader() }).then(res => res.data);

export default {
    joinOfRoomList: wrapHttpClient(joinOfRoomList),
    regist: wrapHttpClient(regist),
    remove: wrapHttpClient(remove),
    wkroomjoin: wrapHttpClient(wkroomjoin),
    removeInWorkers: wrapHttpClient(removeInWorkers)
};
