import BaseModel from './BaseModel';

class PageModel extends BaseModel {
    constructor(json) {
        if (!json) json = { content: [], pageable: { total: 0, page: 1 } };
        super(json, {});
    }

    get content() {
        return this.getPayload().content;
    }

    get pageable() {
        return this.getPayload().pageable;
    }

    set content(value) {
        this.getPayload().content = value;
    }

    set pageable(value) {
        this.getPayload().pageable = value;
    }

    get isMore() {
        return this.getPayload().pageable.total > this.getPayload().content.length;
    }

    get total() {
        return this.getPayload().pageable.total;
    }
    get page() {
        return this.getPayload().pageable.page || 1;
    }
    get rows() {
        return this.getPayload().pageable.rows;
    }

    set total(value) {
        this.getPayload().pageable.total = value;
    }

    set page(value) {
        this.getPayload().pageable.page = value;
    }

    set rows(value) {
        this.getPayload().pageable.rows = value;
    }
    bind(response, isAdd) {
        if (isAdd) this.getPayload().content.push(...response.content);
        else this.getPayload().content = response.content;
        this.getPayload().pageable = response.pageable;
    }
    addPage() {
        var page = this.getPayload().pageable.page || 1;
        this.getPayload().pageable.page = page + 1;
    }
    query() {
        return {
            page: this.getPayload().pageable.page || 1,
            rows: this.getPayload().pageable.rows || 10
        };
    }
    addItem(content) {
        this.getPayload().content.unshift(content);
        const total = this.getPayload().pageable.total;
        this.getPayload().pageable.total = total - 1;
    }
    removeItem(id) {
        const fnCondition = item => item.id === id;
        const idx = this.getPayload().content.findIndex(fnCondition);
        const total = this.getPayload().pageable.total;
        this.getPayload().pageable.total = total - 1;
        this.getPayload().content.splice(idx, 1);
    }
}

export default PageModel;
