import MutationTypes from '../../MutationTypes';

export default {
    [MutationTypes.SET_POSITION_CODE]: (state, payload) => {
        state.position = payload;
    },
    [MutationTypes.SET_OCCUPATION_CODE]: (state, payload) => {
        state.occupation = payload;
    },
    [MutationTypes.SET_COMPANY]: (state, payload) => {
        state.company = payload;
    }
};
