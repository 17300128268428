<template>
    <div ref="filterManagerLayer" class="modal modal-width-fixed modal-select-charger">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-nav">
                        <a class="back" @click="close()"><i /><span class="blind">뒤로가기</span></a>
                    </div>
                    <div class="header-title">
                        <span>담당자 선택</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="confirm" @click="doConfirm()"><span>확인</span></a>
                    <span class="gap" />
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->
            <!-- modal-body -->
            <div class="modal-body">
                <div class="search-box">
                    <div class="search">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="이름, 회사, 직위 검색"
                            @input="setFilterWorker"
                        />
                    </div>
                </div>

                <div class="list-box">
                    <div class="select-box">
                        <input
                            :id="tpId + '-chk-manager-all'"
                            v-model="isAll"
                            type="checkbox"
                            class="sel-user"
                            @click="allSelect()"
                        />
                        <label :for="tpId + '-chk-manager-all'">전체선택</label>
                    </div>
                    <div class="list-scroll-box">
                        <!-- user-list -->
                        <div class="user-list">
                            <ul>
                                <li v-for="(worker, index) in filterWorkers" :key="index">
                                    <input
                                        :id="tpId + '-modal-chk-mg-' + index"
                                        v-model="managers"
                                        type="checkbox"
                                        class="sel-user"
                                        :value="worker"
                                    />
                                    <label class="user-summary" :for="tpId + '-modal-chk-mg-' + index">
                                        <div class="user-thumb">
                                            <span class="thumb">
                                                <img
                                                    :src="worker.imgUrl | profileUrl"
                                                    onerror="javascript:this.src='/images/example/img-profile.png'"
                                                />
                                            </span>
                                        </div>
                                        <div class="user-info">
                                            <p class="user-nm">{{ worker.name }}</p>
                                            <div class="user-member">
                                                <div>
                                                    <strong>{{ worker.companyName }}</strong>
                                                    <span>{{ worker.regDt | formatTime }} 가입</span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <!-- // user-list -->
                    </div>
                </div>
            </div>
            <!-- // modal-body -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
//import WorkRoomJoinApi from '../../api/WorkRoomJoinApi';
import WorkerApi from '../../api/WorkerApi';
//import AffilationSiteApi from '../../api/AffilationSiteApi';

import ArrayHelper from '../../helper/ArrayHelper';

export default {
    name: 'FilterManager',
    components: {},
    props: {
        tpId: {
            type: String,
            required: true
        },
        bindManagers: {
            type: Array,
            required: false,
            default: null
        }
    },
    data: () => {
        return {
            roomId: 0,
            workers: [],
            filterWorkers: [],
            workerId: 0,
            managers: [],
            searchText: '',
            isAll: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    watch: {
        bindManagers: {
            // the callback will be called immediately after the start of the observation
            immediate: true,
            handler(val) {
                if (val) this.managers = val;
            }
        }
    },
    created() {},
    mounted() {
        //this.roomId = Number(this.$route.params.roomId);
        //this.bind();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        close() {
            this.managers = this.bindManagers;
            this.$refs.filterManagerLayer.classList.remove('on');
        },
        async open(id) {
            this.roomId = id;
            this.isAll = false;
            this.searchText = '';
            await this.bind();
            this.$refs.filterManagerLayer.classList.add('on');
        },
        setFilterWorker() {
            this.filterWorkers = this.workers.filter(
                worker => worker.name.indexOf(this.searchText) > -1 || worker.companyName.indexOf(this.searchText) > -1
            );
        },
        delSearchText() {
            this.searchText = '';
            this.setFilterWorker();
        },
        doConfirm() {
            //console.log(this.workerIds);
            this.$emit('setManager', this.managers);
            this.close();
        },
        async bind() {
            let response = [];

            if (this.roomId) response = await WorkerApi.listOfroom(this.roomId);
            const condition = item => item.id === this.basic.id;
            ArrayHelper.oneRemove(response, condition);

            this.workers = response.map(item => {
                const worker = {};
                worker.id = item.id;
                worker.name = item.displayName;
                worker.companyName = item.company.name;
                worker.imgUrl = item.imgUrl;
                worker.regDt = item.regDt;
                return worker;
            });
            this.filterWorkers = this.workers;
        },
        allSelect() {
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.managers = this.filterWorkers.map(item => item);
            } else {
                this.managers = [];
            }
        }
    }
};
</script>
