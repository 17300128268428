<template>
    <div class="modify-wrapper">
        <!-- content-header -->
        <header class="content-header">
            <div class="header-box">
                <div class="header-nav">
                    <a class="back" @click="back"><i /><span class="blind">뒤로가기</span></a>
                </div>
                <div class="header-title">
                    <span>조치 수정</span>
                </div>
            </div>
            <div class="header-util">
                <a class="confirm" @click="doModifyWorkAction()"><span>확인</span></a>
            </div>
        </header>
        <!-- // content-header -->

        <div class="modify-box photo-box">
            <div class="modify-photo-box">
                <div class="photo-add-box" @click="pickerImgFile()">
                    <label for="photo-upload"><i /><span>사진추가</span></label>
                </div>
                <div class="photo-swiper">
                    <div class="swiper-container">
                        <swiper :options="swiperOption">
                            <swiper-slide v-for="(img, index) in imgFileList" :key="index" class="swiper-slide">
                                <img
                                    :src="img.dataUrl"
                                    onerror="javascript:this.src='/images/default.png'"
                                    @click="openImgEditor(img)"
                                />
                                <a class="del-photo" @click="onClickImgDel(img)">
                                    <i /><span class="blind">사진삭제</span>
                                </a>
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>
            </div>
            <div class="swiper-button-prev" />
            <div class="swiper-button-next" />
        </div>

        <div class="modify-form-box">
            <div class="field-box">
                <textarea ref="commentMemo" v-model="writeData.content" rows="3" cols="10" />
            </div>
            <div class="field-box">
                <div class="label">
                    첨부파일
                </div>
                <div class="field">
                    <label @click="pickerFile">추가</label>
                </div>
            </div>

            <div class="modify-add-tags">
                <!-- tag-file-list-box -->
                <div class="tag-file-list-box">
                    <ul>
                        <li v-for="(file, index) in docFileList" :key="'3' + index">
                            <span class="filename">
                                {{ file.name }}
                                <a class="del-tags" @click="onClickDocFileDel(file)">
                                    <span class="blind">삭제</span>
                                </a>
                            </span>
                        </li>
                        <li v-for="(file, index) in addFileList" :key="'4' + index">
                            <span class="filename">
                                {{ file.name }}
                                <a class="del-tags" @click="onClickFileDel(file)">
                                    <span class="blind">삭제</span>
                                </a>
                            </span>
                        </li>
                    </ul>
                </div>
                <!-- // file-download-box -->
            </div>
        </div>
        <input ref="docFile" type="file" :accept="DOC_ALLOW_FILE" class="blind" @change="doDocFileAttach" />
        <input id="gallery" ref="uploadGallery" type="file" class="blind" accept="image/*" @change="doGalleryAttach" />
        <pop-image-editor v-if="showImageEditor" ref="popImageEditor" @close="closeImgEditor" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import ArrayHelper from '../../helper/ArrayHelper';
import FileHelper from '../../helper/FileHelper';

import FileApi from '../../api/FileApi';

import WorkActionApi from '../../api/WorkActionApi';

import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

import PopImageEditor from '../popup/PopImageEditor';

export default {
    name: 'WorkActionModify',
    components: { swiper, swiperSlide, PopImageEditor },
    props: {},
    data: () => {
        return {
            roomId: 0,
            fileId: 0,
            addTag: '',
            writeData: {
                work: {},
                content: '',
                file: {}
            },
            swiperOption: {
                direction: 'horizontal',
                slidesPerView: 1,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction'
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            docFileList: [],
            addFileList: [],
            imgFileList: [],
            removeFileList: [],
            DOC_ALLOW_FILE: FileHelper.allowFileExt(),
            docfileId: 0,
            docfileNo: 0,
            imgfileNo: 0,
            showImageEditor: false
        };
    },
    computed: {
        ...mapState({
            rooms: state => state.work.roomList,
            basic: state => state.main.basic,
            workId: state => state.work.workId,
            id: state => state.work.workActionId
        })
    },
    created() {},
    mounted() {
        this.resetData();
        this.getWorkAction();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        back() {
            this.$emit('back', 'modify');
        },
        pickerFile() {
            this.$refs.docFile.value = '';
            if (FileHelper.isCount(this.addFileList.length)) this.$refs.docFile.click();
        },
        doDocFileAttach() {
            const fileObject = this.$refs.docFile.files[0];
            if (fileObject) {
                const size = fileObject.size;
                if (FileHelper.isNewSize(size, fileObject.name) && FileHelper.isAllowFile(fileObject.name)) {
                    ++this.docfileNo;
                    const formData = new FormData();
                    formData.append('file_id', '');
                    formData.append('file_no', this.docfileNo);
                    formData.append('file', fileObject);
                    const addFile = {
                        fileNo: this.docfileNo,
                        name: fileObject.name,
                        form: formData
                    };
                    this.addFileList.push(addFile);
                }
            }
        },
        onClickFileDel(fileInfo) {
            const condition = item => item.fileNo === fileInfo.fileNo;
            ArrayHelper.oneRemove(this.addFileList, condition);
        },
        onClickDocFileDel(fileInfo) {
            const condition = item => item.id.fileNo === fileInfo.id.fileNo;
            this.removeFileList.push(fileInfo);
            ArrayHelper.oneRemove(this.docFileList, condition);
        },
        async doModifyWorkAction() {
            //삭제대상 파일
            for (var removeFile of this.removeFileList) {
                await FileApi.remove({
                    fileId: removeFile.id.fileId,
                    fileNo: removeFile.id.fileNo
                });
            }

            for (var docFile of this.addFileList) {
                const docFileForm = docFile.form;
                if (this.docFileId > 0) docFileForm.set('file_id', this.docFileId);
                docFileForm.set('file_no', ++this.docfileNo);
                const fileRes = await FileApi.fileUpload(docFileForm, 'doc');
                if (fileRes.content) {
                    this.docFileId = fileRes.content[0].id.fileId;
                    this.writeData.docFileId = this.docFileId;
                }
            }

            /* 이미지 업로드 */
            if (this.imgFileList.length > 0) {
                this.imgfileNo = 0;
                for (var imgFile of this.imgFileList) {
                    if (imgFile.type === 'server') continue;

                    const imgFileForm = new FormData();
                    const now = new Date();
                    const imageName = now.getTime() + '.jpg';

                    imgFileForm.append('file_id', '');
                    imgFileForm.append('file_no', imgFile.fileNo);
                    imgFileForm.append('file_name', imageName);
                    //imgFileForm.append('file_name', imgFile.name);
                    imgFileForm.append('file', imgFile.data);
                    if (this.fileId > 0) imgFileForm.set('file_id', this.fileId);
                    //imgFileForm.set('file_no', ++this.imgfileNo);

                    if (imgFile.type === 'file') {
                        const fileRes = await FileApi.imgUpload(imgFileForm);
                        if (fileRes.content && this.fileId === 0) {
                            this.fileId = fileRes.content.fileId;
                        }
                    } else if (imgFile.type === 'blob') {
                        const fileRes = await FileApi.blolImgUpload(imgFileForm);
                        if (fileRes.content && this.fileId === 0) {
                            this.fileId = fileRes.content.fileId;
                        }
                    } else {
                        //moveImgs.push(imgFile.data);
                    }
                }
                if (this.fileId > 0) this.writeData.file.id = this.fileId;
            }

            this.docfileNo = 0;

            this.writeData.id = this.id;
            this.writeData.work.id = this.workId;
            //this.writeData.file.id = this.fileId;

            WorkActionApi.modify(this.id, this.writeData).then(res => {
                if (res) {
                    this.resetData();
                    this.back();
                }
            });
        },
        addEtcTag() {
            if (this.addTag) {
                const addtag = this.addTag.trim();
                if (this.writeData.tags.findIndex(tag => tag === addtag) < 0) {
                    this.writeData.tags.push(addtag);
                }
                this.addTag = '';
            }
        },
        resetData() {
            this.writeData.content = '';
            this.docFileId = 0;
            this.docfileNo = 0;
            this.imgfileNo = 0;
            this.addFileList = [];
            this.imgFileList = [];
        },
        async getWorkAction() {
            if (this.id) {
                const res = await WorkActionApi.detail(this.id);
                //if (!res) return;

                const workAction = res.content;
                if (workAction.file) this.fileId = workAction.file.id;
                this.docFileId = workAction.docFileId;
                this.writeData.content = workAction.content;

                if (this.fileId) {
                    this.imgfileNo = 0;
                    FileApi.list(this.fileId).then(res => {
                        //this.imgFileList = res;
                        this.imgFileList = res.map(item => {
                            this.imgfileNo = item.id.fileNo;
                            return {
                                fileId: item.id.fileId,
                                fileNo: item.id.fileNo,
                                name: item.name,
                                type: 'server',
                                dataUrl: item.url,
                                data: item
                            };
                        });
                    });
                }
                if (this.docFileId) {
                    FileApi.list(this.docFileId).then(result => {
                        this.docFileList = result;
                        const fileInfo = this.docFileList[result.length - 1];
                        this.docfileNo = fileInfo.id.fileNo;
                    });
                }
            }
        },
        pickerImgFile() {
            this.$refs.uploadGallery.value = '';
            if (FileHelper.isCount(this.imgFileList.length)) this.$refs.uploadGallery.click();
        },
        doGalleryAttach() {
            const fileObject = this.$refs.uploadGallery.files[0];
            if (fileObject) {
                const reader = new FileReader();
                reader.onload = e => {
                    ++this.imgfileNo;
                    const addFile = {
                        fileId: this.fileId,
                        fileNo: this.imgfileNo,
                        name: fileObject.name,
                        type: 'file',
                        dataUrl: e.target.result,
                        data: fileObject
                    };
                    this.imgFileList.push(addFile);
                };
                reader.readAsDataURL(fileObject); // convert to base64 string
            }
        },
        openImgEditor(imgObject) {
            this.showImageEditor = true;
            setTimeout(() => {
                this.$refs.popImageEditor.setDataUrlImage(imgObject);
            }, 100);
        },
        closeImgEditor() {
            this.showImageEditor = false;
        },
        onClickImgDel(fileInfo) {
            if (fileInfo.type === 'server') {
                this.removeFileList.push(fileInfo);
            }
            const condition = item => item.fileNo === fileInfo.fileNo;
            ArrayHelper.oneRemove(this.imgFileList, condition);
        }
    }
};
</script>
