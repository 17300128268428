import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';

import authHeader from './Header';

const list = csId => httpClient.get(`/sitecompany/${csId}`, { headers: authHeader() }).then(res => res.data);

const detail = (csId, companyId) =>
    httpClient.get(`/sitecompany/${csId}/${companyId}`, { headers: authHeader() }).then(res => res.data);

const regist = param => httpClient.post(`/sitecompany`, param, { headers: authHeader() }).then(res => res.data);

const remove = (csId, companyId) =>
    httpClient.delete(`/sitecompany/${csId}/${companyId}`, { headers: authHeader() }).then(res => res.data);

const sendSms = param =>
    httpClient.post(`/csadmin/appDownload`, param, { headers: authHeader() }).then(res => res.data);

export default {
    list: wrapHttpClient(list),
    regist: wrapHttpClient(regist),
    detail: wrapHttpClient(detail),
    remove: wrapHttpClient(remove),
    sendSms: wrapHttpClient(sendSms)
};
