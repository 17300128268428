<template>
    <div id="content-wrap" :class="contentClass">
        <notice-list ref="noticeList" @show="selComp" />
        <notice-detail
            v-if="showType === 'detail'"
            ref="noticeDetail"
            :bid="boardId"
            @back="backContentClass"
            @show="selComp"
            @refresh="refresh"
        />
        <notice-regist
            v-if="showType === 'regist'"
            ref="noticeRegist"
            :bid="boardId"
            @refresh="refresh"
            @back="backContentClass"
        />
        <notice-none v-if="showType === 'none'" ref="noticeNone" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import NoticeList from './NoticeList';
import NoticeDetail from './NoticeDetail';
import NoticeRegist from './NoticeRegist';
import NoticeNone from './NoticeNone';

/* POPUP Layer관련 처리 */
export default {
    name: 'Notice',
    components: { NoticeList, NoticeDetail, NoticeRegist, NoticeNone },
    data: () => {
        return {
            contentClass: 'content-side-2',
            showType: 'none',
            boardId: null
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {
        /* window resize체 대한 이벤트를 추가한다*/
        window.addEventListener('resize', this.onResize);
    },
    mounted() {
        //this.myInfo();
        this.boardId = parseInt(this.$route.params.id);
        if (this.boardId) {
            //this.boardId = parseInt(bid);
            this.$refs.noticeList.showDetail(this.boardId);
        }
        //this.boardId = this.$route.params.id;
        //if (this.boardId) this.$refs.noticeList.showDetail(this.boardId);
        this.onResize();
    },
    beforeDestroy() {
        /* window resize체 대한 이벤트를 삭제한다*/
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        ...mapActions([]),
        onResize() {
            const width = document.body.clientWidth;
            if (width > 800) {
                if (this.contentClass === 'content-side-1') {
                    this.contentClass = 'content-side-2';
                }
            } else {
                if (this.showType === 'none') this.contentClass = 'content-side-1';
            }
        },
        backContentClass() {
            if (this.contentClass === 'content-side-2') this.contentClass = 'content-side-1';
        },
        selComp(type, id) {
            this.showType = type;
            this.boardId = id;
            if (this.showType !== 'none')
                if (this.contentClass === 'content-side-1') this.contentClass = 'content-side-2';
        },
        back() {
            this.showType = 'none';
        },
        refresh(id) {
            this.$refs.noticeList.resetSearch();
            if (id) {
                this.showType = 'detail';
                this.boardId = id;
            } else this.showType = 'none';
        }
    }
};
</script>
