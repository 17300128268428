import MutationTypes from '../../MutationTypes';
import ArrayHelper from '../../../helper/ArrayHelper';

export default {
    [MutationTypes.SET_MY_ROOMS]: (state, payload) => {
        state.roomList = payload;
    },
    [MutationTypes.SET_SELCT_ROOM]: (state, payload) => {
        state.selectRoomId = payload;
    },
    [MutationTypes.REMOVE_MY_ROOMS]: (state, payload) => {
        const condition = item => item.id === payload.roomId;
        ArrayHelper.oneRemove(state.roomList, condition);
    },
    [MutationTypes.ADD_MY_ROOM_CNT]: (state, roomIdx) => {
        state.roomList[roomIdx].alarmCnt++;
    },
    [MutationTypes.ADD_MY_ALARM_CNT]: state => {
        state.alarmCnt++;
    },
    [MutationTypes.RESET_MY_ROOM_CNT]: (state, roomIdx) => {
        state.roomList[roomIdx].alarmCnt = 0;
    },
    [MutationTypes.SET_SHOW_WORK]: (state, payload) => {
        state.showWork = payload;
    },
    [MutationTypes.SET_WORK_OF_ROOM]: (state, payload) => {
        if (state.workOfRoom.roomId !== payload.roomId || payload.page === 1) {
            state.workOfRoom = payload;
        } else {
            //payload.workOfRoom.works.unshift(...payload.works);
            state.workOfRoom.works.push(...payload.works);
            state.workOfRoom.page = payload.page;
        }
    },
    [MutationTypes.ADD_WORK_OF_ROOM]: (state, payload) => {
        state.workOfRoom.works.unshift(payload);
        ++state.workOfRoom.total;
    },
    [MutationTypes.REMOVE_WORK_OF_ROOM]: (state, payload) => {
        const condition = item => item.id === payload.id;
        ArrayHelper.oneRemove(state.workOfRoom.works, condition);
        --state.workOfRoom.total;
    },
    [MutationTypes.SET_PICTURE]: (state, payload) => {
        state.picture = payload;
    },
    [MutationTypes.SET_WORK_OF_SEARCH]: (state, payload) => {
        state.searchList = payload;
    },
    [MutationTypes.SET_SEARCH_CONDITION]: (state, payload) => {
        state.searchCondition = payload;
    },
    [MutationTypes.SET_TEMP_WORK_CONTENT]: (state, payload) => {
        state.tempWorkContent = payload;
    },
    [MutationTypes.SET_TEMP_WORK_DOCFILE]: (state, payload) => {
        state.tempDocFile = payload;
    },
    [MutationTypes.SET_SHOW_POP_MANAGE_ROOM]: (state, payload) => {
        state.showPopManageRoom = payload;
    },
    [MutationTypes.SET_SHOW_POP_ADD_WORKER]: (state, payload) => {
        state.showPopAddWorker = payload;
    },
    [MutationTypes.SET_SHOW_POP_LEAVE_ROOM]: (state, payload) => {
        state.showPopLeaveRoom = payload;
    },
    [MutationTypes.SET_SHOW_POP_ADD_ROOM]: (state, payload) => {
        state.showPopAddRoom = payload;
    },
    [MutationTypes.SET_SHOW_POP_SEND_HP]: (state, payload) => {
        state.showPopSendHp = payload;
    },
    [MutationTypes.SET_SHOW_POP_SHARE_ROOM]: (state, payload) => {
        state.showPopShareRoom = payload;
    },
    [MutationTypes.SET_WORK_ID]: (state, payload) => {
        state.workId = payload.id;
        state.showType = payload.showType || 'detail';
    },
    [MutationTypes.SET_COMMENT_ID]: (state, payload) => {
        if (payload.workId) state.workId = payload.workId;
        state.workActionId = payload.workActionId;
        state.commentId = payload.commentId;
        state.commentType = payload.type || 'commentOfwork';
        state.showType = 'comment';
    },
    [MutationTypes.RESET_ID]: state => {
        state.workId = null;
        state.commentId = null;
        state.workActionId = null;
        state.showType = '';
    },
    [MutationTypes.SET_SHOW_TYPE]: (state, payload) => {
        state.showType = payload;
    },
    [MutationTypes.SET_ACTION_ID]: (state, payload) => {
        state.workId = payload.workId;
        state.workActionId = payload.workActionId;
        state.showType = 'action';
    },
    [MutationTypes.SET_SOCKET_CLT]: (state, client) => {
        state.roomSocketClient = client;
    }
};
