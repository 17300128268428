<template>
    <div>
        <h2>404</h2>
        <h4 class="py-4">Not Found</h4>
        <div class="link">
            <router-link :to="{ name: 'Main' }">
                <a href="#"> 404!111 </a>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    components: {},
    computed: {},
    methods: {}
};
</script>
