<template>
    <div id="content-wrap" class="content-side-1">
        <div class="content-side-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-title">
                        <span>사진대지</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="photo-write" @click="createPhotoReport">
                        <i /><span class="blind">사진대지 생성</span>
                    </a>
                    <a class="photo-download" @click="downloadPhoto"> <i /><span class="blind">다운로드</span> </a>
                    <a class="winmin modal-link" @click="defaultSize"> <i /><span class="blind">창축소</span> </a>
                </div>
            </header>
            <!-- // content-header -->
            <!-- search-item-list -->
            <div class="search-item-list">
                <!-- search -->
                <div class="search-box">
                    <div class="search">
                        <input
                            v-model="condition.filename"
                            type="text"
                            class="txt"
                            placeholder="사진명 검색"
                            @keyup.enter="doSearch"
                        />
                    </div>
                </div>
                <!-- // search -->
                <div class="select-box">
                    <a class="sel modal-link" @click="() => (showGroup = true)">
                        <span>{{ textGroup || '전체' }} 그룹선택</span>
                    </a>
                </div>
                <div class="select-box">
                    <a class="sel modal-link" @click="() => (showCompany = true)">
                        <span>{{ textCompany || '전체' }} 업체선택</span>
                    </a>
                </div>
                <div class="select-box">
                    <a class="sel modal-link" @click="() => (showRegister = true)"><span>등록자 선택</span></a>
                </div>
                <!-- select-box -->
                <div class="select-box">
                    <a class="sel" @click="calendarToggle()"><span>기간선택</span></a>

                    <div ref="selCalendar" class="select-list">
                        <ul>
                            <li><a :class="{ on: calOn === 0 }">기본</a></li>
                            <li><a :class="{ on: calOn === -7 }" @click="addDay(-7)">1주</a></li>
                            <li><a :class="{ on: calOn === -1 }" @click="addMonth(-1)">1개월</a></li>
                            <li><a :class="{ on: calOn === -6 }" @click="addMonth(-6)">6개월</a></li>
                            <li><a :class="{ on: calOn === -12 }" @click="addMonth(-12)">1년</a></li>
                        </ul>
                        <ul>
                            <li>직접입력</li>
                            <li>
                                <span class="cal-box">
                                    <datepicker
                                        v-model="condition.beginDay"
                                        input-class="txt cal"
                                        :language="ko"
                                        :format="format"
                                    />
                                </span>
                            </li>
                            <li>
                                <span class="cal-box">
                                    <datepicker
                                        v-model="condition.endDay"
                                        input-class="txt cal"
                                        :language="ko"
                                        :format="format"
                                    />
                                </span>
                            </li>
                        </ul>
                        <div class="btn-wrapper">
                            <a class="btn-apply" @click="doSearch"><span>적용하기</span></a>
                        </div>
                    </div>
                </div>
                <!-- // select-box -->

                <!-- select-box -->
                <div class="select-box">
                    <a class="sel" @click="toggleWorType()">
                        <span v-if="condition.type">{{ condition.type }}업무</span>
                        <span v-else>업무 구분</span>
                    </a>
                    <div ref="selWorkType" class="select-list">
                        <ul>
                            <li>
                                <a :class="{ on: condition.type === null }" @click="setWorkType(null)">전체</a>
                            </li>
                            <li>
                                <a :class="{ on: condition.type === 'work' }" @click="setWorkType('work')">작업</a>
                            </li>
                            <li>
                                <a :class="{ on: condition.type === 'action' }" @click="setWorkType('action')">조치</a>
                            </li>
                            <li>
                                <a :class="{ on: condition.type === 'picture' }" @click="setWorkType('picture')">
                                    사진
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- // select-box -->
            </div>
            <!-- // search-item-list -->

            <!-- photo-box-wrapper -->
            <div class="photo-box-wrapper">
                <!-- photo-box-header -->
                <div class="photo-box-header">
                    <div class="photo-box-l">
                        <ul class="select-info">
                            <li>
                                <input id="photo-chk-all" type="checkbox" class="chk" @click="allSelect()" />
                                <label for="photo-chk-all">전체선택</label>
                            </li>
                            <li>
                                <span class="selected">{{ reportPhotos.length }}개 선택</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- // photo-box-header -->

                <!-- photo-list -->
                <div ref="listBox" class="photo-list" @scroll="morePage">
                    <ul>
                        <li v-for="(photo, index) in photoList" :key="index">
                            <input v-model="reportPhotos" type="checkbox" :value="photo" />
                            <a class="modal-link" @click="showPhotoDetail(photo.id)">
                                <img :src="photo.url | sThumbUrl" onerror="javascript:this.src='/images/default.png'" />
                            </a>
                            <a class="modal-link photo-filename" @click="showPhotoDetail(photo.id)">
                                <span class="photo-filename">{{ photo.name }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- // photo-list -->
            </div>
            <!-- // photo-box-wrapper -->
        </div>
        <pop-group v-if="showGroup" :show.sync="showGroup" @setData="setRoom" />
        <pop-company v-if="showCompany" :show.sync="showCompany" @setData="setCompany" />
        <pop-register
            v-if="showRegister"
            :show.sync="showRegister"
            :bind-company-id="condition.companyId"
            @setData="setWorkers"
        />
        <pop-photo-detail v-show="showPhoto" ref="photoDetail" :show.sync="showPhoto" :bind-photos="photoList" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import { ko } from 'vuejs-datepicker/dist/locale';
/* POPUP Layer관련 처리 */
import PopGroup from './PopGroup';
import PopRegister from './PopRegister';
import PopCompany from './PopCompany';
import PopPhotoDetail from './PopPhotoDetail';

import { toDayFormatAddDay, toDayFormatAddMonth, toDayFormat } from '../../utils/date';

import WorkApi from '../../api/WorkApi';
import FileApi from '../../api/FileApi';

import ElectronHelper from '../../helper/ElectronHelper';

export default {
    name: 'Photo',
    components: { Datepicker, PopGroup, PopRegister, PopCompany, PopPhotoDetail },
    data: () => {
        return {
            format: 'yyyy-MM-dd',
            calOn: 0,
            condition: {
                companyId: null,
                filename: null,
                beginDay: toDayFormatAddMonth(-1),
                endDay: toDayFormat(),
                sort: 'reg_dt',
                direction: 'desc',
                page: 1,
                rows: 40,
                type: null
            },
            photoList: [],
            ko,
            textGroup: '전체',
            showGroup: false,
            showRegister: false,
            textCompany: '전체',
            showCompany: false,
            showPhoto: false,
            reportPhotos: [],
            isAll: false,
            totalRows: 0
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        //window.resizeTo(1000, 1000);
        ElectronHelper.windowExpand();

        document.addEventListener('mouseup', e => {
            if (
                $(e.target).closest('.select-list').length === 0 &&
                $(e.target).closest('.a.sel').length === 0 &&
                $(e.target).closest('.drp-calendar').length === 0
            ) {
                $('.select-list').removeClass('on');
            }
        });
        //this.condition.page = 1;
        this.doSearch(1);
    },
    beforeDestroy() {
        document.removeEventListener('mouseup', e => {
            if (
                $(e.target).closest('.select-list').length === 0 &&
                $(e.target).closest('.a.sel').length === 0 &&
                $(e.target).closest('.drp-calendar').length === 0
            ) {
                $('.select-list').removeClass('on');
            }
        });
    },
    methods: {
        ...mapActions(['showLoading', 'hideLoading']),
        callDate(date) {
            if (date) {
                date = new Date(date);
                return date.toISOString().substring(0, 10);
            }
        },
        calendarToggle() {
            this.$refs.selCalendar.classList.toggle('on');
        },
        addMonth(month) {
            this.calOn = month;
            this.condition.beginDay = toDayFormatAddMonth(month);
        },
        addDay(day) {
            this.calOn = day;
            this.condition.beginDay = toDayFormatAddDay(day);
        },
        setRoom(room) {
            if (room) {
                this.textGroup = room.name;
                this.condition.roomId = room.id;
            } else {
                this.textGroup = this.condition.roomId = null;
            }
            this.doSearch(1);
        },
        setCompany(company) {
            if (company) {
                this.textCompany = company.name;
                this.condition.companyId = company.id;
            } else {
                this.textCompany = this.condition.companyId = null;
            }
            this.doSearch(1);
        },
        setWorkers(ids) {
            this.condition.workerIds = ids.join(',').toString();
            this.doSearch(1);
        },
        toggleWorType() {
            this.$refs.selWorkType.classList.toggle('on');
        },
        setWorkType(type) {
            this.$refs.selWorkType.classList.remove('on');
            this.condition.type = type;
            this.doSearch(1);
        },
        async doSearch(page) {
            if (page > 0) this.condition.page = page;
            else this.condition.page = 1;

            this.reportPhotos = [];
            this.$refs.selCalendar.classList.remove('on');
            if (this.basic.csAdminYn === 'N') this.condition.companyId = this.basic.companyId;
            this.condition.csId = this.basic.csId;
            this.condition.beginDay = this.callDate(this.condition.beginDay);
            this.condition.endDay = this.callDate(this.condition.endDay);
            const response = await WorkApi.photoList(this.condition);
            if (response) {
                if (page > 1) this.photoList.push(...response.content);
                else this.photoList = response.content;
                this.totalRows = response.pageable.total;
            }
        },
        showPhotoDetail(id) {
            this.$refs.photoDetail.selectId(id, true);
            this.showPhoto = true;
        },
        async createPhotoReport() {
            if (this.reportPhotos.length > 0) {
                if (this.reportPhotos.length > 8) {
                    alert('리포트 출력은 최대 8개까지 선택할 수 있습니다.');
                    return;
                }

                this.showLoading();
                const param = this.reportPhotos.map(item => {
                    return {
                        workId: item.work.id,
                        fileId: item.fileId,
                        fileNo: item.fileNo
                    };
                });
                await FileApi.downloadPhotoReport({ list: param });
                this.hideLoading();
            } else {
                alert('리포트 출력은 최소 1개 이상 선택해야 합니다.');
            }
        },

        async downloadPhoto() {
            if (this.reportPhotos.length > 0) {
                this.showLoading();
                /*
                for (var item of this.reportPhotos) {
                    const param = {
                        fileId: item.fileId,
                        fileNo: item.fileNo
                    };
                    await FileApi.fileDownload(param);
                }
                */
                await FileApi.downloadZip(this.reportPhotos);
                this.hideLoading();
            }
        },
        allSelect() {
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.reportPhotos = this.photoList;
            } else {
                this.reportPhotos = [];
            }
        },
        morePage() {
            const box = this.$refs.listBox;
            if (box.scrollTop + box.clientHeight >= box.scrollHeight) {
                //this.condition.page = this.condition.page + 1;
                console.log(this.totalRows, this.photoList.length);
                setTimeout(() => {
                    if (this.totalRows > this.photoList.length) this.doSearch(this.condition.page + 1);
                }, 1000);
            }
        },
        defaultSize() {
            ElectronHelper.windowDefaultReSize();
        }
    }
};
</script>
