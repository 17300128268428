import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';
import authHeader from './Header';

const myList = query =>
    httpClient.get(`/alarm/my/list`, { params: query, headers: authHeader() }).then(res => res.data);
const myAllList = query =>
    httpClient.get(`/alarm/my/allList`, { params: query, headers: authHeader() }).then(res => res.data);
const detail = id => httpClient.get(`/alarm/${id}`, { headers: authHeader() }).then(res => res.data);
const check = id => httpClient.put(`/alarm/check/${id}`, {}, { headers: authHeader() }).then(res => res.data);
const counter = () => httpClient.get(`/alarm/counter`, { headers: authHeader() }).then(res => res.data.content);

export default {
    myList: wrapHttpClient(myList),
    detail: wrapHttpClient(detail),
    check: wrapHttpClient(check),
    counter: wrapHttpClient(counter),
    myAllList: wrapHttpClient(myAllList)
};
