<template>
    <div id="content-wrap" :class="contentClass">
        <room-list ref="roomList" :room.sync="selectRoom" @refresh="refreshRoom" @changeOwner="changeOwnerAndLeave" />
        <room
            ref="room"
            :room="selectRoom"
            @back="backContentClass"
            @show="showWork"
            @changeOwner="changeOwnerAndLeave"
        />

        <div class="content-side-wrapper">
            <!-- work-detail-wrapper : 작업상세 -->
            <work-detail v-show="showType === 'detail'" ref="workDetail" @back="backContentClass" />
            <!-- // work-detail-wrapper : 작업상세 -->

            <!-- comment-wrapper : 댓글 보기 / 작성 -->
            <comment v-if="showType === 'comment'" ref="comment" @back="backComment" @refresh="refreshRoomData" />
            <!-- // comment-wrapper : 댓글 보기 / 작성 -->

            <!-- modify-wrapper : 수정 -->
            <work-modify v-if="showType === 'modify'" ref="workModify" @back="showWork" @refresh="refreshRoomData" />
            <work-action-modify
                v-if="showType === 'action'"
                ref="actionModify"
                @back="showWork"
                @refresh="refreshRoomData"
            />
            <!-- modify-wrapper : // 수정 -->
        </div>
        <pop-leave-room v-if="showPopLeaveRoom" :room="selectRoom" />
        <pop-manage-room
            v-if="showPopManageRoom"
            :room="selectRoom"
            @refresh="refreshRoomList"
            @changeOwner="changeOwner"
        />
        <pop-add-worker v-if="showPopAddWorker" :room="selectRoom" @refresh="refreshRoomList" />
        <pop-add-room v-if="showPopAddRoom" @refresh="refreshRoomList" />

        <pop-owner-change ref="popOwnerChange" @refresh="refreshRoomList" />
        <pop-invite-member v-if="showPopSendHp" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import RoomList from './RoomList.vue';
import Room from './Room.vue';

import WorkDetail from './WorkDetail.vue';
import Comment from './Comment.vue';
import WorkModify from './WorkModify.vue';
import WorkActionModify from './WorkActionModify.vue';

/* POPUP Layer관련 처리 */
import PopLeaveRoom from '../popup/PopLeaveRoom';
import PopManageRoom from '../popup/PopManageRoom';
import PopAddWorker from '../popup/PopAddWorker';
import PopAddRoom from '../popup/PopAddRoom';
import PopOwnerChange from '../popup/PopOwnerChange';
import PopInviteMember from '../popup/PopInviteMember';

/*
import AppSetting from '../../api/AppSetting';
import ElectronHelper from '../../helper/ElectronHelper';
import { WORKER_ID } from '../../const';
*/
export default {
    name: 'Group',
    components: {
        RoomList,
        Room,
        PopLeaveRoom,
        PopManageRoom,
        PopAddWorker,
        PopAddRoom,
        WorkDetail,
        Comment,
        WorkModify,
        WorkActionModify,
        PopOwnerChange,
        PopInviteMember
    },
    data: () => {
        return {
            selectRoom: { id: null },
            contentClass: 'content-side-1'
        };
    },
    computed: {
        ...mapState({
            showPopManageRoom: state => state.work.showPopManageRoom,
            showPopLeaveRoom: state => state.work.showPopLeaveRoom,
            showPopAddWorker: state => state.work.showPopAddWorker,
            showPopAddRoom: state => state.work.showPopAddRoom,
            showPopSendHp: state => state.work.showPopSendHp,
            showType: state => state.work.showType
        })
    },
    created() {
        /* window resize체 대한 이벤트를 추가한다*/
        window.addEventListener('resize', this.onResize);
    },
    mounted() {
        //console.log(this.$refs.roomList.style);
        //this.getSetting();
        const workId = parseInt(this.$route.params.id);
        const roomId = parseInt(this.$route.params.roomId);
        if (workId && roomId) {
            this.selectRoom.id = roomId;
            this.$refs.roomList.setRoom(roomId);
            this.refreshRoom(roomId);
        }

        this.onResize();
        this.getWeather();
    },
    beforeDestroy() {
        /* window resize체 대한 이벤트를 삭제한다*/
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        ...mapActions(['setShowType', 'getWeather']),

        refreshRoomList() {
            this.$refs.roomList.getWorkOfRoom();
        },
        refreshRoomData(id) {
            this.$refs.room.refresh(id);
            if (!$('body').hasClass('direct-comment')) this.$refs.workDetail.getDetail();
        },
        refreshRoom(id) {
            this.contentClass = 'content-side-2';
            this.setShowType('');
            this.$refs.room.refresh(id);
        },
        onResize() {
            const width = document.body.clientWidth;
            if (width > 800) {
                if (this.contentClass === 'content-side-1') this.contentClass = 'content-side-2';
            } else {
                if (this.contentClass === 'content-side-2' && !this.selectRoom.id) this.contentClass = 'content-side-1';
            }
        },
        backContentClass() {
            if (this.contentClass === 'content-side-2') this.contentClass = 'content-side-1';
            else if (this.contentClass === 'content-side-3') this.contentClass = 'content-side-2';
        },
        backComment() {
            if ($('body').hasClass('direct-comment')) {
                $('body').removeClass('direct-comment');
                this.setShowType('');
                this.contentClass = 'content-side-2';
            } else {
                this.setShowType('detail');
                this.contentClass = 'content-side-3';
            }
        },
        setContentClass(cls) {
            this.contentClass = cls;
        },
        showWork(type) {
            this.setContentClass('content-side-3');

            if (type === 'comment') {
                $('body')
                    .removeClass()
                    .addClass('direct-comment');
            } else if (type === 'modify') {
                this.setShowType('detail');
                this.$refs.workDetail.getDetail();
            } else if (type === 'action') {
                this.setShowType('detail');
                this.$refs.workDetail.getDetail();
            } else {
                this.setShowType('detail');
                this.$refs.workDetail.getDetail();
            }
        },
        changeOwner() {
            this.$refs.popOwnerChange.open(this.selectRoom);
        },
        changeOwnerAndLeave() {
            this.$refs.popOwnerChange.openAndLeave(this.selectRoom);
        },
        addRoom(roomId, workerIds) {
            //RoomList에 addRoom전달
            this.$refs.roomList.addRoom(roomId, workerIds);
        }
    }
};
</script>
