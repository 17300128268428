<template>
    <!-- modal : modal-photo-box -->
    <div class="modal modal-photo-box on" style="z-index:10000;">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-nav">
                        <a class="back"><i /><span class="blind">뒤로가기</span></a>
                    </div>
                    <div class="header-title">
                        <span>사진함</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="btn-photo-download" @click="downloadPhoto()"><span>사진 다운로드</span></a>
                    <a class="confirm" @click="doSelectImgs"><span>확인</span></a>
                    <span class="gap" />
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->
            <!-- modal-body -->
            <div class="modal-body">
                <!-- photo-box-header -->
                <div class="photo-box-header">
                    <div class="photo-box-l">
                        <ul class="select-info">
                            <li>
                                <span>{{ selectImgs.length }}개 선택</span>
                            </li>
                            <li>
                                <a @click="allRelease">
                                    <span class="selected">선택삭제</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="photo-box-r" />
                </div>
                <!-- // photo-box-header -->

                <!-- photo-list -->
                <div ref="listBox" class="photo-list" @scroll="morePage">
                    <template v-for="(val, name) in imgList">
                        <p class="date">
                            {{ name }}
                        </p>
                        <ul>
                            <li v-for="(img, index) in val" :key="index">
                                <input v-model="selectImgs" type="checkbox" class="modal-link" :value="img" />
                                <a class="modal-link">
                                    <img :src="img.imgUrl | thumbUrl" />
                                </a>
                            </li>
                        </ul>
                    </template>
                </div>
                <!-- // photo-list -->
            </div>
            <!-- // modal-body -->
        </div>
    </div>
    <!-- // modal : modal-photo-box -->
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PictureRoomApi from '../../api/PictureRoomApi';
import FileApi from '../../api/FileApi';

export default {
    name: 'PopPictureRoom',
    components: {},
    data: () => {
        return {
            imgList: [],
            selectImgs: [],
            condition: { page: 1, rows: 100 },
            totalRows: 0,
            originList: []
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        //this.$emit('setTags', this.selectTags);
        this.bind();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['showLoading', 'hideLoading']),
        close() {
            this.$emit('close');
        },
        doSelectImgs() {
            //console.log(this.workerIds);
            this.$emit('setImgs', this.selectImgs);
        },
        async bind(page) {
            if (page > 0) this.condition.page = page;
            else this.condition.page = 1;
            this.imgList = [];
            this.selectImgs = [];
            const response = await PictureRoomApi.myList(this.condition);
            if (response) {
                if (this.condition.page > 1) this.originList.push(...response.content);
                else this.originList = response.content;

                //const temp = response.content;
                //this.originList = response.content;
                if (this.condition.page > 1)
                    this.imgList = { ...this.imgList, ...this.groupBy(this.originList, 'deviceYm') };
                else this.imgList = this.groupBy(this.originList, 'deviceYm');
                if (response.pageable) this.totalRows = response.pageable.total || 0;
            }
        },
        async allRelease() {
            const param = this.selectImgs.map(item => item.id);
            await PictureRoomApi.removeIn({ list: param });
            this.bind(1);
            //this.selectImgs = [];
        },
        groupBy(xs, key) {
            return xs.reduce(function(rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        },
        morePage() {
            const box = this.$refs.listBox;
            if (box.scrollTop + box.clientHeight >= box.scrollHeight) {
                if (this.totalRows > this.originList.length) this.bind(this.condition.page + 1);
            }
        },
        async downloadPhoto() {
            if (this.selectImgs.length > 0) {
                this.showLoading();
                const param = this.selectImgs.map(item => {
                    return { fileId: item.fileId, fileNo: 1 };
                });
                await FileApi.downloadZip(param);
                this.hideLoading();
            }
        }
    }
};
</script>
