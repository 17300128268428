<template>
    <aside id="sidebar">
        <div class="user-box">
            <div class="profile-pic">
                <img
                    :src="basic.imgUrl | profileUrl"
                    onerror="javascript:this.src='/images/default.png'"
                    @click="goSetting"
                />
            </div>
            <a href="javascript:;" class="setting" @click="goSetting"><i /><span class="blind">설정</span></a>
        </div>
        <ul class="sidebar-nav">
            <li>
                <a class="member" :class="{ on: selectId === 'Main' }" @click="goGroup"><i /><span>그룹</span></a>
            </li>
            <li>
                <a class="photo" :class="{ on: selectId === 'Photo' }" @click="goPicture">
                    <i /><span>사진대지</span>
                </a>
            </li>
            <li>
                <a class="archive" :class="{ on: selectId === 'PhotoBox' }" @click="goPictureBox">
                    <i /><span>내사진함</span>
                </a>
            </li>
            <li>
                <a class="alarm" :class="{ on: selectId === 'alarm' }" @click="goAlarm">
                    <i /><span>현장알림</span>
                    <span v-if="count.alarm > 0" class="cnt">{{ count.alarm }}</span>
                </a>
            </li>
            <li>
                <a class="report" :class="{ on: selectId === 'daily' }" @click="goWorkDaily">
                    <i /><span>작업일보</span>
                </a>
            </li>
            <li>
                <a class="notice" :class="{ on: selectId === 'notice' }" @click="goNotice">
                    <i /><span>공지사항</span>
                </a>
            </li>
            <li>
                <a class="inquary" :class="{ on: selectId === 'qna' }" @click="goQna"> <i /><span>기술문의</span> </a>
            </li>
            <li v-if="basic.csAdminYn === 'Y'">
                <a class="site" :class="{ on: selectId === 'Site' }" @click="goSite"> <i /><span>현장관리</span> </a>
            </li>
        </ul>
        <div class="weather-box">
            <div class="weather-icon" :class="weatherIcon()" />
            <div>{{ weather.sky }}</div>
            <div>{{ weather.tmn }}˚/{{ weather.tmx }}˚</div>
            <div>미세먼지 {{ weather.pmvText }}</div>
        </div>
        <a class="logout" @click="goLogout"> <i /><span class="blind">로그아웃</span> </a>
    </aside>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import MenuHelper from '../../helper/MenuHelper';
import { WEATHER_SKY } from '../../const';
export default {
    name: 'SideBar',
    components: {},
    data: () => {
        return {
            selectId: 'Main'
        };
    },
    computed: {
        ...mapState({
            count: state => state.main.count,
            basic: state => state.main.basic,
            rooms: state => state.work.roomList,
            lastAccess: state => state.main.lastAccess,
            weather: state => state.main.weather
        })
    },
    created() {},
    mounted() {
        if (this.basic.id) this.getWeather();
        this.selectId = this.$route.name;
        if (this.selectId === 'WorkDailyWrite' || this.selectId === 'WorkDailyReport') this.selectId = 'daily';
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['logout', 'getWeather']),
        setId(id) {
            this.selectId = id;
        },
        weatherIcon() {
            return WEATHER_SKY[this.weather.sky];
        },
        goGroup() {
            this.selectId = 'Main';
            MenuHelper.main(this.$router);
        },
        goSite() {
            this.selectId = 'Site';
            MenuHelper.site(this.$router);
        },
        goPicture() {
            this.selectId = 'Photo';
            MenuHelper.photo(this.$router);
        },
        goPictureBox() {
            this.selectId = 'PhotoBox';
            MenuHelper.photoBox(this.$router);
        },
        goNotice() {
            this.selectId = 'notice';
            MenuHelper.notice(this.$router);
        },
        goQna() {
            this.selectId = 'qna';
            MenuHelper.qna(this.$router);
        },
        goWorkDaily() {
            this.selectId = 'daily';
            if (this.basic.csAdminYn === 'Y') MenuHelper.daily(this.$router);
            else MenuHelper.daily2(this.$router);
        },
        goAlarm() {
            this.selectId = 'alarm';
            MenuHelper.alarm(this.$router);
        },
        goSetting() {
            this.selectId = 'Setting';
            MenuHelper.setting(this.$router);
        },
        goLogout() {
            if (window.stompClient) {
                window.stompClient.disconnect();
                window.stompClient = null;
            }

            //화면사이즈를 줄이고 창을 닫자
            this.logout().then(() => {
                this.$router.push({ path: '/login' });
            });
        },
        goHome() {
            this.$router.push({ path: '/' });
            this.close();
        }
    }
};
</script>
