<template>
    <div class="login-wrapper">
        <div class="login-logo">
            <img src="/images/img-login-logo.png" alt="" />
        </div>
        <div class="login-form">
            <div class="field-box">
                <div class="field">
                    <input ref="loginHp" v-model="login.hp" type="text" class="txt" placeholder="-없이 휴대번호 입력" />
                </div>
            </div>

            <div class="field-box">
                <div class="field">
                    <input
                        v-model="login.passwd"
                        type="password"
                        class="txt"
                        placeholder="비밀번호 입력"
                        @keyup.enter="processLogin"
                    />
                </div>
            </div>

            <div class="btn-wrapper">
                <button type="button" @click="processLogin">
                    <span>로그인</span>
                </button>
            </div>

            <div class="field-box save-id">
                <div class="field">
                    <input id="save-id" v-model="login.auto" type="checkbox" />
                    <label for="save-id">로그인 상태 유지</label>
                </div>
            </div>

            <div class="btn-wrapper-pw">
                <button type="button" @click="findPassword">
                    <span>비밀번호를 잊으셨나요?</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LoginApi from '../api/LoginApi';
import MenuHelper from '../helper/MenuHelper';
import MutationTypes from '../store/MutationTypes';
//import ElectronHelper from '../helper/ElectronHelper';
import { JWT_TOKEN, JWT_REFRESH_TOKEN, WORKER_ID, CS_ID } from '../const';
import { getCookie, setCookie } from '../utils/cookie';
export default {
    name: 'Login',
    components: {},
    data: () => {
        return {
            login: { hp: '', passwd: '', auto: true }
        };
    },
    computed: {
        ...mapState({})
    },
    created() {
        window.resizeTo(360, 600);
        this.logout();
    },
    mounted() {
        document.addEventListener('backbutton', this.backbtn, false);
        const loginID = getCookie('COCO_LOGIN_ID');
        this.login.hp = loginID;
    },
    beforeDestroy() {
        document.removeEventListener('backbutton', this.backbtn, false);
    },
    methods: {
        ...mapActions(['logout']),
        doLastAccess() {
            //ElectronHelper.doLastAccess();
        },
        findPassword() {
            //MenuHelper.findpasswd(this.$router);
            MenuHelper.findpasswd(this.$router);
        },
        async processLogin() {
            try {
                if (!this.login.hp) {
                    alert('휴대번호를 입력하세요');
                    this.$refs.loginHp.focus();
                    return;
                }
                setCookie('COCO_LOGIN_ID', this.login.hp);
                const res = await LoginApi.login(this.login);
                const session = res.content;
                if (session) {
                    const basic = {
                        csId: session.csId,
                        name: session.name,
                        position: session.postionName,
                        company: session.companyName,
                        companyId: session.companyId,
                        id: session.id,
                        hp: session.hp,
                        site: session.siteName,
                        imgUrl: session.imgUrl,
                        csAdminYn: session.csAdminYn,
                        csCompanyAdminYn: res.csCompanyAdminYn
                    };

                    const oauth = session.token || { token: null, refreshToken: null };
                    oauth.tempToken = session.tempToken;
                    this.$store.commit(MutationTypes.SET_MAIN_BASIC, basic);
                    //만약 csId가 없는 경우는 2개 이상인이고 이 경우 현장선택화면으로 이동해야 한다.

                    if (!session.csId) {
                        localStorage.setItem(CS_ID, null);
                        localStorage.setItem(WORKER_ID, session.id);
                        localStorage.setItem(JWT_TOKEN, oauth.tempToken);
                        this.$router.push({ name: 'SelectSite' });
                    } else {
                        localStorage.setItem(CS_ID, session.csId);
                        localStorage.setItem(WORKER_ID, session.id);
                        localStorage.setItem(JWT_TOKEN, oauth.token);
                        localStorage.setItem(JWT_REFRESH_TOKEN, oauth.refreshToken);
                        //this.$router.push({ name: 'SelectSite' });
                        this.doLastAccess();
                        this.$router.push({ name: 'Main' });
                    }
                }
            } catch (error) {
                if (error.response) {
                    alert(error.response.data.msg);
                }
            }
        }
    }
};
</script>
