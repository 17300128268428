import VueI18n from 'vue-i18n';

//import messages_en from '../../../locale/messages_en.json';
import messages_ko from '../../../locale/messages_ko.json';

export default locale => {
    return new VueI18n({
        locale,
        fallbackLocale: locale,
        messages: {
            ko: messages_ko
        }
    });
};
