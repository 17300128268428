<template>
    <div class="content-side-wrapper">
        <template v-if="room.id">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-nav">
                        <a class="back" @click="back()"><i /><span class="blind">뒤로가기</span></a>
                    </div>
                    <div class="header-title">
                        <span>{{ room.name }}</span>
                        <span class="cnt">{{ workOfRoom.total | numComma }}</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="search" @click="moreSearch(true)"><i /><span class="blind">검색</span></a>
                    <a class="more-nav" @click="toggleGroupBox()"><i /><span class="blind">더보기</span></a>
                    <div ref="groupBox" class="more-nav-box">
                        <ul>
                            <li><a class="modal-link" @click="clickRemoveGroup(room)">그룹 나가기</a></li>
                            <li><a @click="clickManageGroup(room)">그룹 관리</a></li>
                        </ul>
                    </div>
                </div>
            </header>
            <!-- // content-header -->

            <!-- content-header-search -->
            <div class="content-header-search" :class="{ on: showSearch }">
                <div class="search-box">
                    <div class="search">
                        <input
                            v-model="condition.searchText"
                            type="text"
                            class="txt"
                            placeholder="내용, 이름, 태그 검색"
                            @keyup.enter="clickSearch"
                        />
                    </div>
                </div>
                <div class="select-box">
                    <select v-model="condition.finishYn" @change="clickSearch">
                        <option value="">
                            업무상태
                        </option>
                        <option value="N">
                            조치중
                        </option>
                        <option value="Y">
                            조치완료
                        </option>
                    </select>
                </div>
                <div class="btn-box">
                    <a class="search-close" @click="moreSearch(false)"><i /><span class="blind">검색</span></a>
                </div>
            </div>
            <!-- // content-header-search -->

            <!-- content-nav -->
            <header class="content-nav">
                <div class="swiper-container">
                    <swiper :options="swiperOption">
                        <swiper-slide class="swiper-slide">
                            <a :class="searchType === '' ? 'on' : ''" @click="doSearchType('')">
                                <span>전체</span>
                            </a>
                        </swiper-slide>
                        <swiper-slide class="swiper-slide">
                            <a :class="searchType === 'receive' ? 'on' : ''" @click="doSearchWork('receive')">
                                <span>받은업무</span>
                            </a>
                        </swiper-slide>
                        <swiper-slide class="swiper-slide">
                            <a :class="searchType === 'request' ? 'on' : ''" @click="doSearchWork('request')">
                                <span>보낸업무</span>
                            </a>
                        </swiper-slide>
                        <swiper-slide class="swiper-slide">
                            <a :class="searchType === 'work' ? 'on' : ''" @click="doSearchType('work')">
                                <span>작업</span>
                            </a>
                        </swiper-slide>
                        <swiper-slide class="swiper-slide">
                            <a :class="searchType === 'picture' ? 'on' : ''" @click="doSearchType('picture')">
                                <span>사진</span>
                            </a>
                        </swiper-slide>
                        <swiper-slide class="swiper-slide">
                            <a :class="searchType === 'talk' ? 'on' : ''" @click="doSearchType('talk')">
                                <span>토크</span>
                            </a>
                        </swiper-slide>
                        <swiper-slide v-for="(tag, index) in tags" :key="index" class="swiper-slide">
                            <a :class="searchTag === tag.tag ? 'on' : ''" @click="doSearchTag(tag.tag)">
                                <span>#{{ tag.tag }}</span>
                            </a>
                        </swiper-slide>
                    </swiper>
                </div>
                <div class="swiper-button-next" />
                <div class="swiper-button-prev" />
            </header>
            <!-- // content-nav -->

            <!-- timeline-box -->
            <div class="timeline-wrapper" @drop.prevent="addFile" @dragover.prevent>
                <div ref="listBox" class="timeline-list-box" @scroll="morePage">
                    <ul>
                        <li v-for="(work, index) in workOfRoom.works" :key="index" :class="taskType(work)">
                            <div class="time-ago">
                                {{ work.regDt | timeBefore }}
                            </div>
                            <div class="timeline-contents">
                                <div class="timeline-header">
                                    <span class="work-color">{{ taskName(work) }}</span>
                                    <span>{{ work.register.displayName }}&middot;{{ work.register.company.name }}</span>
                                </div>
                                <div class="timeline-memo" :class="{ on: work.register.id === basic.id }">
                                    <a @click="showWorkDetail(work.id)">
                                        <div v-if="work.type !== 'talk'" class="thumb-box">
                                            <span class="thumb">
                                                <img
                                                    :src="work.imgUrl | sThumbUrl"
                                                    onerror="javascript:this.src='/images/default.png'"
                                                />
                                            </span>
                                            <span v-if="work.type === 'work'" class="status" :class="classCount(work)">
                                                {{ finishCount(work) }}
                                            </span>
                                        </div>
                                        <p :inner-html.prop="work.content" />
                                    </a>
                                </div>
                                <div class="timeline-util">
                                    <a v-if="work.type !== 'work'" class="copygroup" @click="openPopShareRoom(work.id)">
                                        <i /><span>그룹방 전달</span>
                                    </a>
                                    <a class="comment view-comment" @click="showComment(work.id, work.commentId)">
                                        <i /><span class="blind">댓글</span><span>{{ work.countOfComment }}</span>
                                    </a>
                                    <a class="attach" @click="showWorkDetail(work.id)">
                                        <i />
                                        <span class="blind">첨부</span>
                                        <span>{{ work.countOfDocFile + work.countOfActionDocFile }}</span>
                                    </a>
                                    <a class="read" @click="showWorkDetail(work.id)">
                                        <i />
                                        <span class="blind">조회</span>
                                        <span v-if="work.type === 'work'">
                                            {{ work.countOfManager }} / {{ work.countOfView }}
                                        </span>
                                        <span v-else>{{ work.views }}</span>
                                    </a>
                                </div>
                            </div>
                        </li>
                        <li v-if="workOfRoom.works.length >= workOfRoom.total">
                            <span class="group-first-msg">{{ room.regDt | formatTime }} 그룹방이 개설되었습니다.</span>
                        </li>
                    </ul>
                </div>

                <!-- talk-input-wrapper -->
                <div ref="talkInput" class="talk-input-wrapper">
                    <div class="talk-input-box">
                        <textarea
                            id="comment-memo"
                            ref="commentMemo"
                            v-model="writeData.content"
                            cols="40"
                            rows="3"
                            placeholder="요청업무나 토크를 입력하세요."
                            autofocus
                            @keyup.enter.exact="registWork"
                            @keyup="cmaTextareaSize(40)"
                        />
                    </div>
                    <div class="attach-hashtag-mention-box">
                        <!-- tag-file-list-box -->
                        <div class="tag-file-list-box">
                            <ul>
                                <li v-for="(user, index) in writeData.managers" :key="'1' + index">
                                    <span class="tag">
                                        {{ user.name }}&middot;
                                        {{ user.companyName }}
                                        <a class="del-tags" @click="onClickManagerDel(user)">
                                            <span class="blind">삭제</span>
                                        </a>
                                    </span>
                                </li>
                                <li v-for="(tag, index) in writeData.tags" :key="'2' + index">
                                    <span class="tag">
                                        #{{ tag }}
                                        <a class="del-tags" @click="onClickTagDel(tag)">
                                            <span class="blind">삭제</span>
                                        </a>
                                    </span>
                                </li>
                                <li v-for="(file, index) in addFileList" :key="'3' + index">
                                    <span class="filename">
                                        {{ file.name }}
                                        <a class="del-file" @click="onClickFileDel(file)">
                                            <span class="blind">삭제</span>
                                        </a>
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <!-- // file-download-box -->
                    </div>

                    <div v-if="imgFileList.length > 0" class="photo-box">
                        <div class="photo-swiper">
                            <div class="swiper-container">
                                <swiper :options="swiperOption">
                                    <swiper-slide v-for="(img, index) in imgFileList" :key="index" class="swiper-slide">
                                        <img :src="img.dataUrl" @click="openImgEditor(img)" />
                                        <a class="del-photo" @click="onClickImgDel(img)">
                                            <i /><span class="blind">사진삭제</span>
                                        </a>
                                    </swiper-slide>
                                </swiper>
                            </div>
                        </div>
                        <div class="swiper-button-prev" />
                        <div class="swiper-button-next" />
                    </div>
                    <div class="talk-util-box">
                        <ul>
                            <li>
                                <a class="photo" @click="toggleFileBox()"> <i /><span class="blind">사진</span> </a>
                            </li>
                            <li>
                                <a class="attatch" @click="pickerFile()"> <i /><span class="blind">첨부파일</span> </a>
                            </li>
                            <li>
                                <a class="mention" @click="openPopManager()"> <i /><span class="blind">멘션</span> </a>
                            </li>
                            <li>
                                <a class="hashtag" @click="openPopTag()"> <i /><span class="blind">해시태그</span> </a>
                            </li>
                        </ul>
                        <a class="send" @click="registWork"> <i /><span class="blind">보내기</span> </a>
                        <div ref="fileBox" class="more-nav-box">
                            <ul>
                                <li><a @click="pickerImgFile()">내 컴퓨터</a></li>
                                <li><a @click="openPictureRoom()">사진함</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- // talk-input-wrapper -->
            </div>
            <!-- // timeline-box -->
            <input
                id="docFile"
                ref="docFile"
                type="file"
                :accept="DOC_ALLOW_FILE"
                class="blind"
                @change="doDocFileAttach"
            />

            <input
                id="gallery"
                ref="uploadGallery"
                type="file"
                class="blind"
                accept="image/*"
                @change="doGalleryAttach"
            />

            <pop-tag ref="popTag" tp-id="room-write" :bind-tags="writeData.tags" @setTags="setWriteTags" />
            <pop-manager
                ref="popManager"
                tp-id="room-write"
                :bind-managers="writeData.managers"
                @setManager="setWriteManager"
            />
            <pop-image-editor v-if="showImageEditor" ref="popImageEditor" @close="closeImgEditor" />
            <pop-picture-room
                v-if="showPictureRoom"
                ref="popPictureRoom"
                @close="closePictureRoom"
                @setImgs="doPictureRoomAttach"
            />
            <pop-share-room ref="popShareRoom" />
        </template>
        <template v-else>
            <div class="null-wrapper">
                <div class="null-box">
                    <img src="/images/img-coco-logo-gray.png" alt="" />
                    <p>그룹을 선택하시고 협업을 시작하세요.</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import WorkTagApi from '../../api/WorkTagApi';
import WorkApi from '../../api/WorkApi';
import FileApi from '../../api/FileApi';

import EventHelper from '../../helper/EventHelper';
import ArrayHelper from '../../helper/ArrayHelper';
import FileHelper from '../../helper/FileHelper';

import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

import WorkCondition from '../../model/WorkCondition';

import { toDayFormat, toDayFormatAddMonth } from '../../utils/date';
import { WORK_TYPE, WORK_TYPE_CSS } from '../../const';

import PopTag from '../popup/PopTag';
import PopManager from '../popup/PopManager';
import PopImageEditor from '../popup/PopImageEditor';
import PopPictureRoom from '../popup/PopPictureRoom';
import PopShareRoom from '../popup/PopShareRoom';

export default {
    name: 'Room',
    components: { swiper, swiperSlide, PopTag, PopManager, PopImageEditor, PopPictureRoom, PopShareRoom },
    props: {
        room: {
            type: Object,
            required: true
        }
    },
    data: () => {
        return {
            searchType: '',
            searchTag: '',
            tags: [],
            swiperOption: {
                slidesPerView: 'auto',
                navigation: {
                    nextEl: '.content-nav .swiper-button-next',
                    prevEl: '.content-nav .swiper-button-prev'
                }
            },
            works: [],
            condition: new WorkCondition(),
            writeData: {
                room: {},
                content: '',
                managers: [],
                tags: []
            },
            imgFileList: [],
            addFileList: [],
            DOC_ALLOW_FILE: FileHelper.allowFileExt(),
            ALLOW_FILE_IMG: FileHelper.allowImgExt(),
            docfileId: 0,
            docfileNo: 0,
            imgfileId: 0,
            imgfileNo: 0,
            showImageEditor: false,
            showPictureRoom: false,
            showSearch: false
        };
    },
    computed: {
        ...mapState({
            workOfRoom: state => state.work.workOfRoom,
            basic: state => state.main.basic
            //rooms: state => state.work.roomList
        })
    },
    created() {},
    mounted() {
        EventHelper.headerSearch();
    },
    beforeDestroy() {
        //window.removeEventListener('scroll', this.morePage);
    },
    methods: {
        ...mapActions([
            'getWorkOfRoom',
            'addWork',
            'setCommentId',
            'setWorkId',
            'setShowPopLeaveRoom',
            'setShowPopManageRoom',
            'showLoading',
            'hideLoading'
        ]),
        back() {
            this.$emit('back');
        },
        async refresh(id) {
            //console.log(this.room);
            //this.room = param;
            if (!id) id = this.room.id;
            this.resetData();
            this.condition.roomId = id;
            this.condition.searchText = '';
            this.condition.beginDay = toDayFormatAddMonth(-3);
            this.condition.endDay = toDayFormat();
            this.condition.page = 1;
            this.tags = await WorkTagApi.rankOfroom(id);
            //this.showLoading();
            await this.doSearch();
            //this.hideLoading();
        },
        focusInput() {
            this.$refs.commentMemo.focus();
        },
        clickSearch() {
            this.condition.page = 1;
            this.doSearch();
        },
        async doSearch() {
            //if (this.condition.page === 1) $('html').scrollTop(0);
            await this.getWorkOfRoom(this.condition.getPayload());

            if (this.workOfRoom.works.length > 0) {
                const joinId = { csId: this.basic.csId, workerId: this.basic.id, workRoomId: this.room.id };
                WorkApi.updateReadWork({ id: joinId, listWorkId: this.workOfRoom.works[0].id });
                this.styleListBox();
            }

            //this.focusInput();
        },
        async getRankTag() {
            this.tags = await WorkTagApi.rankOfroom(this.room.id);
        },
        doSearchWork(type) {
            this.searchType = type;
            this.searchTag = '';
            this.condition.reset();
            this.condition.roomId = this.room.id;
            this.condition.page = 1;
            this.condition.type = '';
            if (type === 'request') {
                this.condition.registerId = this.basic.id;
            } else {
                this.condition.managerIds = [this.basic.id];
            }
            this.doSearch();
        },
        doSearchType(type) {
            //this.condition;
            this.searchType = type;
            this.searchTag = '';
            this.condition.reset();
            this.condition.roomId = this.room.id;
            this.condition.page = 1;
            if (type) this.condition.type = type;
            this.doSearch();
            //this.$emit('doSearch');
        },
        doSearchTag(tag) {
            //this.condition;
            this.searchType = 'x';
            this.searchTag = tag;
            //this.classReset($(event.target));
            this.condition.reset();
            this.condition.roomId = this.room.id;
            this.condition.page = 1;
            if (tag) this.condition.tags = [tag];
            this.doSearch();
            //this.$emit('doSearch');
        },
        taskName(work) {
            return WORK_TYPE[work.type];
        },
        taskType(work) {
            return WORK_TYPE_CSS[work.type];
        },
        classCount(work) {
            if (work.finishYn === 'Y') return 'type-3';
            else if (work.countOfAction === 0) return 'type-2';
            return 'type-1';
        },
        finishCount(work) {
            if (work.finishYn === 'Y') return '완료';
            return work.countOfAction;
        },
        showComment(workId, commentId) {
            const param = { workId, commentId };
            this.setCommentId(param);
            this.$emit('show', 'comment');
        },
        showWorkDetail(workId) {
            this.setWorkId(workId);
            this.$emit('show', 'detail');
        },
        setWriteTags(tags) {
            this.writeData.tags = tags;
            this.writeData.tag = tags;
            this.styleListBox();
        },
        setWriteManager(workers) {
            this.writeData.managers = workers;
            this.writeData.manager = workers.map(worker => worker.id);
            this.styleListBox();
        },
        resetData() {
            this.writeData.content = '';
            this.writeData.room = {};
            this.writeData.type = '';
            this.writeData.managers = [];
            this.writeData.tags = [];
            this.writeData.manager = [];
            this.writeData.tag = [];
            this.writeData.fileId = null;
            this.docFileId = 0;
            this.docfileNo = 0;
            this.imgfileId = 0;
            this.imgfileNo = 0;
            this.addFileList = [];
            this.imgFileList = [];
        },
        openPopTag() {
            this.$refs.popTag.open(this.room.id);
        },
        openPopManager() {
            this.$refs.popManager.open(this.room.id);
        },
        openPopShareRoom(id) {
            this.$refs.popShareRoom.open(id);
        },
        onClickImgDel(fileInfo) {
            const condition = item => item.fileNo === fileInfo.fileNo;
            ArrayHelper.oneRemove(this.imgFileList, condition);
        },
        onClickFileDel(fileInfo) {
            const condition = item => item.fileNo === fileInfo.fileNo;
            ArrayHelper.oneRemove(this.addFileList, condition);
        },
        onClickTagDel(tag) {
            const condition = item => item.tag === tag;
            ArrayHelper.oneRemove(this.writeData.tags, condition);
        },
        onClickManagerDel(user) {
            const condition = item => item.id === user.id;
            ArrayHelper.oneRemove(this.writeData.managers, condition);
        },
        pickerFile() {
            this.$refs.docFile.value = '';
            if (FileHelper.isCount(this.addFileList.length)) this.$refs.docFile.click();
        },
        doDocFileAttach() {
            const fileObject = this.$refs.docFile.files[0];
            if (fileObject) {
                const size = fileObject.size;
                if (FileHelper.isNewSize(size, fileObject.name) && FileHelper.isAllowFile(fileObject.name))
                    this.addDocFileList(fileObject);
            }
        },
        async registWork() {
            if (this.writeData.content && this.writeData.content.trim()) {
                //this.showLoading();
                this.docfileNo = 0;
                if (this.addFileList.length > 0) {
                    //this.showLoading();
                    for (var docFile of this.addFileList) {
                        const docFileForm = docFile.form;
                        if (this.docfileId > 0) docFileForm.set('file_id', this.docfileId);
                        docFileForm.set('file_no', ++this.docfileNo);
                        const fileRes = await FileApi.fileUpload(docFileForm, 'doc');
                        if (fileRes.content) {
                            this.docfileId = fileRes.content[0].id.fileId;
                            this.writeData.docFileId = this.docfileId;
                        }
                    }
                }
                const moveImgs = [];
                /* 이미지 업로드 */
                if (this.imgFileList.length > 0) {
                    this.imgfileNo = 0;
                    for (var imgFile of this.imgFileList) {
                        const imgFileForm = new FormData();
                        //const now = new Date();
                        //const imageName = now.getTime() + '.jpg';

                        imgFileForm.append('file_id', '');
                        imgFileForm.append('file_no', imgFile.fileNo);
                        imgFileForm.append('file_name', imgFile.name);
                        //imgFileForm.append('file_name', imgFile.name);
                        imgFileForm.append('file', imgFile.data);

                        if (this.imgfileId > 0) imgFileForm.set('file_id', this.imgfileId);
                        imgFileForm.set('file_no', ++this.imgfileNo);
                        if (imgFile.type === 'file') {
                            const fileRes = await FileApi.imgUpload(imgFileForm);
                            if (fileRes.content && this.imgfileId === 0) {
                                this.imgfileId = fileRes.content.fileId;
                            }
                        } else if (imgFile.type === 'blob') {
                            const fileRes = await FileApi.blolImgUpload(imgFileForm);
                            if (fileRes.content && this.imgfileId === 0) {
                                this.imgfileId = fileRes.content.fileId;
                            }
                        } else {
                            moveImgs.push(imgFile.data);
                        }
                    }
                    if (this.imgfileId > 0) this.writeData.fileId = this.imgfileId;
                }

                this.writeData.room.id = this.room.id;
                if (this.writeData.managers.length > 0) this.writeData.type = 'work';
                else if (this.imgFileList.length > 0) this.writeData.type = 'picture';
                else this.writeData.type = 'talk';

                this.writeData.finishYn = 'N';
                this.writeData.moveImgs = moveImgs;

                //console.log(this.writeData);
                await this.addWork(this.writeData);
                window.stompClient.send(
                    '/app/chat.message/' + this.room.id,
                    JSON.stringify({ roomId: this.room.id }),
                    {}
                );
                await this.refresh(this.room.id);
                this.resetData();
                //this.hideLoading();
            }
        },
        pickerImgFile() {
            this.$refs.uploadGallery.value = '';
            this.$refs.fileBox.classList.remove('on');
            if (FileHelper.isCount(this.imgFileList.length)) this.$refs.uploadGallery.click();
        },
        doGalleryAttach() {
            const fileObject = this.$refs.uploadGallery.files[0];
            //console.log(fileObject)
            if (fileObject) {
                this.addImgFileList(fileObject);
            }
        },
        openImgEditor(imgObject) {
            this.showImageEditor = true;
            setTimeout(() => {
                this.$refs.popImageEditor.setDataUrlImage(imgObject);
            }, 100);
        },
        closeImgEditor() {
            this.showImageEditor = false;
        },
        toggleFileBox() {
            this.$refs.fileBox.classList.toggle('on');
        },
        openPictureRoom() {
            //alert('open picture');
            this.$refs.fileBox.classList.remove('on');
            this.showPictureRoom = true;
        },
        closePictureRoom() {
            this.showPictureRoom = false;
        },
        doPictureRoomAttach(pictureRoomImgs) {
            this.closePictureRoom();
            pictureRoomImgs.forEach(item => {
                ++this.imgfileNo;
                const now = new Date();
                const imageName = now.getTime() + '.jpg';
                const addFile = {
                    fileNo: this.imgfileNo,
                    name: imageName,
                    type: 'picture',
                    dataUrl: item.imgUrl,
                    data: item.fileId
                };

                this.imgFileList.push(addFile);
            });
        },
        async clickRemoveGroup(room) {
            //this.showPopLeaveRoom = true;
            this.toggleGroupBox();
            if (room.master.id === this.basic.id && room.countOfMember > 1) {
                alert('방장을 이전 후 그룹 탈퇴가 가능합니다.');
                this.$emit('update:room', room);
                await this.$emit('changeOwner');
                return;
            }
            await this.$emit('update:room', room);
            await this.setShowPopLeaveRoom(true);
        },
        clickManageGroup(room) {
            this.toggleGroupBox();
            this.$emit('update:room', room);
            this.setShowPopManageRoom(true);
        },
        toggleGroupBox() {
            this.$refs.groupBox.classList.toggle('on');
        },
        morePage() {
            const box = this.$refs.listBox;
            if (box.scrollTop + box.clientHeight >= box.scrollHeight) {
                this.condition.page = this.condition.page + 1;
                if (this.workOfRoom.total > this.workOfRoom.works.length) this.doSearch();
            }
        },
        styleListBox() {
            this.$nextTick(() => {
                this.$refs.listBox.style.height = 'calc(100% - ' + (this.$refs.talkInput.scrollHeight + 5) + 'px)';
                this.focusInput();
            });
        },
        addFile(e) {
            const droppedFiles = e.dataTransfer.files;
            if (!droppedFiles) return;

            for (var key in droppedFiles) {
                var fileObject = droppedFiles[key];
                if (FileHelper.isAllowImgFile(fileObject.name)) {
                    //이미지쪽에 첨부
                    this.addImgFileList(fileObject);
                } else if (FileHelper.isAllowFile(fileObject.name)) {
                    //파일쪽에 첨부한다.
                    this.addDocFileList(fileObject);
                }
            }
        },
        addImgFileList(fileObject) {
            const reader = new FileReader();
            reader.onload = e => {
                ++this.imgfileNo;

                const addFile = {
                    fileNo: this.imgfileNo,
                    name: fileObject.name,
                    type: 'file',
                    dataUrl: e.target.result,
                    data: fileObject
                };

                this.imgFileList.push(addFile);
                this.styleListBox();
            };
            reader.readAsDataURL(fileObject); //
        },
        addDocFileList(fileObject) {
            if (FileHelper.isAllowFile(fileObject.name)) {
                ++this.docfileNo;
                const formData = new FormData();
                formData.append('file_id', '');
                formData.append('file_no', this.docfileNo);
                formData.append('file', fileObject);
                const addFile = {
                    fileNo: this.docfileNo,
                    name: fileObject.name,
                    form: formData
                };
                this.addFileList.push(addFile);
            }
            this.styleListBox();
        },
        getNullWidth() {
            if (this.room.id) return false;
            const width = document.body.clientWidth;
            if (width > 400) return true;
            else false;
        },
        moreSearch(isShow) {
            this.showSearch = isShow;
        },
        cmaTextareaSize(bsize) {
            // 객체명, 기본사이즈
            const sTextarea = this.$refs.commentMemo;
            const lineCount = sTextarea.value.split('\n').length;
            if (lineCount > 5) return;
            var csize = 0;
            if (lineCount < 5 && sTextarea.scrollHeight - lineCount * 9 >= bsize) {
                csize = sTextarea.scrollHeight - 9 + 'px';
            } else {
                csize = sTextarea.scrollHeight >= bsize ? sTextarea.scrollHeight + 'px' : bsize + 'px';
            }
            sTextarea.style.height = bsize + 'px';
            sTextarea.style.height = csize;
        }
    }
};
</script>
