<template>
    <div class="content-side-wrapper">
        <!-- content-header -->
        <header class="content-header">
            <div class="header-box">
                <div class="header-nav">
                    <a class="back" @click="back"><i /><span class="blind">뒤로가기</span></a>
                </div>
                <div class="header-title">
                    <span>{{ boardName[boardType] }} 상세</span>
                </div>
            </div>
            <div ref="headerUtil" class="header-util">
                <a class="photo-print" @click="printElement()"> <i /><span class="blind">인쇄</span> </a>
                <a v-if="auth" class="more-nav" @click="toggleBox()"><i /><span class="blind">더보기</span></a>
                <div ref="noticeBox" class="more-nav-box">
                    <ul>
                        <li>
                            <a @click="doModify">공지 수정</a>
                        </li>
                        <li>
                            <a @click="doRemove">공지 삭제</a>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
        <!-- // content-header -->
        <!-- notice-detail-wrapper -->
        <div ref="noticeDetail" class="notice-detail-wrapper">
            <div class="notice-detail-header">
                <p>{{ board.title }}</p>
                <div class="notice-meta">
                    <span>
                        {{ board.register.name }} {{ board.register.positionName }}&middot;
                        {{ board.register.company.name }}
                    </span>
                    <span class="date">{{ board.regDt | longFormatTime }}</span>
                </div>
                <!-- tag-file-list-box -->
                <div class="tag-file-list-box">
                    <ul>
                        <li v-for="(file, index) in fileList" :key="index">
                            <a class="filename" @click="download(file)">
                                <span>{{ file.name }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- // tag-file-list-box -->
            </div>
            <div class="notice-detail-body">
                <div class="notice-memo" v-html="board.content" />
                <div v-if="boardType === 'qna'" class="notice-memo-reply">
                   <div  class="sub_tit">기술문의 답변</div>
                   <p v-html="board.answer || '답변전 입니다.'" />
                </div>
            </div>

        </div>
        <!-- // notice-detail-wrapper -->
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BoardApi from '../../api/BoardApi';
import FileApi from '../../api/FileApi';

/* Helper */
import ArrayHelper from '../../helper/ArrayHelper';
import { isAuth } from '../../helper/AuthHelper';
//import { convertCrToBr } from '../../utils/string';

export default {
    name: 'BoardDetail',
    components: {},
    props: {
        bid: {
            type: Number,
            required: true
        }
    },
    data: () => {
        return {
            auth: false,
            board: { register: { company: '' } },
            boardName: { notice: '공지', qna: '기술문의' },
            boardType: '',
            fileList: []
        };
    },
    computed: {
        ...mapState({})
    },
    watch: {
        bid: {
            // the callback will be called immediately after the start of the observation
            immediate: true,
            handler(val) {
                if (val) {
                    this.bid = val;
                    this.detail();
                }
            }
        }
    },
    created() {
        this.boardType = this.$route.name.toLowerCase();
    },
    mounted() {
        this.boardType = this.$route.meta.type;
        this.board.type = this.boardType;
        this.board.noticeYn = 'N';
    },
    beforeDestroy() {
        //console.log('event제거');
    },
    methods: {
        ...mapActions(['hideLoading']),
        reset() {
            this.auth = false;
            this.board = { register: { company: '' } };
            this.fileList = [];
        },
        hide(board) {
            BoardApi.hide(board.id).then(() => {
                const condition = item => item.id === board.id;
                ArrayHelper.oneRemove(this.board, condition);
            });
        },
        doModify() {
            //console.log('modify 페이지로 이동함', this.board);
            this.$emit('show', 'regist', this.bid);
        },
        async doRemove() {
            if (confirm('삭제 하시겠습니까?')) {
                await BoardApi.remove(this.board.id);
                this.$emit('refresh', null);
            }
        },
        async detail() {
            this.reset();
            if (this.bid) {
                this.board = await BoardApi.detail(this.bid);
                this.auth = isAuth(this.board.register.id);
                if (this.board.fileId) this.fileList = await FileApi.list(this.board.fileId);
            }
        },
        download(file) {
            FileApi.fileDownload(file.id);
        },
        back() {
            this.$emit('back');
        },
        toggleBox() {
            this.$refs.noticeBox.classList.toggle('on');
        },
        printElement() {
            this.$refs.noticeBox.classList.remove('on');
            //const wrap = document.getElementById('wrap');
            const headerUtil = this.$refs.headerUtil;
            const sidebar = document.getElementById('sidebar');
            const content = document.getElementById('notice-list');
            sidebar.style.display = 'none';
            content.style.display = 'none';
            headerUtil.style.display = 'none';
            window.print();
            headerUtil.style.display = '';
            sidebar.style.display = '';
            content.style.display = '';
        }
    }
};
</script>
