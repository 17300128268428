export const getLanguage = lang => {
    /***
     * List of value that could be 'navigator.language'
     *
     * ja,
     * zh-TW,
     * zh-tw
     * zh-CN,
     * zh-cn,
     * en-us,
     * en-US,
     * ko,
     * ja-jp,
     * th,
     * ko-kr,
     * en-gb,
     * zh-cn,
     * th-th,
     * es,
     * id,
     * fr,
     *  zh-hk,
     *  undefined
     * */
    let language = lang || navigator.language || 'ja';
    /***
     * '-' -> '_' replace and toLowerCase
     */
    language = language
        .replace('-', '_')
        .toLowerCase()
        .substr(0, 2);

    if (['ko'].indexOf(language) === -1) {
        language = 'ko';
    }
    /**
     * Use only two characters unless it is 'simplified Chinese'(간체) or 'traditional Chinese'(번체)
     * */
    return language;
};
