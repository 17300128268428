<template>
    <!-- modal : modal-photo-detail -->
    <div class="modal modal-photo-detail" :class="{ on: classOn }">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-nav" />
                    <div class="header-title">
                        <span>사진 상세</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="btn-photo-download" @click="imgClipboard()"><span>사진 복사</span></a>
                    <a class="btn-photo-download" @click="downloadPhoto()"><span>사진 다운로드</span></a>
                    <a class="btn-photo-print" @click="print()"><span>사진 출력</span></a>
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->

            <!-- modal-body -->
            <div class="modal-body">
                <div class="photo-view-box">
                    <div v-if="photos.length > 0" class="swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div :id="tpId + '-print'" class="img-box">
                                    <img
                                        ref="cocoPhotoImg"
                                        :src="photos[curIdx].url"
                                        onerror="javascript:this.src='/images/default.png'"
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-show="!isFirst" class="swiper-button-prev" @click="prePhoto" />
                        <div v-show="!isLast" class="swiper-button-next" @click="nextPhoto" />
                    </div>
                </div>

                <div class="photo-detail-box">
                    <div class="charger-info">
                        <div class="user-summary">
                            <div class="user-thumb">
                                <span class="thumb">
                                    <img
                                        :src="work.register.imgUrl | profileUrl"
                                        onerror="javascript:this.src='/images/example/img-profile.png'"
                                    />
                                </span>
                            </div>
                            <div class="user-info">
                                <p class="user-nm">
                                    {{ work.register.displayName }}&middot;{{ work.register.company.name }}
                                </p>
                                <div>
                                    <p v-if="work.room">
                                        <span>{{ work.room.name }}</span>
                                    </p>
                                    <p>
                                        <span :class="getClass()">{{ work.typeName }}</span>
                                        <span>{{ work.regDt | formatTime }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="photo-desc-box">
                        <div class="form-box">
                            <div class="field-box">
                                <div v-if="photos.length > 0" class="field with-btn">
                                    <input
                                        v-model="photos[curIdx].name"
                                        type="text"
                                        class="txt"
                                        maxlength="100"
                                        readonly
                                    />
                                </div>
                            </div>
                            <p class="desc">
                                {{ getContent() }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- // modal-body -->
        </div>
    </div>
    <!-- // modal : modal-photo-detail -->
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FileApi from '../../api/FileApi';
import ElectronHelper from '../../helper/ElectronHelper';
//import ArrayHelper from '../../helper/ArrayHelper';

export default {
    name: 'PopPhotoDetail',
    components: {},
    props: {
        bindPhotos: {
            type: Array,
            required: false,
            default: null
        },
        tpId: {
            type: String,
            required: true
        }
    },
    data: () => {
        return {
            classOn: false,
            isFileEdit: false,
            photos: [],
            work: { id: null, register: { company: {}, displayName: '' }, room: {} },
            curIdx: 0,
            isLast: false,
            isFirst: true
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    watch: {
        bindPhotos: {
            // the callback will be called immediately after the start of the observation
            immediate: true,
            handler(val) {
                if (val) this.photos = val;
            }
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        ...mapActions(['showLoading', 'hideLoading']),
        close() {
            this.classOn = false;
            this.$emit('update:show', false);
        },
        selectId(work) {
            this.work = work;
            this.curIdx = 0;
            this.isLast = this.curIdx >= this.photos.length - 1;
            this.isFirst = this.curIdx === 0;
            this.classOn = true;
        },
        getContent() {
            if (this.work.type === 'action') return this.work.action.content;
            else return this.work.content;
        },
        prePhoto() {
            --this.curIdx;
            this.isFirst = this.curIdx < 1;
            //this.isLast = this.curIdx >= this.photos.length - 1;
            this.isLast = false;
        },
        nextPhoto() {
            ++this.curIdx;
            this.isLast = this.curIdx >= this.photos.length - 1;
            this.isFirst = false;
            //if (this.curIdx < this.photos.length - 1) this.work = this.photos[this.curIdx + 1];
        },
        getClass() {
            if (this.work.type === 'action') return 'talk-color';
            else return this.work.type + '-color';
        },
        print() {
            window.print();
        },
        async downloadPhoto() {
            if (this.photos.length > 0) {
                this.showLoading();
                for (var item of this.photos) {
                    const param = {
                        fileId: item.id.fileId,
                        fileNo: item.id.fileNo
                    };
                    await FileApi.fileDownload(param);
                }
                this.hideLoading();
            }
        },
        imgClipboard() {
            //console.log('electron-img-copy|' + this.$refs.cocoPhotoImg.src);
            ElectronHelper.imageClipboard(this.$refs.cocoPhotoImg.src);
        }
    }
};
</script>
