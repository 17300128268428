<template>
    <div id="content-wrap" class="content-side-1">
        <div class="content-side-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-title">
                        <span>작업일보 현황</span>
                    </div>
                </div>
                <div class="header-util">
                    <a v-if="basic.csAdminYn === 'Y'" class="report-list" @click="goReport">
                        <i /><span class="blind">작업일보 현황</span>
                    </a>
                    <a class="winmin modal-link" @click="defaultSize"> <i /><span class="blind">창축소</span> </a>
                </div>
            </header>
            <!-- // content-header -->

            <!-- search-item-list -->
            <div class="search-item-list full">
                <!-- select-box -->
                <!-- // select-box -->

                <div class="change-yy-mm">
                    <a class="prev-yy-mm" @click="beforeMonth"><i /><span class="blind">이전달</span></a>
                    <span>{{ yearmonth }}</span>
                    <a class="next-yy-mm" @click="nextMonth"><i /><span class="blind">다음달</span></a>
                </div>
            </div>
            <!-- // search-item-list -->

            <div class="report-wrapper">
                <div class="mm-dd-wrapper">
                    <div class="mm-dd-box">
                        <ul>
                            <li v-for="(day, index) in arrDay" :key="index">
                                <a :class="{ on: day.ymd === workDaily.today }" @click="onChangeDate(day.ymd)">
                                    <div class="dd">{{ day.day }}</div>
                                    <div class="report-is" :class="day.work ? 'on' : 'off'" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="report-detail-box">
                    <div class="report-detail-header">
                        <p class="report-title">
                            {{ formatStringToDay(workDaily.today) }}
                            ({{ getWeekOfDay(formatStringToDay(workDaily.today)) }}) 작업일보
                        </p>
                        <div class="weather-print-box">
                            <a class="btn-del-report" @click="doRemove()"><i /><span>삭제</span></a>
                            <a class="btn-save-report" @click="onSave()"><i /><span>임시저장</span></a>
                            <a class="btn-upload-report" @click="onSave('submit')"><i /><span>제출완료</span></a>
                        </div>
                    </div>

                    <div class="report-tab-wrapper">
                        <div class="tab-nav">
                            <ul>
                                <li>
                                    <a :class="{ on: showTab('content') }" @click="onClickTab('content')">
                                        <span>작업내용</span>
                                    </a>
                                </li>
                                <li>
                                    <a :class="{ on: showTab('occupation') }" @click="onClickTab('occupation')">
                                        <span>출역현황</span>
                                    </a>
                                </li>
                                <li>
                                    <a :class="{ on: showTab('equip') }" @click="onClickTab('equip')">
                                        <span>장비현황</span>
                                    </a>
                                </li>
                                <li class="add-menu-1st" :class="{ hide: showTab('content') }">
                                    <a class="btn" @click="addRow"><span>행 추가</span></a>
                                </li>
                                <li class="add-menu-2nd" :class="{ hide: showTab('content') }">
                                    <a class="btn" @click="removeRow"><span>행 삭제</span></a>
                                </li>
                                <li class="add-menu-2nd" :class="{ hide: !showTab('content') }">
                                    <a class="btn" @click="onCopy()"><span>이전 데이터복사</span></a>
                                </li>
                            </ul>
                        </div>
                        <div class="tab-content-wraper">
                            <div class="tab-content" :class="showTab('content') ? '' : 'hide'">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>전일 작업내용</th>
                                            <th>금일 작업내용</th>
                                            <th>명일 작업내용</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="left">
                                                <textarea v-model="workDaily.preContent" readonly />
                                            </td>
                                            <td class="left">
                                                <textarea
                                                    v-model="workDaily.todayContent"
                                                    placeholder="내용을 입력하세요."
                                                />
                                            </td>
                                            <td class="left">
                                                <textarea
                                                    v-model="workDaily.nextContent"
                                                    placeholder="내용을 입력하세요."
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="tab-content" :class="showTab('occupation') ? '' : 'hide'">
                                <table>
                                    <colgroup>
                                        <col style="width:40px" />
                                        <col style="width:40px" />
                                        <col style="width:auto" />
                                        <col style="width:11.8%" />
                                        <col style="width:11.8%" />
                                        <col style="width:11.8%" />
                                        <col style="width:11.8%" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th><input type="checkbox" /></th>
                                            <th>NO</th>
                                            <th>직종</th>
                                            <th>전일누계</th>
                                            <th>전일투입수</th>
                                            <th>금일투입수</th>
                                            <th>누계</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(occupation, index) in workDaily.occupations" :key="index">
                                            <td class="center">
                                                <input
                                                    :id="'report-chk-occupation-' + index"
                                                    v-model="checkOccupations"
                                                    type="checkbox"
                                                    :value="occupation.id.code.code"
                                                />
                                            </td>
                                            <td class="center">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="center">
                                                {{ occupation.id.code.name }}
                                            </td>
                                            <td>{{ occupation.preCountSum | numComma }}</td>
                                            <td>
                                                <input
                                                    v-model="occupation.preCount"
                                                    type="text"
                                                    class="txt report-type-1"
                                                    readonly
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    v-model="occupation.putCount"
                                                    type="text"
                                                    class="txt report-type-2"
                                                />
                                            </td>
                                            <td class="total">
                                                {{
                                                    (Number(occupation.preCountSum) + Number(occupation.putCount))
                                                        | numComma
                                                }}
                                            </td>
                                        </tr>
                                        <tr class="total">
                                            <td colspan="3" class="center">
                                                총계
                                            </td>
                                            <td>{{ calcOccupationTotal('sum') }}</td>
                                            <td>{{ calcOccupationTotal('pre') }}</td>
                                            <td>{{ calcOccupationTotal('put') }}</td>
                                            <td>{{ calcOccupationTotal('all') }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="tab-content" :class="showTab('equip') ? '' : 'hide'">
                                <table>
                                    <colgroup>
                                        <col style="width:40px" />
                                        <col style="width:40px" />
                                        <col style="width:auto" />
                                        <col style="width:auto" />
                                        <col style="width:11.8%" />
                                        <col style="width:11.8%" />
                                        <col style="width:11.8%" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th><input type="checkbox" @click="allEquip" /></th>
                                            <th>NO</th>
                                            <th>장비명</th>
                                            <th>규격</th>
                                            <th>전일투입수</th>
                                            <th>금일투입수</th>
                                            <th>누계</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(equip, index) in workDaily.equips" :key="index">
                                            <td class="center">
                                                <input
                                                    :id="'report-chk-equip-' + index"
                                                    v-model="checkEquips"
                                                    type="checkbox"
                                                    :value="equip.id.code.code"
                                                />
                                            </td>
                                            <td class="center">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="center">
                                                {{ equip.id.code.name }}
                                            </td>
                                            <td class="center">
                                                {{ equip.id.code.spec }}
                                            </td>
                                            <td>
                                                <input
                                                    v-model="equip.preCount"
                                                    type="text"
                                                    class="txt report-type-1"
                                                    readonly
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    v-model="equip.putCount"
                                                    type="text"
                                                    class="txt report-type-2"
                                                    value="5"
                                                />
                                            </td>
                                            <td class="total">
                                                {{ (Number(equip.preCountSum) + Number(equip.putCount)) | numComma }}
                                            </td>
                                        </tr>
                                        <tr class="total">
                                            <td colspan="4" class="center">
                                                총계
                                            </td>
                                            <td class="center">
                                                {{ calcEquipTotal('pre') }}
                                            </td>
                                            <td class="center">
                                                {{ calcEquipTotal('put') }}
                                            </td>
                                            <td class="total">
                                                {{ calcEquipTotal('all') }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pop-equip ref="filterEquip" @setEquips="setEquips" />
        <pop-occupation ref="filterOccupation" @setOccupations="setOccupations" />
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import WorkDailyApi from '../../api/WorkDailyApi';
import { isWorkDailyAuth } from '../../helper/AuthHelper';
import ElectronHelper from '../../helper/ElectronHelper';
import {
    toDayFormat,
    //formatDate,
    formatStringToDay,
    formatPureDate,
    formatWithLocale,
    getWeekOfDay
} from '../../utils/date';

import PopEquip from './PopEquip.vue';
import PopOccupation from './PopOccupation.vue';

import MenuHelper from '../../helper/MenuHelper';

//import { formatDate } from '../../utils/date';

export default {
    name: 'WorkDailyRegist',
    components: { PopEquip, PopOccupation },
    data: () => {
        return {
            wid: null,
            checkOccupations: [],
            checkEquips: [],
            totalEquip: { putCount: 0, preCountSum: 0, preCount: 0 },
            workDaily: { id: null, equips: [], occupations: [], today: toDayFormat(), status: null },
            selectedTab: 'content',
            selectedContent: { pre: false, today: true, next: false },
            sumitText: '[작성전입니다]',
            today: new Date(),
            arrDay: [],
            yearmonth: '',
            isAllEquip: false,
            isAllOccupation: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        //this.wid = this.$route.params.id;
        ElectronHelper.windowWorkDailyExpand();
        this.bindDetail();
        this.monthBuild();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        back() {
            this.$router.go(-1);
        },
        getWeekOfDay,
        isWorkDailyAuth,
        formatStringToDay,
        bindSubmitText() {
            if (this.workDaily.status === 'temp') this.sumitText = '[임시저장중입니다]';
            else if (this.workDaily.status === 'submit') this.sumitText = '';
            else this.sumitText = '[작성전입니다]';
        },
        async bindDetail() {
            if (this.wid) {
                this.workDaily = await WorkDailyApi.detail(this.wid);
            } else {
                //console.log(getWeekOfDay(this.workDaily.today));
                this.workDaily = (await WorkDailyApi.detailByDay(this.workDaily.today)) || {
                    id: null,
                    equips: [],
                    occupations: [],
                    today: this.workDaily.today,
                    status: null
                };
            }
            //this.workDaily.today = formatDate(this.workDaily.today);
            this.bindSubmitText();

            this.$refs.filterOccupation.bind(this.workDaily.today);
            this.$refs.filterEquip.bind(this.workDaily.today);
        },
        onClickTab(value) {
            this.selectedTab = value;
        },
        showTab(value) {
            return this.selectedTab === value;
        },
        onClickContent(value) {
            this.selectedContent[value] = !this.selectedContent[value];
        },
        showContent(value) {
            return this.selectedContent[value];
        },
        setEquips(equips) {
            const targetRemove = this.workDaily.equips;
            const newItems = equips.filter(item => {
                if (targetRemove.findIndex(remove => remove.id.code.code === item.id.code.code) < 0) return item;
            });
            this.workDaily.equips.push(...newItems);
            equips = [];
        },
        setOccupations(occupations) {
            //이미 여기서 데이서 이전, 누적데이터가 필요함.
            const targetRemove = this.workDaily.occupations;
            const newItems = occupations.filter(item => {
                if (targetRemove.findIndex(remove => remove.id.code.code === item.id.code.code) < 0) return item;
            });
            this.workDaily.occupations.push(...newItems);
            occupations = [];
        },
        onEquip() {
            //EventHelper.onEquip();
            this.$refs.filterEquip.open();
        },
        onOccupation() {
            //EventHelper.onOccupation();
            this.$refs.filterOccupation.open();
        },
        onChangeDate(ymd) {
            this.wid = null;
            this.workDaily.today = ymd;
            this.bindDetail();
        },
        calcOccupationTotal(type) {
            if (type === 'pre') {
                return this.workDaily.occupations.reduce((sum, item) => sum + Number(item.preCount), 0);
            } else if (type === 'put') {
                return this.workDaily.occupations.reduce((sum, item) => sum + Number(item.putCount), 0);
            } else if (type === 'sum') {
                return this.workDaily.occupations.reduce((sum, item) => sum + Number(item.preCountSum), 0);
            } else if (type === 'all') {
                return this.workDaily.occupations.reduce(
                    (sum, item) => sum + Number(item.preCountSum) + Number(item.putCount),
                    0
                );
            }
        },
        calcEquipTotal(type) {
            if (type === 'pre') {
                return this.workDaily.equips.reduce((sum, item) => sum + Number(item.preCount), 0);
            } else if (type === 'put') {
                return this.workDaily.equips.reduce((sum, item) => sum + Number(item.putCount), 0);
            } else if (type === 'sum') {
                return this.workDaily.equips.reduce((sum, item) => sum + Number(item.preCountSum), 0);
            } else if (type === 'all') {
                return this.workDaily.equips.reduce(
                    (sum, item) => sum + Number(item.preCountSum) + Number(item.putCount),
                    0
                );
            }
        },
        onClickDelOccupation() {
            if (this.checkOccupations.length === 0) {
                alert('삭제할 직종을 선택하세요.');
                return;
            }
            const targetRemove = this.workDaily.occupations;
            const newItems = targetRemove.filter(item => {
                if (this.checkOccupations.findIndex(remove => item.id.code.code === remove) < 0) return item;
            });
            this.workDaily.occupations = newItems;
            this.checkOccupations = [];
        },
        onClickDelEquip() {
            if (this.checkEquips.length === 0) {
                alert('삭제할 장비를 선택하세요.');
                return;
            }
            const targetRemove = this.workDaily.equips;
            const newItems = targetRemove.filter(item => {
                if (this.checkEquips.findIndex(remove => item.id.code.code === remove) < 0) return item;
            });
            this.workDaily.equips = newItems;
            this.checkEquips = [];
        },
        async onSave(status) {
            //console.log(this.workDaily);
            if (status === 'submit') {
                if (confirm('작업일보를 제출하시겠습니까?')) {
                    this.workDaily.status = status;
                    const res = await WorkDailyApi.regist(this.workDaily);
                    if (res) {
                        //MenuHelper.workDaily(this.$router);
                    }
                }
            } else {
                status = status || 'temp';
                this.workDaily.status = status;
                const response = await WorkDailyApi.regist(this.workDaily);
                this.workDaily.id = response.content.id;
                this.workDaily.status = response.content.status;
                //this.bindSubmitText();
                await this.bindDetail();
            }
        },
        async onCopy() {
            if (
                confirm(
                    '이전 작업일보를 복사를 하실 경우 만약 \n작성하신 작업내용이 있으면 삭제됩니다. \n복사하시겠습니까?'
                )
            ) {
                const res = await WorkDailyApi.copyPrevData(this.workDaily.today);
                if (res) {
                    alert('복사 되었습니다.');
                    await this.bindDetail();
                }
            }
        },
        beforeMonth() {
            this.today = new Date(this.today.getFullYear(), this.today.getMonth() - 1, this.today.getDate());
            this.monthBuild(); //만들기
        },
        nextMonth() {
            this.today = new Date(this.today.getFullYear(), this.today.getMonth() + 1, this.today.getDate());
            this.monthBuild(); //만들기
        },
        async monthBuild() {
            this.arrDay = [];
            const curMonth = new Date(this.today.getFullYear(), this.today.getMonth(), 1); //현재달의 첫째 날
            //const arrDay = [];
            const lastDate = new Date(this.today.getFullYear(), this.today.getMonth() + 1, 0); //현재 달의 마지막 날
            const curTime = curMonth.getDate();
            this.yearmonth = this.today.getFullYear() + '.' + (this.today.getMonth() + 1);

            const condition = {
                page: 1,
                rows: 50,
                csId: this.basic.csId,
                companyId: this.basic.companyId,
                //companyId: 3,
                today: formatWithLocale(this.today, 'YYYYMM')
                //today: '202006'
            };
            const response = await WorkDailyApi.listByMonth(condition);
            // 달력 출력
            for (var i = 0; i < lastDate.getDate(); i++) {
                curMonth.setDate(curTime + i);
                const compDay = formatWithLocale(curMonth, 'YYYYMMDD');
                const work = response.content.find(item => item.today === compDay) || null;
                const day = {
                    ymd: formatPureDate(curMonth),
                    day: i + 1,
                    work
                };
                this.arrDay.push(day);
            }
        },
        addRow() {
            if (this.selectedTab === 'equip') this.onEquip();
            else if (this.selectedTab === 'occupation') this.onOccupation();
        },
        removeRow() {
            if (this.selectedTab === 'equip') this.onClickDelEquip();
            else if (this.selectedTab === 'occupation') this.onClickDelOccupation();
        },
        allEquip() {
            this.isAllEquip = !this.isAllEquip;
            if (this.isAllEquip) {
                this.checkEquips = this.workDaily.equips.map(item => item.id.code.code);
            } else {
                this.checkEquips = [];
            }
        },
        allOccupation() {
            this.isAllOccupation = !this.isAllOccupation;
            if (this.isAllOccupation) {
                this.checkOccupations = this.workDaily.occupations.map(item => item.id.code.code);
            } else {
                this.checkOccupations = [];
            }
        },
        async doRemove() {
            if (confirm('삭제 하시겠습니까?')) {
                const res = await WorkDailyApi.remove(this.workDaily.id);
                if (res) {
                    alert('삭제 되었습니다.');
                    await this.bindDetail();
                }
                //await this.bindDetail();
                //this.$router.go(-1);
            }
        },
        goReport() {
            MenuHelper.daily(this.$router);
        },
        defaultSize() {
            ElectronHelper.windowDefaultDaily();
        }
    }
};
</script>
