export const profileUrl = url => {
    if (!url) return '/images/example/img-profile.png';

    return url;
};

export const thumbUrl = url => {
    if (!url) return '/images/default.png';

    return url + '_400X400';
};

export const sThumbUrl = url => {
    if (!url) return '/images/default.png';

    return url + '_130X130';
};
