import MutationTypes from '../../MutationTypes';
/* api */
import WorkRoomApi from '../../../api/WorkRoomApi';
import WorkRoomJoinApi from '../../../api/WorkRoomJoinApi';

import WorkApi from '../../../api/WorkApi';
import WorkerApi from '../../../api/WorkerApi';

export default {
    getWorkOfSearch: async ({ commit }, condition) => {
        const works = await WorkApi.list(condition);
        commit(MutationTypes.SET_WORK_OF_SEARCH, works.content);
    },
    getMyRooms: async ({ commit }) => {
        const rooms = await WorkRoomApi.myList();
        if (rooms) {
            for (var room of rooms) {
                room.works = [];
                room.workers = '';
                const res = await WorkerApi.listOfroom(room.id);
                res.forEach(item => {
                    if (room.master.id !== item.id) room.workers += item.name + ',';
                });
                if (room.workers) room.workers = room.workers.slice(0, -1);
            }
            commit(MutationTypes.SET_MY_ROOMS, rooms);
        }
    },
    incRoomCnt: async ({ commit, state }, roomId) => {
        var roomIdx = state.roomList.findIndex(room => room.id === roomId);
        commit(MutationTypes.ADD_MY_ROOM_CNT, roomIdx);
    },
    setSelectRoom: async ({ commit }, roomId) => {
        commit(MutationTypes.SET_SELCT_ROOM, roomId);
    },
    incAlarmCnt: async ({ commit, state }) => {
        commit(MutationTypes.ADD_MY_ALARM_CNT);
        console.log('alarmCnt::' + state.alarmCnt);
    },
    rstRoomCnt: async ({ commit, state }, roomId) => {
        var roomIdx = state.roomList.findIndex(room => room.id === roomId);
        commit(MutationTypes.RESET_MY_ROOM_CNT, roomIdx);
    },
    removeMyRooms: async ({ commit }, param) => {
        await WorkRoomJoinApi.remove(param.roomId, param.workerId);
        commit(MutationTypes.REMOVE_MY_ROOMS, param);
    },
    getWorkOfRoom: async ({ state, commit }, condition) => {
        if (state.workOfRoom.works && condition.page > 1) {
            //console.log(state.workOfRoom.works.length, state.workOfRoom.total);
            if (state.workOfRoom.works.length === state.workOfRoom.total && state.workOfRoom.total > 0) {
                --condition.page;
                return;
            }
        }
        const works = await WorkApi.list(condition);
        const workOfRoom = {
            roomId: condition.roomId,
            //works: works.content.reverse(),
            works: works.content,
            page: works.pageable.page + 1,
            total: works.pageable.total
        };
        commit(MutationTypes.SET_WORK_OF_ROOM, workOfRoom);
    },
    addWork: async ({ commit }, work) => {
        const response = await WorkApi.regist(work);
        if (response) {
            commit(MutationTypes.ADD_WORK_OF_ROOM, response.content);
            return response;
        }
    },
    modifyWork: async ({ commit }, work) => {
        const response = await WorkApi.modify(work.id, work);
        if (response) {
            commit(MutationTypes.ADD_WORK_OF_ROOM, response.content);
            return response;
        }
    },
    removeWork: async ({ commit }, work) => {
        await WorkApi.remove(work.id);
        commit(MutationTypes.REMOVE_WORK_OF_ROOM, work);
    },
    addWorkOfRoom: async ({ commit }, work) => {
        commit(MutationTypes.ADD_WORK_OF_ROOM, work);
    },
    setPicture: async ({ commit }, imgObj) => {
        commit(MutationTypes.SET_PICTURE, imgObj);
    },
    setTempWorkContent: async ({ commit }, content) => {
        commit(MutationTypes.SET_TEMP_WORK_CONTENT, content);
    },
    setTempWorkDocFile: async ({ commit }, content) => {
        commit(MutationTypes.SET_TEMP_WORK_DOCFILE, content);
    },
    setShowPopManageRoom: async ({ commit }, isShow) => {
        commit(MutationTypes.SET_SHOW_POP_MANAGE_ROOM, isShow);
    },
    setShowPopAddWorker: async ({ commit }, isShow) => {
        commit(MutationTypes.SET_SHOW_POP_ADD_WORKER, isShow);
    },
    setShowPopLeaveRoom: async ({ commit }, isShow) => {
        commit(MutationTypes.SET_SHOW_POP_LEAVE_ROOM, isShow);
    },
    setShowPopAddRoom: async ({ commit }, isShow) => {
        commit(MutationTypes.SET_SHOW_POP_ADD_ROOM, isShow);
    },
    setShowPopSendHp: async ({ commit }, isShow) => {
        commit(MutationTypes.SET_SHOW_POP_SEND_HP, isShow);
    },
    setShowPopShareRoom: async ({ commit }, isShow) => {
        commit(MutationTypes.SET_SHOW_POP_SHARE_ROOM, isShow);
    },
    setWorkId: async ({ commit }, id) => {
        const payload = { id, showType: 'detail' };
        commit(MutationTypes.SET_WORK_ID, payload);
    },
    setModifyWorkId: async ({ commit }, id) => {
        const payload = { id, showType: 'modify' };
        commit(MutationTypes.SET_WORK_ID, payload);
    },
    setCommentId: async ({ commit }, param) => {
        commit(MutationTypes.SET_COMMENT_ID, param);
    },
    resetId: async ({ commit }) => {
        commit(MutationTypes.RESET_ID);
    },
    setShowType: async ({ commit }, type) => {
        commit(MutationTypes.SET_SHOW_TYPE, type);
    },
    setModifyWorkActionId: async ({ commit }, param) => {
        const payload = { workId: param.workId, workActionId: param.workActionId };
        commit(MutationTypes.SET_ACTION_ID, payload);
    },
    setSocketClient: async ({ commit }, socketClient) => {
        commit(MutationTypes.SET_SOCKET_CLT, socketClient);
    }
};
