//import router from '../router';
// 이렇게 이요하니까 중복오류가 발생함.
export default {
    main: router => {
        router.push({ path: `/main` }).catch(() => {});
    },
    photo: router => {
        router.push({ path: `/photo` }).catch(() => {});
    },
    photoBox: router => {
        router.push({ path: `/photoBox` }).catch(() => {});
    },
    site: router => {
        router.push({ path: `/site` }).catch(() => {});
    },
    login: router => {
        router.push({ path: `/login` }).catch(() => {});
    },
    findpasswd: router => {
        router.push({ path: `/findpasswd` });
    },
    alarm: router => {
        router.push({ path: `/alarm` });
    },
    notice: router => {
        router.push({ path: `/notice` }).catch(() => {});
    },
    qna: router => {
        router.push({ path: `/qna` }).catch(() => {});
    },
    daily: router => {
        router.push({ path: `/daily/1` }).catch(() => {});
    },
    daily2: router => {
        router.push({ path: `/daily/2` }).catch(() => {});
    },
    policy: router => {
        router.push({ path: `/policy` });
    },
    privacy: router => {
        router.push({ path: `/privacy` });
    },
    mypage: router => {
        router.push({ path: `/mypage` });
    },
    mypageNoHistory: router => {
        router.replace({ path: `/mypage` });
    },
    room: (router, id) => {
        router.push({ path: `/room/${id}` });
    },
    setting: router => {
        router.push({ path: `/setting` });
    }
};
