import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';
import authHeader from './Header';

import qs from 'qs';

const list = query => {
    return httpClient
        .get(`/work`, {
            params: query,
            paramsSerializer: params => {
                return qs.stringify(params);
            },
            headers: authHeader()
        })
        .then(res => res.data);
};
const detail = id => httpClient.get(`/work/${id}`, { headers: authHeader() }).then(res => res.data);
const kakaoDetail = id => httpClient.get(`/kakao/work/${id}`).then(res => res.data);

const regist = param => httpClient.post(`/work`, param, { headers: authHeader() }).then(res => res.data);
const modify = (id, param) => httpClient.put(`/work/${id}`, param, { headers: authHeader() }).then(res => res.data);
const remove = id => httpClient.delete(`/work/${id}`, { headers: authHeader() }).then(res => res.data);

const finish = id => httpClient.put(`/work/finish/${id}`, {}, { headers: authHeader() }).then(res => res.data);

const myCount = () => httpClient.get(`/work/my/count`, { headers: authHeader() }).then(res => res.data.content);
const myList = size => httpClient.get(`/work/my/list/${size}`, { headers: authHeader() }).then(res => res.data.content);

const listOfMain = (id, size) =>
    httpClient.get(`/work/ofmain/${id}/${size}`, { headers: authHeader() }).then(res => res.data);

const untreatedCount = id => httpClient.get(`/work/room/${id}`, { headers: authHeader() }).then(res => res.data);
const todoCount = () => httpClient.get(`/work/todo/count`, { headers: authHeader() }).then(res => res.data.content);
const todoList = size =>
    httpClient.get(`/work/todo/list/${size}`, { headers: authHeader() }).then(res => res.data.content);

const comment = (id, param) =>
    httpClient.put(`/workcomment/${id}`, param, { headers: authHeader() }).then(res => res.data);

const photoList = query => {
    return httpClient
        .get(`/work/photo/all`, {
            params: query,
            headers: authHeader()
        })
        .then(res => res.data);
};

const updateReadWork = param =>
    httpClient.post(`/workReadWork`, param, { headers: authHeader() }).then(res => res.data);

const shareWork = param =>
    httpClient.post(`/work/forward`, { list: param }, { headers: authHeader() }).then(res => res.data);

export default {
    list: wrapHttpClient(list),
    detail: wrapHttpClient(detail),
    regist: wrapHttpClient(regist),
    modify: wrapHttpClient(modify),
    remove: wrapHttpClient(remove),
    finish: wrapHttpClient(finish),
    myList: wrapHttpClient(myList),
    myCount: wrapHttpClient(myCount),
    listOfMain: wrapHttpClient(listOfMain),
    untreatedCount: wrapHttpClient(untreatedCount),
    todoCount: wrapHttpClient(todoCount),
    todoList: wrapHttpClient(todoList),
    kakaoDetail: wrapHttpClient(kakaoDetail),
    comment: wrapHttpClient(comment),
    photoList: wrapHttpClient(photoList),
    updateReadWork: wrapHttpClient(updateReadWork),
    shareWork: wrapHttpClient(shareWork)
};
