import { formatTime, longFormatTime, shortTime, timeBefore } from '../../utils/date';

const VueDatetimeFilter = {
    install(Vue) {
        Vue.filter('formatTime', formatTime);
        Vue.filter('longFormatTime', longFormatTime);
        Vue.filter('shortTime', shortTime);
        Vue.filter('timeBefore', timeBefore);
    }
};

export default VueDatetimeFilter;
