import BaseModel from './BaseModel';
class WeatherModel extends BaseModel {
    constructor(json, options) {
        super(json, options);
    }

    get sky() {
        const pty = this.getPayload().pty;
        //const lgt = this.getPayload().lgt;

        //if (lgt === 4) return '낙뢰';
        if (pty !== '없음') return pty;
        /*
        else if (pty === 3) return '눈';
        else if (pty === 2) return '눈비';
        else if (pty === 1) return '눈/비';
        */

        return this.getPayload().sky || '맑음';
    }
    get reh() {
        return this.getPayload().reh;
    }
    get pop() {
        return this.getPayload().pop;
    }
    get t1h() {
        return this.getPayload().t1h;
    }

    get rn1() {
        return this.getPayload().rn1;
    }

    get pty() {
        return this.getPayload().pty;
    }

    get pmv() {
        return this.getPayload().pmv;
    }

    get pmvText() {
        const pmv = this.getPayload().pmv;
        if (pmv === '-') return '측정X';
        if (pmv <= 15) return '좋음';
        if (pmv <= 35) return '보통';
        if (pmv <= 75) return '나쁨';
        if (!pmv) return '보통';
        return '매우나쁨';
    }

    get tmx() {
        return this.getPayload().tmx;
    }

    get tmn() {
        return this.getPayload().tmn;
    }

    get display() {
        //화면출력시 어떻게 할까?
        return '';
    }
}

export default WeatherModel;
