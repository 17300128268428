<template>
    <div ref="filterManagerLayer" class="modal modal-width-fixed modal-owner-change">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-title">
                        <span>방장 변경</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="confirm" @click="doConfirm()"><span>확인</span></a>
                    <span class="gap" />
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->

            <!-- modal-body -->
            <div class="modal-body">
                <div class="form-box">
                    <div class="field-box">
                        <div class="label">
                            현재 방장
                        </div>
                        <div class="field">
                            <input type="text" class="txt" readonly :value="room.master.displayName" />
                        </div>
                    </div>
                    <div class="field-box">
                        <div class="label">
                            새방장 선택
                        </div>
                        <div class="field">
                            <input v-model="manager.name" type="text" class="txt" readonly />
                        </div>
                    </div>
                </div>

                <div class="list-box">
                    <div class="list-scroll-box">
                        <!-- user-list -->
                        <div class="user-list">
                            <ul>
                                <li v-for="(worker, index) in filterWorkers" :key="index">
                                    <input
                                        :id="'modal-chk-owner-' + index"
                                        v-model="manager"
                                        type="radio"
                                        class="sel-user"
                                        :value="worker"
                                    />
                                    <label class="user-summary" :for="'modal-chk-owner-' + index">
                                        <div class="user-thumb">
                                            <span class="thumb">
                                                <img :src="worker.imgUrl | profileUrl" />
                                            </span>
                                        </div>
                                        <div class="user-info">
                                            <p class="user-nm">{{ worker.name }}</p>
                                            <div class="user-member">
                                                <div>
                                                    <strong>{{ worker.companyName }}</strong>
                                                    <span>{{ worker.regDt | formatTime }} 가입</span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <!-- // user-list -->
                    </div>
                </div>
            </div>
            <!-- // modal-body -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import WorkerApi from '../../api/WorkerApi';
import WorkRoomApi from '../../api/WorkRoomApi';

import ArrayHelper from '../../helper/ArrayHelper';

export default {
    name: 'FilterManager',
    components: {},
    data: () => {
        return {
            room: { master: { displayName: '' } },
            roomId: 0,
            workers: [],
            filterWorkers: [],
            workerId: 0,
            manager: { id: null, displayName: '' },
            searchText: '',
            isAll: false,
            outId: null
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        //this.roomId = Number(this.$route.params.roomId);
        //this.bind();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['setShowPopManageRoom', 'setShowPopLeaveRoom']),
        close() {
            this.resetData();
            this.$refs.filterManagerLayer.classList.remove('on');
        },
        async openAndLeave(room) {
            console.log(room);
            await this.open(room);
            this.outId = this.basic.id;
        },
        async open(room) {
            this.resetData();
            this.room = room;
            this.roomId = room.id;
            await this.bind();
            this.$refs.filterManagerLayer.classList.add('on');
        },
        setFilterWorker() {
            this.filterWorkers = this.workers.filter(
                worker => worker.name.indexOf(this.searchText) > -1 || worker.companyName.indexOf(this.searchText) > -1
            );
        },
        delSearchText() {
            this.searchText = '';
            this.setFilterWorker();
        },
        async bind() {
            let response = [];

            if (this.roomId) response = await WorkerApi.listOfroom(this.roomId);
            const condition = item => item.id === this.basic.id;
            ArrayHelper.oneRemove(response, condition);

            const condition2 = item => item.id === this.room.master.id;
            ArrayHelper.oneRemove(response, condition2);

            this.workers = response.map(item => {
                const worker = {};
                worker.id = item.id;
                worker.name = item.displayName;
                worker.companyName = item.company.name;
                worker.imgUrl = item.imgUrl;
                worker.regDt = item.regDt;
                return worker;
            });
            this.filterWorkers = this.workers;
        },
        async doConfirm() {
            if (this.room.master.id === this.basic.id || this.basic.csAdminYn === 'Y') {
                if (this.manager.id && confirm('방장을 이전하시겠습니까?')) {
                    this.room.master.id = this.manager.id;
                    await WorkRoomApi.modify(this.room.id, this.room);
                    this.setShowPopManageRoom(false);
                    if (this.outId) {
                        this.setShowPopLeaveRoom(true);
                    }
                    this.close();
                    this.$emit('refresh');
                }
            }
        },
        resetData() {
            this.outId = null;
            this.room = { master: { displayName: '' } };
            this.manager = { id: null, displayName: '' };
            this.filterWorkers = this.workers = [];
        }
    }
};
</script>
