import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';
import authHeader from './Header';

const myList = query =>
    httpClient.get(`/pictureRoom/my/list`, { params: query, headers: authHeader() }).then(res => res.data);
const removeIn = ids => httpClient.delete(`/pictureRoom`, { data: ids, headers: authHeader() }).then(res => res.data);

export default {
    myList: wrapHttpClient(myList),
    removeIn: wrapHttpClient(removeIn)
};
