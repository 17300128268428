export const numComma = number => {
    if (number === 0) {
        return '0';
    }
    if (number) {
        number += '';
        number = number.replace(/,/gi, '');
        const parts = number.toString().split('.');

        if (!parts[1]) {
            return number.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }

        return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (parts[1] ? '.' + parts[1] : '');
    }
};

export const isNumber = evt => {
    evt = evt || window.event;
    var keyID = evt.which ? evt.which : evt.keyCode;
    if (
        (keyID >= 48 && keyID <= 57) ||
        (keyID >= 96 && keyID <= 105) ||
        keyID === 8 ||
        keyID === 46 ||
        keyID === 37 ||
        keyID === 39
    )
        return;
    else return false;
};

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
