<template>
    <div id="notice-list" class="content-side-wrapper">
        <!-- content-header -->
        <header class="content-header">
            <div class="header-box">
                <div class="header-title">
                    <span>{{ boardName[boardType] }}</span>
                </div>
            </div>
            <div class="header-util">
                <a class="add report-write" @click="showRegist"> <i /><span class="blind">공지작성</span> </a>
                <a class="winmin modal-link" @click="defaultSize"> <i /><span class="blind">창축소</span> </a>
            </div>
        </header>
        <!-- // content-header -->

        <!-- search -->
        <div class="search-box">
            <div class="search">
                <input
                    v-model="searchText"
                    type="text"
                    class="txt"
                    placeholder="제목, 작성자 검색"
                    @keyup.enter="resetSearch"
                />
            </div>
        </div>
        <!-- // search -->

        <!-- notice-list-box -->
        <div class="notice-list-box">
            <p class="title">
                <span>전체</span>
                <span class="cnt">{{ boards.total }}</span>
            </p>
            <!-- notice-list -->
            <div ref="listBox" class="notice-list" @scroll="morePage">
                <ul>
                    <li v-for="(board, index) in boards.content" :key="index">
                        <a class="notice-summary" :class="{ on: boardId === board.id }" @click="showDetail(board.id)">
                            <div class="user-thumb">
                                <span class="thumb">
                                    <img
                                        :src="board.register.imgUrl | profileUrl"
                                        onerror="javascript:this.src='/images/example/img-profile.png'"
                                    />
                                </span>
                            </div>
                            <div class="notice-info">
                                <p v-if="board.type === 'notice'" class="notice-subject">{{ board.title }}</p>
                                <p v-else class="notice-subject">{{ board.title }} [{{ textStatus(board) }}]</p>
                                <div class="notice-meta">
                                    <div>
                                        <span>
                                            {{ board.register.displayName }}&middot;
                                            {{ board.register.company.name }}
                                        </span>
                                        <span>{{ board.regDt | longFormatTime }}</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- // notice-list -->
        </div>
        <!-- // group-list-box -->
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BoardApi from '../../api/BoardApi';

import PageModel from '../../model/PageModel';

import ElectronHelper from '../../helper/ElectronHelper';

export default {
    name: 'BoardList',
    components: {},
    data: () => {
        return {
            boards: new PageModel(),
            page: 1,
            boardName: { notice: '공지', qna: '기술문의' },
            boardType: '',
            boardId: null,
            searchText: ''
        };
    },
    computed: {
        ...mapState({})
    },
    created() {
        this.boardType = this.$route.name.toLowerCase();
        //EventHelper.offNavMenu();
    },
    mounted() {
        this.boards.page = this.page;
        this.boardType = this.$route.meta.type;
        this.doSearch();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['hideLoading']),
        async hide(board) {
            await BoardApi.hide(board.id);
            this.boards.removeItem(board.id);
        },
        showRegist() {
            this.$emit('show', 'regist', null);
        },
        showDetail(id) {
            this.boardId = id;
            this.$emit('show', 'detail', id);
        },
        async resetSearch() {
            this.boards.page = this.page = 1;
            const query = {
                ...this.boards.query(),
                type: this.boardType,
                searchText: this.searchText
            };
            const response = await BoardApi.list(query);
            if (response) {
                //this.boards = new PageModel(response);
                this.boards.bind(response, false);
            }
            this.hideLoading();
        },
        async doSearch() {
            const query = {
                ...this.boards.query(),
                type: this.boardType,
                searchText: this.searchText
            };
            const response = await BoardApi.list(query);
            if (response) {
                //this.boards = new PageModel(response);
                this.boards.bind(response, true);
            }
            this.hideLoading();
        },
        more() {
            //++this.boards.page;
            this.boards.page = this.page++;
            this.doSearch();
        },
        morePage() {
            const box = this.$refs.listBox;
            if (box.scrollTop + box.clientHeight >= box.scrollHeight) {
                //this.condition.page = this.condition.page + 1;
                if (this.boards.isMore) {
                    this.boards.page++;
                    this.doSearch();
                }
            }
        },
        defaultSize() {
            ElectronHelper.windowDefaultSize();
        },
        textStatus(board) {
            if (board.answer) return '답변완료';
            else return '답변전';
        }
    }
};
</script>
