<template>
    <div class="content-side-wrapper on">
        <div class="null-wrapper">
            <div class="null-box">
                <img src="/images/img-notice-none.png" alt="" />
                <p>{{ boardName[boardType] }}를 선택하시고 확인하세요!</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

/* POPUP Layer관련 처리 */
export default {
    name: 'NoticeNone',
    components: {},
    data: () => {
        return {
            boardName: { notice: '공지', qna: '기술문의' },
            boardType: ''
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {
        this.boardType = this.$route.name.toLowerCase();
    },
    mounted() {
        //this.myInfo();
        this.boardType = this.$route.meta.type;
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([])
    }
};
</script>
