<template>
    <div id="content-wrap" class="content-side-1">
        <div class="content-side-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-title">
                        <span>작업일보 현황</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="report-write" @click="goWrite"><i /><span class="blind">작업일보 등록</span></a>
                    <a class="winmin modal-link" @click="defaultSize"> <i /><span class="blind">창축소</span> </a>
                </div>
            </header>
            <!-- // content-header -->

            <!-- search-item-list -->
            <div class="search-item-list full">
                <div class="change-yy-mm">
                    <a class="prev-yy-mm" @click="beforeMonth"><i /><span class="blind">이전달</span></a>
                    <span>{{ yearmonth }}</span>
                    <a class="next-yy-mm" @click="nextMonth"><i /><span class="blind">다음달</span></a>
                </div>
            </div>
            <!-- // search-item-list -->

            <div class="report-wrapper">
                <div class="mm-dd-wrapper">
                    <div class="mm-dd-box">
                        <ul>
                            <li v-for="(day, index) in arrDay" :key="index">
                                <a :class="{ on: day.ymd === selDay }" @click="onChangeDate(day.ymd)">
                                    <div class="dd">{{ day.day }}</div>
                                    <div class="report-is" :class="day.work ? 'on' : 'off'" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="report-detail-box">
                    <div class="report-detail-header">
                        <p class="report-title">
                            {{ formatStringToDay(selDay) }}
                            ({{ getWeekOfDay(formatStringToDay(selDay)) }}) 작업일보
                        </p>
                        <div class="weather-print-box">
                            <div class="weather-info">
                                날씨: {{ weather.sky }}, 온도: {{ weather.minVal }}℃ / {{ weather.maxVal }}℃
                            </div>
                            <a class="btn-print-report" @click="onClickPrint()"><i /><span>보고서 출력</span></a>
                        </div>
                    </div>

                    <div class="report-tab-wrapper">
                        <div class="tab-nav">
                            <ul>
                                <li>
                                    <a :class="{ on: showTab('content') }" @click="onClickTab('content')">
                                        <span>작업내용</span>
                                    </a>
                                </li>
                                <li>
                                    <a :class="{ on: showTab('occupation') }" @click="onClickTab('occupation')">
                                        <span>출역현황</span>
                                    </a>
                                </li>
                                <li>
                                    <a :class="{ on: showTab('equip') }" @click="onClickTab('equip')">
                                        <span>장비현황</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="tab-content-wraper">
                            <div class="tab-content" :class="showTab('content') ? '' : 'hide'">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>공종</th>
                                            <th>업체</th>
                                            <th>전일 작업내용</th>
                                            <th>금일 작업내용</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(work, index) in workDailys" :key="index">
                                            <td class="center">
                                                {{ getProcessCode(work.processCode) }}
                                            </td>
                                            <td class="center">
                                                {{ work.company.name }}
                                            </td>
                                            <td class="left" style="width:35%">
                                                <pre>{{ work.yesterDay }}</pre>
                                            </td>
                                            <td class="left" style="width:35%">
                                                <pre>{{ work.todayContent }}</pre>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="tab-content" :class="showTab('occupation') ? '' : 'hide'">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>공종</th>
                                            <th>전체</th>
                                            <th>직종</th>
                                            <th>전일누계</th>
                                            <th>전일투입수</th>
                                            <th>금일투입수</th>
                                            <th>누계</th>
                                        </tr>
                                    </thead>
                                    <tbody id="tbOccupation">
                                        <tr v-for="(occupation, index) in occupations" :key="index">
                                            <td class="center">
                                                {{ getProcessCode(occupation.wdMaster.processCode) }}
                                            </td>
                                            <td class="center">
                                                {{ occupation.wdMaster.company.name }}
                                            </td>
                                            <td class="center">
                                                {{ occupation.id.code.name }}
                                            </td>
                                            <td>{{ occupation.preCountSum | numComma }}</td>
                                            <td>{{ occupation.preCount | numComma }}</td>
                                            <td>{{ occupation.putCount | numComma }}</td>
                                            <td>
                                                {{
                                                    (Number(occupation.preCountSum) + Number(occupation.putCount))
                                                        | numComma
                                                }}
                                            </td>
                                        </tr>
                                        <tr v-if="occupations.length > 0" class="sub-total">
                                            <td colspan="3" class="center">
                                                대우건설 소계
                                            </td>
                                            <td>{{ calcOccupationTotal('sum') }}</td>
                                            <td>{{ calcOccupationTotal('pre') }}</td>
                                            <td>{{ calcOccupationTotal('put') }}</td>
                                            <td>{{ calcOccupationTotal('all') }}</td>
                                        </tr>

                                        <tr v-for="(occupation, index) in occupationsNoDw" :key="index">
                                            <td class="center">
                                                {{ getProcessCode(occupation.wdMaster.processCode) }}
                                            </td>
                                            <td class="center">
                                                {{ occupation.wdMaster.company.name }}
                                            </td>
                                            <td class="center">
                                                {{ occupation.id.code.name }}
                                            </td>
                                            <td>{{ occupation.preCountSum | numComma }}</td>
                                            <td>{{ occupation.preCount | numComma }}</td>
                                            <td>{{ occupation.putCount | numComma }}</td>
                                            <td>
                                                {{
                                                    (Number(occupation.preCountSum) + Number(occupation.putCount))
                                                        | numComma
                                                }}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr v-if="occupationsNoDw.length > 0" class="sub-total">
                                            <td colspan="3" class="center">
                                                협력업체 소계
                                            </td>
                                            <td>{{ calcOccupationNoDwTotal('sum') }}</td>
                                            <td>{{ calcOccupationNoDwTotal('pre') }}</td>
                                            <td>{{ calcOccupationNoDwTotal('put') }}</td>
                                            <td>{{ calcOccupationNoDwTotal('all') }}</td>
                                        </tr>

                                        <tr class="total">
                                            <td colspan="3" class="center">
                                                총합계
                                            </td>
                                            <td>{{ calcOccupationNoDwTotal('sum') }}</td>
                                            <td>{{ calcOccupationNoDwTotal('pre') }}</td>
                                            <td>{{ calcOccupationNoDwTotal('put') }}</td>
                                            <td>{{ calcOccupationNoDwTotal('all') }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="tab-content" :class="showTab('equip') ? '' : 'hide'">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>공종</th>
                                            <th>업체</th>
                                            <th>장비명</th>
                                            <th>규격</th>
                                            <th>전일누계</th>
                                            <th>전일투입수</th>
                                            <th>금일투입수</th>
                                            <th>누계</th>
                                        </tr>
                                    </thead>
                                    <tbody id="tbEquip">
                                        <tr v-for="(equip, index) in equips" :key="index">
                                            <td class="center">
                                                {{ getProcessCode(equip.wdMaster.processCode) }}
                                            </td>
                                            <td class="center">
                                                {{ equip.wdMaster.company.name }}
                                            </td>
                                            <td class="center">
                                                {{ equip.id.code.name }}
                                            </td>
                                            <td class="center">
                                                {{ equip.id.code.spec }}
                                            </td>
                                            <td>{{ equip.preCountSum | numComma }}</td>
                                            <td>{{ equip.preCount | numComma }}</td>
                                            <td>{{ equip.putCount | numComma }}</td>
                                            <td>
                                                {{ (Number(equip.preCountSum) + Number(equip.putCount)) | numComma }}
                                            </td>
                                        </tr>

                                        <tr class="total">
                                            <td colspan="4" class="center">
                                                합계
                                            </td>
                                            <td>{{ calcEquipTotal('sum') }}</td>
                                            <td>{{ calcEquipTotal('pre') }}</td>
                                            <td>{{ calcEquipTotal('put') }}</td>
                                            <td>{{ calcEquipTotal('all') }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import WorkDailyApi from '../../api/WorkDailyApi';
import WeatherApi from '../../api/WeatherApi';
import CodeApi from '../../api/CodeApi';
import { isWorkDailyAuth } from '../../helper/AuthHelper';
import {
    toDayFormat,
    //formatDate,
    formatStringToDay,
    formatPureDate,
    formatWithLocale,
    getWeekOfDay
} from '../../utils/date';

import MenuHelper from '../../helper/MenuHelper';
import ElectronHelper from '../../helper/ElectronHelper';

export default {
    name: 'WorkDailyRegist',
    components: {},
    data: () => {
        return {
            //workDaily: { id: null, equips: [], occupations: [], today: toDayFormat(), status: null },
            selDay: formatPureDate(toDayFormat()),
            workDailys: [],
            equips: [],
            occupations: [],
            occupationsNoDw: [],
            selectedTab: 'content',
            selectedContent: { pre: false, today: true, next: false },
            today: new Date(),
            arrDay: [],
            yearmonth: '',
            weather: {},
            processCodes: [],
            DAEWOO_COMPANY_ID: 2
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        //this.wid = this.$route.params.id;
        ElectronHelper.windowWorkDailyExpand();
        CodeApi.list('0050').then(res => {
            this.processCodes = res.content;
            this.bindDetail();
            this.monthBuild();
        });
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        back() {
            //this.$router.go(-1);
        },
        async uiInit() {},
        getWeekOfDay,
        isWorkDailyAuth,
        formatStringToDay,
        async bindDetail() {
            if (this.selDay) {
                const res = await WorkDailyApi.detailByToday(this.selDay);
                const weather = await WeatherApi.minMax(this.basic.csId, formatStringToDay(this.selDay));
                this.weather = weather.content;
                if (res.content) {
                    this.workDailys = res.content;
                    this.workDailys.sort((a, b) => a.processCode - b.processCode);
                    if (res.content.length > 0) {
                        const res1 = await WorkDailyApi.listByEquip(this.selDay);
                        this.equips = res1.content;
                        const res2 = await WorkDailyApi.listByOccupation(this.selDay);
                        //this.occupations = res2.content;
                        this.equips.sort((a, b) => a.id.code.code - b.id.code.code);
                        this.equips.sort((a, b) => a.wdMaster.company.sortNo - b.wdMaster.company.sortNo);
                        res2.content.sort((a, b) => a.wdMaster.company.sortNo - b.wdMaster.company.sortNo);
                        res2.content.sort((a, b) => a.wdMaster.processCode - b.wdMaster.processCode);

                        this.occupations = res2.content.filter(item => item.companyId === this.DAEWOO_COMPANY_ID);
                        this.occupationsNoDw = res2.content.filter(item => item.companyId !== this.DAEWOO_COMPANY_ID);

                        setTimeout(() => {
                            this.rowspan('tbOccupation', 0);
                            this.rowspan('tbOccupation', 1);
                            this.rowspan('tbEquip', 0);
                            this.rowspan('tbEquip', 1);
                        }, 300);
                    }
                }
            }
        },
        onClickTab(value) {
            setTimeout(() => {
                this.rowspan('tbOccupation', 0);
                this.rowspan('tbOccupation', 1);
                this.rowspan('tbEquip', 0);
                this.rowspan('tbEquip', 1);
            }, 100);
            this.selectedTab = value;
        },
        onClickPrint() {
            window.open('/reportPrint?csId=' + this.basic.csId + '&today=' + this.selDay, '_inner');
        },
        showTab(value) {
            return this.selectedTab === value;
        },
        onClickContent(value) {
            this.selectedContent[value] = !this.selectedContent[value];
        },
        showContent(value) {
            return this.selectedContent[value];
        },
        onChangeDate(ymd) {
            this.selDay = ymd;
            this.bindDetail();
        },
        calcOccupationTotal(type) {
            if (type === 'pre') {
                return this.occupations.reduce((sum, item) => sum + Number(item.preCount), 0);
            } else if (type === 'put') {
                return this.occupations.reduce((sum, item) => sum + Number(item.putCount), 0);
            } else if (type === 'sum') {
                return this.occupations.reduce((sum, item) => sum + Number(item.preCountSum), 0);
            } else if (type === 'all') {
                return this.occupations.reduce(
                    (sum, item) => sum + Number(item.preCountSum) + Number(item.putCount),
                    0
                );
            }
        },
        calcOccupationNoDwTotal(type) {
            if (type === 'pre') {
                return this.occupationsNoDw.reduce((sum, item) => sum + Number(item.preCount), 0);
            } else if (type === 'put') {
                return this.occupationsNoDw.reduce((sum, item) => sum + Number(item.putCount), 0);
            } else if (type === 'sum') {
                return this.occupationsNoDw.reduce((sum, item) => sum + Number(item.preCountSum), 0);
            } else if (type === 'all') {
                return this.occupationsNoDw.reduce(
                    (sum, item) => sum + Number(item.preCountSum) + Number(item.putCount),
                    0
                );
            }
        },
        calcEquipTotal(type) {
            if (type === 'pre') {
                return this.equips.reduce((sum, item) => sum + Number(item.preCount), 0);
            } else if (type === 'put') {
                return this.equips.reduce((sum, item) => sum + Number(item.putCount), 0);
            } else if (type === 'sum') {
                return this.equips.reduce((sum, item) => sum + Number(item.preCountSum), 0);
            } else if (type === 'all') {
                return this.equips.reduce((sum, item) => sum + Number(item.preCountSum) + Number(item.putCount), 0);
            }
        },

        beforeMonth() {
            this.today = new Date(this.today.getFullYear(), this.today.getMonth() - 1, this.today.getDate());
            this.monthBuild(); //만들기
        },
        nextMonth() {
            this.today = new Date(this.today.getFullYear(), this.today.getMonth() + 1, this.today.getDate());
            this.monthBuild(); //만들기
        },
        async monthBuild() {
            this.arrDay = [];
            const curMonth = new Date(this.today.getFullYear(), this.today.getMonth(), 1); //현재달의 첫째 날
            //const arrDay = [];
            const lastDate = new Date(this.today.getFullYear(), this.today.getMonth() + 1, 0); //현재 달의 마지막 날
            const curTime = curMonth.getDate();
            this.yearmonth = this.today.getFullYear() + '.' + (this.today.getMonth() + 1);

            const today = formatWithLocale(this.today, 'YYYYMM');
            const response = await WorkDailyApi.listSubList(today);
            // 달력 출력
            for (var i = 0; i < lastDate.getDate(); i++) {
                curMonth.setDate(curTime + i);
                const compDay = formatWithLocale(curMonth, 'YYYYMMDD');
                const work = response.content.find(item => item === compDay) || null;
                const day = {
                    ymd: formatPureDate(curMonth),
                    day: i + 1,
                    work
                };
                this.arrDay.push(day);
            }
        },
        getProcessCode(code) {
            const find = this.processCodes.find(item => item.code === code) || {};
            return find.name;
        },
        goWrite() {
            MenuHelper.daily2(this.$router);
        },
        defaultSize() {
            ElectronHelper.windowDefaultDaily();
        },
        rowspan(targetId, colIdx, isStats) {
            //this.$nextTick(() => {
            return $('#' + targetId).each(function() {
                var that;
                $('tr', this).each(function() {
                    $('td', this)
                        .eq(colIdx)
                        .filter(':visible')
                        .each(function(col) {
                            console.log('col', col);
                            if (
                                $(this).html() === $(that).html() &&
                                (!isStats ||
                                    (isStats &&
                                        $(this)
                                            .prev()
                                            .html() ===
                                            $(that)
                                                .prev()
                                                .html()))
                            ) {
                                var rowspan = $(that).attr('rowspan') || 1;
                                rowspan = Number(rowspan) + 1;

                                $(that).attr('rowspan', rowspan);

                                // do your action for the colspan cell here
                                $(this).hide();

                                //$(this).remove();
                                // do your action for the old cell here
                            } else {
                                that = this;
                            }

                            // set the that if not already set
                            that = that === null ? this : that;
                        });
                });
            });
            //});
        }
    }
};
</script>
