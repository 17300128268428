import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';
import authHeader from './Header';

const list = query => httpClient.get(`/company`, { params: query, headers: authHeader() }).then(res => res.data);
const codeList = () =>
    httpClient.get(`/company/code`, { headers: authHeader() }).then(res => res.data, { headers: authHeader() });
const detail = id => httpClient.get(`/company/${id}`, { headers: authHeader() }).then(res => res.data);

const regist = param => httpClient.post(`/company`, param, { headers: authHeader() }).then(res => res.data);

export default {
    list: wrapHttpClient(list),
    codeList: wrapHttpClient(codeList),
    detail: wrapHttpClient(detail),
    regist: wrapHttpClient(regist)
};
