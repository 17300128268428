import Vue from 'vue';
import Router from 'vue-router';
import NotFound from '../components/errors/404';

import Main from '../components/group/Group';
import Login from '../components/Login';
import SelectSite from '../components/SelectSite';
import FindPasswd from '../components/FindPasswd';
import Setting from '../components/setting/Setting';
import Notice from '../components/notice/Notice';
import Photo from '../components/photo/Photo';
import PhotoBox from '../components/photo/PhotoBox';
import Site from '../components/site/Site';
import WorkDailyWrite from '../components/daily/WorkDailyWrite';
import WorkDailyReport from '../components/daily/WorkDailyReport';
import ReportPrint from '../components/report/ReportPrint';

import Alarm from '../components/alarm/Alarm';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Main',
            alias: '/main',
            component: Main
        },
        {
            path: '/work/:id/:roomId',
            name: 'Main',
            alias: '/main',
            component: Main
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/selectSite',
            name: 'SelectSite',
            component: SelectSite,
            meta: {
                bodyClass: 'main fixed'
            }
        },
        {
            path: '/setting',
            name: 'Setting',
            component: Setting
        },
        {
            path: '/notice',
            name: 'Notice',
            component: Notice,
            meta: {
                type: 'notice'
            }
        },
        {
            path: '/notice/:id',
            name: 'Notice',
            component: Notice,
            meta: {
                type: 'notice'
            }
        },
        {
            path: '/qna',
            name: 'Qna',
            component: Notice,
            meta: {
                type: 'qna'
            }
        },
        {
            path: '/qna/:id',
            name: 'Qna',
            component: Notice,
            meta: {
                type: 'qna'
            }
        },
        {
            path: '/photo',
            name: 'Photo',
            component: Photo
        },
        {
            path: '/photoBox',
            name: 'PhotoBox',
            component: PhotoBox
        },
        {
            path: '/site',
            name: 'Site',
            component: Site
        },
        {
            path: '/daily/2',
            name: 'WorkDailyWrite',
            component: WorkDailyWrite
        },
        {
            path: '/daily/1',
            name: 'WorkDailyReport',
            component: WorkDailyReport
        },
        {
            path: '/findpasswd',
            name: 'FindPasswd',
            component: FindPasswd
        },
        {
            path: '/reportPrint',
            name: 'ReportPrint',
            component: ReportPrint
        },
        {
            path: '/alarm',
            name: 'Alarm',
            component: Alarm,
            meta: {
                type: 'Alarm'
            }
        },
        { path: '*', component: NotFound }
    ]
});

export default router;
