const DOC_ALLOW_FILE = 'image/*,.pdf,.doc,.xls,.ppt,.hwp,.docx,.xlsx,.pptx,.zip,.txt,.me,.mp4,.mov';

const MAX_SIZE = 20971520;
const MAX_MOVE_SIZE = 20971520 * 3;
const MAX_COUNT = 5;
const ALLOW_FILE_DOC = '.pdf,.doc,.xls,.ppt,.hwp,.docx,.xlsx,.pptx,.zip,.txt,.me,.mp4,.mov';
const ALLOW_FILE_IMG = '.jpg,.jpeg,.png,.bmp,.ai,.gif,tif';

export default {
    allowFileExt: () => {
        return DOC_ALLOW_FILE;
    },
    allowImgExt: () => {
        return ALLOW_FILE_IMG;
    },
    isCount: count => {
        if (MAX_COUNT <= count) {
            alert('최대 파일 허용개수는 ' + MAX_COUNT + ' 입니다.');
            return false;
        }
        return true;
    },
    isSize: size => {
        if (MAX_SIZE < size) {
            alert('20MB이내로 첨부가 가능합니다.');
            return false;
        }
        return true;
    },
    isNewSize: (size, fileName) => {
        const ext = fileName.split('.').pop() || '';
        const fileExt = 'mp4,mov';
        if (fileExt.indexOf(ext.toLowerCase()) >= 0) {
            if (MAX_MOVE_SIZE < size) {
                alert('동영상은 60MB이내로 첨부가 가능합니다.');
                return false;
            }
        } else {
            if (MAX_SIZE < size) {
                alert('20MB이내로 첨부가 가능합니다.');
                return false;
            }
        }
        return true;
    },
    isAllowFile: fileName => {
        const fileExt = ALLOW_FILE_DOC + ',' + ALLOW_FILE_IMG;
        const ext = fileName.split('.').pop() || '';
        if (fileExt.indexOf(ext.toLowerCase()) < 0) {
            alert('첨부가능한 파일이 아닙니다.');
            return false;
        }
        return true;
    },
    isAllowImgFile: fileName => {
        const fileExt = ALLOW_FILE_IMG;
        const ext = fileName.split('.').pop() || '';
        if (fileExt.indexOf(ext.toLowerCase()) < 0) {
            return false;
        }
        return true;
    }
};
