export default {
    headerSearch: () => {
        $('.header-util .search')
            .off('click')
            .on('click', function(e) {
                e.preventDefault();
                $('.content-header-search').addClass('on');
                $('.content-header-search .btn-box a')
                    .off('click')
                    .on('click', function(e) {
                        e.preventDefault();
                        $('.content-header-search').removeClass('on');
                    });
            });
    }
};
