export const getCookie = name => {
    const value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return value ? value[2] : null;
};
export const setCookie = (name, value) => {
    if (getCookie(name)) {
        eraseCookie(name);
    }
    var now = new Date();
    var time = now.getTime();
    time += 3600 * 1000 * 24 * 365;
    now.setTime(time);
    document.cookie = name + '=' + value + '; expires=' + now.toUTCString() + '; path=/';
};
export const eraseCookie = name => {
    document.cookie = name + '=; Max-Age=0';
};
