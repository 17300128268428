<template>
    <div class="modal modal-width-fixed modal-change-location on">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-nav">
                        <a class="back"><i /><span class="blind">뒤로가기</span></a>
                    </div>
                    <div class="header-title">
                        <span>현장 변경</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->

            <!-- modal-body -->
            <div class="modal-body">
                <p>현장을 선택하시고 COCO협업에 참여하세요.</p>
                <div class="form-box">
                    <div class="search-box">
                        <div class="search">
                            <input
                                v-model="searchText"
                                type="text"
                                class="txt"
                                placeholder="현장명 검색"
                                @keyup="setFilterSite"
                            />
                        </div>
                    </div>
                </div>
                <div class="sel-loc-body">
                    <ul>
                        <li v-for="(site, index) in filterSites" :key="index" @dblclick="modifySite">
                            <div class="check-box">
                                <input :id="'sel-loc-' + index" v-model="selectId" type="radio" :value="site.id" />
                                <label :for="'sel-loc-' + index">{{ site.name }}</label>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="btn-wrapper">
                    <button type="button" @click="modifySite">
                        <span>현장 선택</span>
                    </button>
                </div>
            </div>
            <!-- // modal-body -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AffilationSiteApi from '../../api/AffilationSiteApi';
import LoginApi from '../../api/LoginApi';
import MutationTypes from '../../store/MutationTypes';
import { JWT_TOKEN, JWT_REFRESH_TOKEN, WORKER_ID, CS_ID } from '../../const';

export default {
    name: 'PopSite',
    components: {},
    data: () => {
        return {
            sites: [],
            selectId: null,
            searchText: '',
            filterSites: []
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        AffilationSiteApi.siteOfworker(this.basic.id).then(res => {
            this.filterSites = this.sites = res.map(item => item.site);
        });
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        close() {
            this.$emit('update:show', false);
        },
        async modifySite() {
            //이 부분의 오류,성공처리에 대해서 공통작업이 필요함
            const session = {
                name: this.basic.name,
                postionName: this.basic.position,
                companyName: this.basic.company,
                id: this.basic.id,
                csId: this.selectId
            };

            const res = await LoginApi.selectSite(session);
            //오류나는건 추후 공통처리한다.
            if (res.content) {
                const basic = {
                    csId: session.csId,
                    name: session.name,
                    position: session.postionName,
                    company: session.companyName,
                    companyId: res.content.companyId,
                    id: session.id,
                    hp: res.content.hp,
                    site: res.content.siteName,
                    imgUrl: res.content.imgUrl,
                    csAdminYn: res.content.csAdminYn,
                    csCompanyAdminYn: res.content.csCompanyAdminYn
                };

                this.$store.commit(MutationTypes.SET_MAIN_BASIC, basic);

                const oauth = res.content.token;
                this.$store.commit(MutationTypes.SET_OAUTH, oauth);
                window.localStorage.setItem(CS_ID, session.csId);
                window.localStorage.setItem(WORKER_ID, session.id);
                window.localStorage.setItem(JWT_TOKEN, oauth.token);
                window.localStorage.setItem(JWT_REFRESH_TOKEN, oauth.refreshToken);
                //await this.doLastAccess();
                this.$router.push({ name: 'Main' });
            }
        },
        setFilterSite() {
            this.filterSites = this.sites.filter(site => site.name.indexOf(this.searchText) > -1);
        }
    }
};
</script>
