<template>
    <div class="comment-wrapper">
        <!-- content-header -->
        <header class="content-header">
            <div class="header-box">
                <div class="header-nav">
                    <a class="back" @click="back"><i /><span class="blind">뒤로가기</span></a>
                </div>
                <div class="header-title">
                    <span>댓글</span>
                </div>
            </div>
        </header>
        <!-- // content-header -->

        <!-- commnent-list -->
        <div class="comment-list">
            <ul>
                <li v-if="comments.content.length < 1" class="first-message">
                    <div class="comment-box">
                        <div class="user-thumb">
                            <span class="thumb">
                                <img src="/images/example/img-profile.png" alt="" />
                            </span>
                        </div>
                        <div class="comment-memo">
                            <div class="comment">
                                <p>첫번째 댓글을 등록해 보세요.</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li v-for="(comment, index) in comments.content" :key="index">
                    <div class="comment-box">
                        <div class="user-thumb">
                            <span class="thumb">
                                <img
                                    :src="comment.register.imgUrl | profileUrl"
                                    onerror="javascript:this.src='/images/example/img-profile.png'"
                                />
                            </span>
                        </div>
                        <div class="comment-memo">
                            <div class="comment">
                                <p>
                                    {{ getDisplayName(comment.register) }} &middot;
                                    {{ comment.register.company.name }}
                                </p>
                                <p :inner-html.prop="convertCrToBr(comment.content) | withLinks" />
                            </div>
                            <div class="comment-meta">
                                <span>{{ comment.regDt | longFormatTime }}</span>
                                <a v-if="basic.id === comment.register.id" @click="removeComment(comment.id)">
                                    삭제
                                </a>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <div class="talk-input-wrapper">
                <div class="talk-input-box">
                    <textarea
                        id="comment-memo2"
                        v-model="content"
                        cols="40"
                        rows="3"
                        placeholder="댓글을 입력하세요."
                        autofocus
                        maxlength="254"
                        @keyup.enter.exact="registComment"
                    />
                </div>
                <div class="talk-util-box">
                    <a class="send" @click="registComment()"><i /><span class="blind">보내기</span></a>
                </div>
            </div>
        </div>
        <!-- // commnent-list -->
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PageModel from '../../model/PageModel';
import CommentApi from '../../api/CommentApi';
import WorkApi from '../../api/WorkApi';
import WorkActionApi from '../../api/WorkActionApi';
import { convertCrToBr } from '../../utils/string';

export default {
    name: 'Comment',
    components: {},
    data: () => {
        return {
            id: 0,
            comments: new PageModel(),
            content: ''
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic,
            workId: state => state.work.workId,
            workActionId: state => state.work.workActionId,
            commentId: state => state.work.commentId,
            commentType: state => state.work.commentType
        })
    },
    created() {
        //$('html').scrollTop(0);
    },
    mounted() {
        this.doSearch();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['setShowType']),
        convertCrToBr,
        back() {
            this.$emit('back');
        },
        async registComment() {
            if (this.content.trim() === '') {
                this.content = '';
                return;
            }

            const comment = {
                id: this.workId,
                commentId: this.commentId,
                content: this.content
            };
            const response = await CommentApi.regist(comment);
            if (response) {
                this.comments.addItem(response.content);
                this.content = '';
                if (this.commentType === 'commentOfwork') {
                    await WorkApi.comment(this.workId, { id: this.workId, commentId: response.content.commentId });
                } else {
                    await WorkActionApi.comment(this.workActionId, {
                        id: this.workActionId,
                        commentId: response.content.commentId
                    });
                }
                this.$emit('refresh');
            }
        },
        async doSearch(page) {
            if (page) this.comments.page = page;
            const query = {
                ...this.comments.query()
            };
            if (this.commentId > 0) {
                const response = await CommentApi.list(this.commentId, query);
                if (response) {
                    this.comments.bind(response, true);
                }
            }
        },
        more() {
            ++this.comments.page;
            this.doSearch();
        },
        getFirstName(register) {
            return register.name.substring(0, 1);
        },
        getDisplayName(register) {
            return register.name + ' ' + register.positionName;
        },
        async removeComment(id) {
            const response = await CommentApi.remove(id);
            if (response) {
                this.comments.removeItem(id);
            }
            this.$emit('refresh');
        }
    }
};
</script>
