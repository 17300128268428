import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';
import authHeader from './Header';

const workOfManagerList = workId =>
    httpClient.get(`/wkmanager/of/${workId}`, { headers: authHeader() }).then(res => res.data);
const kakaoWorkOfManagerList = workId =>
    httpClient.get(`/kakao/wkmanager/of/${workId}`, { headers: authHeader() }).then(res => res.data);

const detail = id => httpClient.get(`/wkmanager/${id}`, { headers: authHeader() }).then(res => res.data);

const updateViewCount = (workId, managerId) =>
    httpClient
        .put(`/wkmanager-viewcount/${workId}/${managerId}`, { data: {} }, { headers: authHeader() })
        .then(res => res.data);

export default {
    workOfManagerList: wrapHttpClient(workOfManagerList),
    detail: wrapHttpClient(detail),
    kakaoWorkOfManagerList: wrapHttpClient(kakaoWorkOfManagerList),
    updateViewCount: wrapHttpClient(updateViewCount)
};
