<template>
    <div id="wrap">
        <side-bar v-if="!isLogin" ref="sidebar" />
        <router-view :key="$route.fullPath" />
        <div id="loading" :class="{ on: isLoading }">
            <div class="logo-animated" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
//import Footer from './components/layouts/Footer.vue';
import SideBar from './components/layouts/SideBar.vue';
import WorkerApi from './api/WorkerApi';

import ConstructionApi from './api/ConstructionApi';
import { WORKER_ID } from './const';
import MutationTypes from './store/MutationTypes';

import ElectronHelper from './helper/ElectronHelper';
import AppSetting from './api/AppSetting';
export default {
    name: 'App',
    components: { SideBar },
    data() {
        return {
            bodyCls: 'main',
            isLogin: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic,
            isLoading: state => state.main.isLoading
        })
    },
    watch: {
        $route: 'fetchData'
    },
    created() {
        //this.showLoading();
        //document.cookie = 'SESSION=ZjIwNzRlM2MtNGU3NS00ZjMxLWJmYmYtZGQ4ZjA2M2YxYmQx';
        //const workerId = localStorage.getItem(WORKER_ID);
        //if (!workerId) this.$router.push({ path: '/login' });
    },
    mounted() {
        ElectronHelper.closeLoading();
        if (
            this.$route.name === 'Login' ||
            this.$route.name === 'SelectSite' ||
            this.$route.name === 'FindPasswd' ||
            this.$route.name === 'ReportPrint'
        )
            this.isLogin = true;
        else this.isLogin = false;
        const workerId = localStorage.getItem(WORKER_ID);
        if (workerId) {
            //ElectronHelper.setupFcmToken(workerId);
            this.getSetting();
        }
        //
        if (this.basic.csId === 0 && workerId) {
            ConstructionApi.mySite().then(csResponse => {
                if (csResponse.content.id > 0 && workerId) {
                    WorkerApi.detail(workerId).then(res => {
                        const basic = {
                            csId: csResponse.content.id,
                            name: res.name,
                            position: res.positionName,
                            company: res.company.name,
                            companyId: res.company.id,
                            id: res.id,
                            hp: res.hp,
                            site: csResponse.content.name,
                            asteriskPhone: res.asteriskPhone,
                            imgUrl: res.imgUrl,
                            csAdminYn: res.adminYn,
                            csCompanyAdminYn: res.csCompanyAdminYn
                        };
                        this.$store.commit(MutationTypes.SET_MAIN_BASIC, basic);
                    });
                } else {
                    //이게 의미가 있나 어차피 소속현장이 없다면,,, 로그인이 안될 텐데
                    this.$router.push({ path: '/login' });
                }
            });
        } else {
            console.log(workerId);
            //this.$router.push({ path: '/login' });
        }
    },
    destroyed() {},
    methods: {
        ...mapActions(['setTempWorkContent', 'setTempWorkDocFile', 'showLoading', 'getWeather', 'getCount']),
        fetchData(route) {
            if (
                route.name === 'Login' ||
                route.name === 'SelectSite' ||
                this.$route.name === 'FindPasswd' ||
                route.name === 'ReportPrint'
            )
                this.isLogin = true;
            else this.isLogin = false;

            if (route.name === 'Notice') this.$refs.sidebar.setId('notice');
            else if (route.name === 'Main') this.$refs.sidebar.setId('Main');

            if (
                route.name !== 'Login' &&
                route.name !== 'SelectSite' &&
                route.name !== 'FindPasswd' &&
                route.name !== 'ReportPrint' &&
                this.basic.id > 0
            ) {
                this.getCount();
                this.getWeather();
            }

            //이건 필요없을 것 같음.
        },
        backbtn() {},
        doLastAccess() {
            ElectronHelper.doLastAccess();
        },
        async getSetting() {
            const workerId = this.basic.id || localStorage.getItem(WORKER_ID);
            const setting = await AppSetting.detail(workerId);
            ElectronHelper.setupAlarm(workerId, setting);
        }
    }
};
</script>
