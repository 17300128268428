<template>
    <div class="work-detail-wrapper">
        <!-- content-header -->
        <header class="content-header">
            <div class="header-box">
                <div class="header-nav">
                    <a class="back" @click="back"><i /><span class="blind">뒤로가기</span></a>
                </div>
                <div class="header-title">
                    <span>상세</span>
                </div>
            </div>
            <div v-if="work.type === 'work'" class="header-util">
                <a v-if="work.finishYn === 'Y'" class="repair-done" @click="finish">
                    <span>조치완료</span>
                </a>
                <a v-else class="repair-before" @click="finish">
                    <span>조치 전</span>
                </a>
            </div>
        </header>
        <!-- // content-header -->

        <!-- work-detail-list -->
        <div ref="listBox" class="work-detail-list" @drop.prevent="addFile" @dragover.prevent>
            <div v-if="isDone()" class="work-complete-box">
                <p>보고 계신 업무는 완료 처리 되었습니다.<br />그룹에서 새 업무나 사진을 등록하세요</p>
            </div>
            <ul>
                <li>
                    <!-- work-detail-box -->
                    <div class="work-detail-box">
                        <div class="work-detail-header">
                            <div class="user-summary">
                                <div class="user-thumb">
                                    <span class="thumb">
                                        <img
                                            :src="work.register.imgUrl | profileUrl"
                                            onerror="javascript:this.src='/images/example/img-profile.png'"
                                        />
                                    </span>
                                </div>
                                <div class="user-info">
                                    <p class="user-nm">
                                        {{ work.register.displayName }}&middot;{{ work.register.company.name }}
                                    </p>
                                    <div class="user-member">
                                        <div>
                                            <span :class="taskType()">{{ taskName() }}</span>
                                            <span>{{ work.regDt | longFormatTime }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <a v-if="isAuth()" class="more-nav" @click="toggleMoreBox">
                                <i /><span class="blind">더보기</span>
                            </a>
                            <div ref="moreBox" class="more-nav-box">
                                <ul>
                                    <li><a class="view-modify" @click="showModify">내용 수정</a></li>
                                    <li><a @click="remove">삭제</a></li>
                                </ul>
                            </div>
                            <p class="comment" :inner-html.prop="convertCrToBr(work.content) | withLinks" />
                        </div>

                        <div class="work-swiper swiper-container">
                            <swiper :options="swiperOption">
                                <swiper-slide v-for="(img, index) in imgs" :key="index" class="swiper-slide">
                                    <img
                                        :src="img.url"
                                        onerror="javascript:this.src='/images/default.png'"
                                        @click="showPhotoDetail()"
                                    />
                                </swiper-slide>
                            </swiper>
                            <div class="swiper-button-prev detail" />
                            <div class="swiper-button-next detail" />
                        </div>

                        <div class="work-detail-body">
                            <!-- tag-file-list-box -->
                            <div class="tag-file-list-box">
                                <ul>
                                    <li v-for="(user, index) in manager" :key="'1' + index">
                                        <a class="tag">
                                            <span>
                                                {{ user.manager.displayName }}&middot;
                                                {{ user.manager.company.name }}
                                            </span>
                                        </a>
                                    </li>
                                    <li v-for="(tag, index) in tags" :key="'2' + index">
                                        <a class="tag">
                                            <span>#{{ tag.tag }}</span>
                                        </a>
                                    </li>
                                    <li v-for="(file, index) in docFileList" :key="'3' + index">
                                        <a class="filename" @click="download(file)">
                                            <span>{{ file.name }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <!-- // tag-file-list-box -->
                        </div>
                        <div class="work-detail-etc">
                            <a class="view-comment" @click="showComment(work.commentId)">
                                <div class="comment-write"><span>댓글쓰기</span></div>
                                <div class="comment-count">
                                    <i /><span>담당확인 {{ managerViewCount }}, 댓글 {{ work.countOfComment }}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <!-- // work-detail-box -->
                </li>
                <template v-if="actions.length > 0">
                    <li v-for="(action, index) in actions" :key="index">
                        <action-item
                            :item="action"
                            :work="work"
                            :ref-idx="index"
                            @remove="removeAction"
                            @showComment="showComment"
                            @showPhoto="showActionPhotoDetail"
                        />
                    </li>
                </template>
            </ul>

            <!-- talk-input-wrapper -->
            <div
                v-show="work.type === 'work' && work.finishYn === 'N' && isWriteAuth()"
                ref="talkInput"
                class="talk-input-wrapper"
            >
                <div class="talk-input-box">
                    <textarea
                        id="action-memo"
                        ref="commentMemo"
                        v-model="writeData.content"
                        cols="40"
                        rows="3"
                        placeholder="조치업무를 입력하세요."
                        autofocus
                        @keyup.enter.exact="registAction"
                    />
                </div>
                <div class="attach-hashtag-mention-box">
                    <!-- tag-file-list-box  조치등록은 첨부만 가능합니다. -->
                    <div class="tag-file-list-box">
                        <ul>
                            <li v-for="(file, index) in addFileList" :key="'3' + index">
                                <span class="filename">
                                    {{ file.name }}
                                    <a class="del-file" @click="onClickFileDel(file)">
                                        <span class="blind">삭제</span>
                                    </a>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <!-- // file-download-box -->
                </div>
                <div v-if="imgFileList.length > 0" class="photo-box">
                    <div class="photo-swiper">
                        <div class="swiper-container">
                            <swiper :options="swiperOptionEdit">
                                <swiper-slide v-for="(img, index) in imgFileList" :key="index" class="swiper-slide">
                                    <div class="swiper-slide">
                                        <img :src="img.dataUrl" @click="openImgEditor(img)" />
                                        <a class="del-photo" @click="onClickImgDel(img)">
                                            <i /><span class="blind">사진삭제</span>
                                        </a>
                                    </div>
                                </swiper-slide>
                            </swiper>
                        </div>
                    </div>
                    <div class="swiper-button-prev edit" />
                    <div class="swiper-button-next edit" />
                </div>
                <div class="talk-util-box">
                    <ul>
                        <li>
                            <a class="photo" @click="toggleFileBox()"><i /><span class="blind">사진</span></a>
                        </li>
                        <li>
                            <a class="attatch" @click="pickerFile()"><i /><span class="blind">첨부파일</span></a>
                        </li>
                    </ul>
                    <a class="send" @click="registAction"><i /><span class="blind">보내기</span></a>

                    <div ref="fileBox" class="more-nav-box">
                        <ul>
                            <li><a @click="pickerImgFile()">내 컴퓨터</a></li>
                            <li><a @click="openPictureRoom()">사진함</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- // talk-input-wrapper -->
        </div>
        <!-- // work-detail-box -->
        <input
            id="action-docFile"
            ref="docFile"
            type="file"
            :accept="DOC_ALLOW_FILE"
            class="blind"
            @change="doDocFileAttach"
        />

        <input
            id="action-gallery"
            ref="uploadGallery"
            type="file"
            class="blind"
            accept="image/*"
            @change="doGalleryAttach"
        />

        <pop-image-editor v-if="showImageEditor" ref="popImageEditor" @close="closeImgEditor" />
        <pop-picture-room
            v-if="showPictureRoom"
            ref="popPictureRoom"
            @close="closePictureRoom"
            @setImgs="doPictureRoomAttach"
        />
        <pop-photo-detail
            v-show="showPhoto"
            ref="photoDetail"
            tp-id="work"
            :show.sync="showPhoto"
            :bind-photos="imgs"
        />
        <pop-photo-detail
            v-show="showActionPhoto"
            ref="actionPhotoDetail"
            tp-id="action"
            :show.sync="showActionPhoto"
            :bind-photos="actionImgs"
        />
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
/* components */

import ActionItem from './ActionItem.vue';

/* API */
import WorkApi from '../../api/WorkApi';
import WorkTagApi from '../../api/WorkTagApi';
import WorkManagerApi from '../../api/WorkManagerApi';
import WorkActionApi from '../../api/WorkActionApi';
import FileApi from '../../api/FileApi';

import { WORK_TYPE, WORK_TYPE_CSS } from '../../const';

import ArrayHelper from '../../helper/ArrayHelper';

import { convertCrToBr } from '../../utils/string';

import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

import FileHelper from '../../helper/FileHelper';

import PopImageEditor from '../popup/PopImageEditor';
import PopPictureRoom from '../popup/PopPictureRoom';
import PopPhotoDetail from '../popup/PopPhotoDetail';

export default {
    name: 'WorkDetail',
    components: { ActionItem, swiper, swiperSlide, PopImageEditor, PopPictureRoom, PopPhotoDetail },
    props: {},
    data: () => {
        return {
            ggroup: '',
            isMore: false,
            selectId: 0,
            work: {
                id: 0,
                register: { company: {} },
                content: '',
                finishYn: 'N',
                countOfComment: 0
            },
            tags: [],
            manager: [],
            actions: [],
            imgs: [],
            actionImgs: [],
            swiperOption: {
                direction: 'horizontal',
                slidesPerView: 1,
                navigation: {
                    nextEl: '.swiper-button-next.detail',
                    prevEl: '.swiper-button-prev.detail'
                }
            },
            swiperOptionEdit: {
                direction: 'horizontal',
                slidesPerView: 1,
                navigation: {
                    nextEl: '.swiper-button-next.edit',
                    prevEl: '.swiper-button-prev.edit'
                }
            },
            writeData: {
                room: {},
                work: {},
                content: '',
                managers: [],
                tags: [],
                file: { id: null }
            },
            //workWriteClass: '',
            docFileList: [],

            imgFileList: [],
            addFileList: [],

            DOC_ALLOW_FILE: FileHelper.allowFileExt(),
            docfileId: 0,
            docfileNo: 0,
            imgfileId: 0,
            imgfileNo: 0,
            managerViewCount: 0,
            showImageEditor: false,
            showPictureRoom: false,
            showPhoto: false,
            showActionPhoto: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic,
            id: state => state.work.workId
        })
    },
    created() {},
    mounted() {
        //this.getDetail();
        this.resetData();
    },
    beforeDestroy() {
        this.resetData();
        //document.removeEventListener('touchend', this.listnerBody, true);
    },
    methods: {
        ...mapActions(['getWork', 'removeWork', 'setCommentId', 'setModifyWorkId', 'showLoading', 'hideLoading']),
        back() {
            this.resetData();
            this.$emit('back');
        },
        showPhotoDetail() {
            this.$refs.photoDetail.selectId(this.work);
            this.showPhoto = true;
        },
        showActionPhotoDetail(action, imgs) {
            this.actionImgs = imgs;
            this.$nextTick(() => {
                this.showActionPhoto = true;
                this.$refs.actionPhotoDetail.selectId(action);
            });
        },
        doDocFileAttach() {
            const fileObject = this.$refs.docFile.files[0];
            if (fileObject) {
                this.addDocFileList(fileObject);
            }
        },
        onClickFileDel(fileInfo) {
            const condition = item => item.fileNo === fileInfo.fileNo;
            ArrayHelper.oneRemove(this.addFileList, condition);
        },
        async remove() {
            if (confirm('삭제하시겠습니까?')) {
                await this.removeWork(this.work);
                await this.getWork();
                this.$emit('back');
            }
        },
        async removeAction(id) {
            if (confirm('삭제하시겠습니까?')) {
                const res = await WorkActionApi.remove(id);
                if (res) {
                    const condition = item => item.id === id;
                    ArrayHelper.oneRemove(this.actions, condition);
                }
            }
        },
        getDetail() {
            //this.showLoading();
            this.resetData();
            if (this.id) {
                WorkApi.detail(this.id).then(res => {
                    this.resetData();
                    if (!res) return;
                    //this.work = new WorkModel(res.content);

                    this.work = res.content;
                    if (this.work.type === 'work') {
                        WorkActionApi.actionOfworkId(this.id).then(result => {
                            this.actions = result.content;
                        });
                        WorkManagerApi.workOfManagerList(this.id).then(res => {
                            this.manager = res.content;
                            for (var item of this.manager) {
                                if (item.viewDt) this.managerViewCount += 1;
                            }
                            if (this.isManager()) {
                                if (this.manager.length > this.managerViewCount) {
                                    /*
                                    WorkManagerApi.updateViewCount(this.work.id, this.basic.id).then(res => {
                                        if (res.content) this.managerViewCount += 1;
                                    });
                                    */
                                    WorkManagerApi.updateViewCount(this.work.id, this.basic.id);
                                }
                            }
                        });
                    }
                    if (this.work.fileId) {
                        FileApi.list(this.work.fileId).then(res => {
                            this.imgs = res;
                            //this.styleListBox();
                        });
                    }
                    if (this.work.docFileId) {
                        FileApi.list(this.work.docFileId).then(res => {
                            this.docFileList = res;
                            //this.styleListBox();
                        });
                    }
                    setTimeout(() => {
                        this.styleListBox();
                    }, 200);
                });

                WorkTagApi.tagsOfwork(this.id).then(res => {
                    this.tags = res.content;
                });
            }
        },
        taskName() {
            return WORK_TYPE[this.work.type];
        },
        taskType() {
            return WORK_TYPE_CSS[this.work.type] + '-color';
        },
        isDone() {
            return this.work.type === 'work' && this.work.finishYn === 'Y';
        },
        resetData() {
            this.writeData.content = '';
            this.writeData.type = '';
            this.docFileId = 0;
            this.docfileNo = 0;
            this.imgfileId = 0;
            this.imgfileNo = 0;
            this.actions = [];
            this.manager = [];
            this.docFileList = [];
            this.addFileList = [];
            this.imgFileList = [];
            this.imgs = [];
            this.work = {
                id: 0,
                register: { company: {} },
                content: '',
                finishYn: 'N',
                countOfComment: 0
            };
        },
        async registAction() {
            if (this.writeData.content && this.writeData.content.trim()) {
                this.showLoading();
                this.docfileNo = 0;
                //this.showLoading();
                for (var docFile of this.addFileList) {
                    const docFileForm = docFile.form;
                    if (this.docFileId > 0) docFileForm.set('file_id', this.docFileId);
                    docFileForm.set('file_no', ++this.docfileNo);
                    const fileRes = await FileApi.fileUpload(docFileForm, 'doc');
                    if (fileRes.content) {
                        this.docFileId = fileRes.content[0].id.fileId;
                        this.writeData.docFileId = this.docFileId;
                    }
                }

                const moveImgs = [];

                if (this.imgFileList.length > 0) {
                    this.imgfileNo = 0;
                    for (var imgFile of this.imgFileList) {
                        const imgFileForm = new FormData();
                        //const now = new Date();
                        //const imageName = now.getTime() + '.jpg';

                        imgFileForm.append('file_id', '');
                        imgFileForm.append('file_no', imgFile.fileNo);
                        //imgFileForm.append('file_name', imageName);
                        imgFileForm.append('file_name', imgFile.name);
                        imgFileForm.append('file', imgFile.data);

                        if (this.imgfileId > 0) imgFileForm.set('file_id', this.imgfileId);
                        imgFileForm.set('file_no', ++this.imgfileNo);
                        if (imgFile.type === 'file') {
                            const fileRes = await FileApi.imgUpload(imgFileForm);
                            if (fileRes.content && this.imgfileId === 0) {
                                this.imgfileId = fileRes.content.fileId;
                            }
                        } else if (imgFile.type === 'blob') {
                            const fileRes = await FileApi.blolImgUpload(imgFileForm);
                            if (fileRes.content && this.imgfileId === 0) {
                                this.imgfileId = fileRes.content.fileId;
                            }
                        } else {
                            moveImgs.push(imgFile.data);
                        }
                    }
                    if (this.imgfileId > 0) this.writeData.file.id = this.imgfileId;
                }

                this.writeData.moveImgs = moveImgs;
                this.writeData.work.id = this.id;
                if (this.writeData.file && this.writeData.file.id === null) this.writeData.file = null;
                const response = await WorkActionApi.regist(this.writeData);
                //this.hideLoading();
                if (response) await this.getDetail();

                this.getWork();
                this.resetData();
                this.hideLoading();
            }
        },
        convertCrToBr,
        isWriteAuth() {
            const regId = this.basic.id;
            if (regId === this.work.register.id) return true;
            else if (this.manager.find(manager => manager.manager.id === regId)) return true;
            else false;
        },
        isManager() {
            const regId = this.basic.id;
            if (this.manager.find(manager => manager.manager.id === regId)) return true;
            else false;
        },
        async download(file) {
            await FileApi.fileDownload(file.id);
        },
        toggleMoreBox() {
            this.$refs.moreBox.classList.toggle('on');
        },
        async finish() {
            await WorkApi.finish(this.work.id);
            this.work.finishYn = this.work.finishYn === 'Y' ? 'N' : 'Y';
        },
        isAuth() {
            //return false;
            return this.work.register.id === this.basic.id;
        },
        showModify() {
            this.setModifyWorkId(this.id);
        },
        showActionModify(workActionId) {
            const param = { workId: this.id, workActionId };
            this.setModifyWorkActionId(param);
        },
        pickerImgFile() {
            this.$refs.uploadGallery.value = '';
            if (FileHelper.isCount(this.imgFileList.length)) this.$refs.uploadGallery.click();
        },
        doGalleryAttach() {
            this.$refs.fileBox.classList.remove('on');

            const fileObject = this.$refs.uploadGallery.files[0];
            if (fileObject) {
                this.addImgFileList(fileObject);
            }
        },
        openImgEditor(imgObject) {
            this.showImageEditor = true;
            setTimeout(() => {
                this.$refs.popImageEditor.setDataUrlImage(imgObject);
            }, 100);
        },
        closeImgEditor() {
            this.showImageEditor = false;
        },
        toggleFileBox() {
            this.$refs.fileBox.classList.toggle('on');
        },
        openPictureRoom() {
            this.$refs.fileBox.classList.remove('on');
            this.showPictureRoom = true;
        },
        closePictureRoom() {
            this.showPictureRoom = false;
        },
        doPictureRoomAttach(pictureRoomImgs) {
            this.closePictureRoom();
            pictureRoomImgs.forEach(item => {
                ++this.imgfileNo;
                const now = new Date();
                const imageName = now.getTime() + '.jpg';
                const addFile = {
                    fileNo: this.imgfileNo,
                    name: imageName,
                    type: 'picture',
                    dataUrl: item.imgUrl,
                    data: item.fileId
                };

                this.imgFileList.push(addFile);
            });
        },
        onClickImgDel(fileInfo) {
            const condition = item => item.fileNo === fileInfo.fileNo;
            ArrayHelper.oneRemove(this.imgFileList, condition);
        },
        showComment(commentId) {
            const param = { workId: this.id, commentId, type: 'commentOfwork' };
            this.setCommentId(param);
            this.$emit('show', 'comment');
        },
        pickerFile() {
            this.$refs.docFile.value = '';
            if (FileHelper.isCount(this.addFileList.length)) this.$refs.docFile.click();
        },
        styleListBox() {
            this.$nextTick(() => {
                this.$refs.listBox.style.height = 'calc(100% - ' + (this.$refs.talkInput.scrollHeight + 40) + 'px)';
                this.focusInput();
            });
        },
        addFile(e) {
            if (this.isDone() || !this.isWriteAuth()) return;

            const droppedFiles = e.dataTransfer.files;
            if (!droppedFiles) return;

            for (var key in droppedFiles) {
                var fileObject = droppedFiles[key];
                const size = fileObject.size;

                if (FileHelper.isAllowImgFile(fileObject.name)) {
                    //이미지쪽에 첨부
                    this.addImgFileList(fileObject);
                } else if (FileHelper.isNewSize(size, fileObject.name) && FileHelper.isAllowFile(fileObject.name)) {
                    //파일쪽에 첨부한다.
                    this.addDocFileList(fileObject);
                }
            }
        },
        addImgFileList(fileObject) {
            const reader = new FileReader();
            reader.onload = e => {
                ++this.imgfileNo;

                const addFile = {
                    fileNo: this.imgfileNo,
                    name: fileObject.name,
                    type: 'file',
                    dataUrl: e.target.result,
                    data: fileObject
                };
                this.imgFileList.push(addFile);
                this.styleListBox();
            };
            reader.readAsDataURL(fileObject); //
        },
        addDocFileList(fileObject) {
            if (FileHelper.isAllowFile(fileObject.name)) {
                ++this.docfileNo;
                const formData = new FormData();
                formData.append('file_id', '');
                formData.append('file_no', this.docfileNo);
                formData.append('file', fileObject);
                const addFile = {
                    fileNo: this.docfileNo,
                    name: fileObject.name,
                    form: formData
                };
                this.addFileList.push(addFile);
            }
            this.styleListBox();
        },
        focusInput() {
            this.$refs.commentMemo.focus();
        }
    }
};
</script>
<style type="text/css">
.swiper-button-disabled {
    display: none;
}
</style>
