<template>
    <div ref="roomListLayer" class="content-side-wrapper">
        <!-- content-header -->
        <header class="content-header">
            <div class="header-box">
                <div class="header-title">
                    <span>그룹</span>
                </div>
            </div>
            <div class="header-util">
                <a class="add modal-link" @click="setShowPopAddRoom(true)">
                    <i /><span class="blind">그룹 추가</span>
                </a>
                <a class="winmin modal-link" @click="defaultSize"> <i /><span class="blind">창축소</span> </a>
            </div>
        </header>
        <!-- // content-header -->
        <!-- search -->
        <div class="search-box">
            <div class="search">
                <input
                    v-model="searchText"
                    type="text"
                    class="txt"
                    placeholder="그룹명, 방장 검색"
                    @input="setFilterRoom"
                />
            </div>
        </div>
        <!-- // search -->
        <!-- group-list-box -->
        <div class="group-list-box">
            <p class="title">
                <span>전체그룹</span>
                <span class="cnt">{{ filterRooms.length }}</span>
            </p>
            <!-- user-list -->
            <div class="user-list">
                <ul>
                    <li v-for="(room, index) in filterRooms" :key="index">
                        <a
                            class="user-summary"
                            :data-id="room.id"
                            :class="{ on: selectRoom.id === room.id }"
                            @click="clickRoom(room)"
                        >
                            <div class="user-thumb">
                                <span class="thumb">
                                    <img
                                        :src="room.master.imgUrl | profileUrl"
                                        onerror="javascript:this.src='/images/example/img-profile.png'"
                                    />
                                </span>
                                <span v-if="room.alarmCnt > 0" class="cnt">{{ room.alarmCnt }}</span>
                            </div>
                            <div class="user-info">
                                <p class="user-nm">{{ room.name }} | 멤버 {{ room.countOfMember }}명</p>
                                <div class="user-member">
                                    <div>
                                        <strong>방장 {{ room.master.name }}</strong>
                                        <span>
                                            {{ room.workers }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a class="more-nav" @click="clickMore(room)"> <i /><span class="blind">더보기</span> </a>
                        <div class="more-nav-box" :class="selectRoomId === room.id && !showPopLeaveRoom ? 'on' : ''">
                            <ul>
                                <li>
                                    <a class="modal-link" @click="clickRemoveGroup(room)">
                                        그룹 나가기
                                    </a>
                                </li>
                                <li>
                                    <a @click="clickManageGroup(room)">그룹 관리</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- // user-list -->
        </div>
        <!-- // group-list-box -->
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ArrayHelper from '../../helper/ArrayHelper';
import ElectronHelper from '../../helper/ElectronHelper';
import SockJS from 'sockjs-client';
import Stomp from 'webstomp-client';
//import { CS_IDS } from '../../const';

export default {
    name: 'RoomList',
    components: {},
    data: () => {
        return {
            searchText: '',
            showPopLeaveRoom: false,
            showPopAddRoom: false,
            showPopManageRoom: false,
            showPopAddWorker: false,
            selectRoomId: 0,
            selectRoom: {
                id: null
            },
            filterRooms: [{ alarmCnt: 0 }],
            originRooms: []
        };
    },
    computed: {
        ...mapState({
            rooms: state => state.work.roomList,
            basic: state => state.main.basic,
            mutationSelectRoomId: state => state.work.selectRoomId
        })
    },
    created() {},
    mounted() {
        this.getWorkOfRoom();
        document.addEventListener('mouseup', e => {
            if ($(e.target).closest('.more-nav').length === 0 && $(e.target).closest('.more-nav-box').length === 0) {
                $('.more-nav-box').removeClass('on');
                this.selectRoomId = 0;
            }
        });
        //window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        /* if (window.stompClient) {
            window.stompClient.disconnect();
            window.stompClient = null;
        }
        */

        document.removeEventListener('mouseup', e => {
            if ($(e.target).closest('.more-nav').length === 0 && $(e.target).closest('.more-nav-box').length === 0) {
                $('.more-nav-box').removeClass('on');
                this.selectRoomId = 0;
            }
        });
        //window.removeEventListener('resize', this.onResize);
    },
    methods: {
        ...mapActions([
            'getMyRooms',
            'setShowPopLeaveRoom',
            'setShowPopManageRoom',
            'setShowPopAddRoom',
            'incRoomCnt',
            'rstRoomCnt',
            'incAlarmCnt',
            'setSelectRoom'
        ]),
        removeRoom(id) {
            const condition = item => item.id === id;
            ArrayHelper.oneRemove(this.filterRooms, condition);
        },
        async getWorkOfRoom() {
            await this.getMyRooms();
            this.filterRooms = this.rooms;
            if (!window.stompClient) {
                this.connectSoc();
            }
            // else{
            //   if(roomId) this.addSubRoom(roomId);
            // }
        },
        setFilterRoom() {
            this.filterRooms = this.rooms.filter(
                room => room.name.indexOf(this.searchText) > -1 || room.master.name.indexOf(this.searchText) > -1
            );
        },
        async clickRemoveGroup(room) {
            if (room.master.id === this.basic.id && room.countOfMember > 1) {
                alert('방장을 이전 후 그룹 탈퇴가 가능합니다.');
                this.$emit('update:room', room);
                await this.$emit('changeOwner');
                return;
            }
            await this.$emit('update:room', room);
            await this.setShowPopLeaveRoom(true);
            this.selectRoomId = 0;
        },
        clickManageGroup(room) {
            this.$emit('update:room', room);
            this.setShowPopManageRoom(true);
            this.selectRoomId = 0;
        },
        setRoom(id) {
            // console.log("selRoom:id::"+ id);
            // console.log("this.rooms::" + JSON.stringify(this.rooms[0]));
            const selRoom = this.rooms.find(room => room.id === id);
            console.log('selRoom:::' + JSON.stringify(selRoom));
            this.clickRoom(selRoom);
        },
        clickRoom(room) {
            //this.selectRoom = room;
            this.$emit('update:room', room);
            this.$emit('refresh', room.id);
            //좀 이해가 안되지만..이게 위에 있으면 이상하게 업데이트가 되는거 같음.
            this.selectRoom = room;

            this.rstRoomCnt(room.id); //alarmCnt 0
            this.setSelectRoom(room.id);
            console.log(this.mutationSelectRoomId);
        },
        clickMore(room) {
            this.selectRoom = room;
            this.selectRoomId = room.id;
        },
        onResize() {
            const width = document.body.clientWidth;
            if (this.$refs.roomListLayer) {
                if (width > 720) {
                    //if (this.contentClass === 'content-side-1') this.contentClass = 'content-side-2';
                    this.$refs.roomListLayer.style.maxWidth = '360px';
                } else this.$refs.roomListLayer.style.maxWidth = '720px';
            }
        },
        defaultSize() {
            ElectronHelper.windowDefaultSize();
        },
        async connectSoc() {
            //console.log("start:connect");

            if (!window.stompClient) {
                this.socket = new SockJS('/websocket');
                this.stompClient = Stomp.over(this.socket);
                window.stompClient = this.stompClient;
                window.stompClient.connect(
                    {},
                    frame => {
                        console.log(frame);
                        window.stompClient.subscribe('/topic/chat/addRoom', tick => {
                            //console.log('workerIds::' + JSON.parse(tick.body).workerIds);
                            //console.log('this.basic.id::' + this.basic.id);
                            if (JSON.parse(tick.body).workerIds.includes(String(this.basic.id))) {
                                console.log('OK!!');
                                this.getWorkOfRoom(JSON.parse(tick.body).roomId);
                                this.addSubRoom(JSON.parse(tick.body).roomId);
                            }
                        });

                        window.stompClient.subscribe('/topic/chat/addAlarm', tick => {
                            if (
                                localStorage.getItem('csIds').includes(JSON.parse(tick.body).csId) ||
                                JSON.parse(tick.body).workerId === this.basic.id
                            ) {
                                this.incAlarmCnt();
                            }
                        });
                        this.rooms.map(v => {
                            this.addSubRoom(v.id);
                        });
                    },
                    error => {
                        console.log(error);
                        if (window.stompClient) {
                            window.stompClient.disconnect();
                            window.stompClient = null;
                            setTimeout(this.connectSoc(), 10000);
                            console.log('STOMP: Reconecting in 10 seconds');
                        }
                    }
                );
            }
        },
        addSubRoom(roomId) {
            if (window.stompClient) {
                window.stompClient.subscribe('/topic/chat/' + roomId, this.subRoomSuc);
            }
        },
        async addRoom(roomId, workerIds) {
            if (window.stompClient) {
                await this.getMyRooms();
                console.log('send');
                console.log(this.rooms);
                window.stompClient.send(
                    '/app/chat.message/addRoom',
                    JSON.stringify({ roomId: roomId, workerIds: workerIds }),
                    {}
                );
                this.addSubRoom(roomId);
            }
        },
        subRoomSuc(tick) {
            const roomId = Number(JSON.parse(tick.body).roomId);
            //console.log(tick);
            //console.log('roomId::' + roomId);
            //console.log('tag::' + $('div.user-list a.user-summary.on').dataset);
            //console.log('this.mutationSelectRoomId::' + this.mutationSelectRoomId);
            if (this.mutationSelectRoomId === roomId) {
                this.setRoom(roomId);
            } else {
                this.incRoomCnt(roomId);
            }
        }
    }
};
</script>
