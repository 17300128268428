<template>
    <div class="modal modal-width-fixed modal-select-person on">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-nav">
                        <a class="back"><i /><span class="blind">뒤로가기</span></a>
                    </div>
                    <div class="header-title">
                        <span>멤버 추가</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="confirm" @click="addWorkers"><span>확인</span></a>
                    <span class="gap" />
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->

            <!-- modal-body -->
            <div class="modal-body">
                <div class="search-box">
                    <div class="search">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="이름, 회사, 직위 검색"
                            @keyup="setFilterWorkers"
                        />
                    </div>
                </div>

                <div class="list-box">
                    <div class="select-box">
                        <input id="chk-add-worker-all" type="checkbox" class="sel-user" @click="allSelect()" />
                        <label for="chk-add-worker-all">전체선택</label>
                    </div>
                    <div class="list-scroll-box">
                        <!-- user-list -->
                        <div class="user-list">
                            <ul>
                                <li v-for="(worker, index) in filterWorkers" :key="index">
                                    <input
                                        :id="'chk-add-worker-' + index"
                                        v-model="workerIds"
                                        type="checkbox"
                                        class="sel-user"
                                        :value="worker.id"
                                    />
                                    <label class="user-summary" :for="'chk-add-worker-' + index">
                                        <div class="user-thumb">
                                            <span class="thumb">
                                                <img :src="worker.imgUrl | profileUrl" />
                                            </span>
                                        </div>
                                        <div class="user-info">
                                            <p class="user-nm">{{ worker.displayName }}</p>
                                            <div class="user-member">
                                                <div>
                                                    <strong>{{ worker.company.name }}</strong>
                                                    <span>{{ worker.regDt | formatTime }} 가입</span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <!-- // user-list -->
                    </div>
                </div>
            </div>
            <!-- // modal-body -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
//import ArrayHelper from '../../helper/ArrayHelper';

import WorkerApi from '../../api/WorkerApi';

export default {
    name: 'PopWorker',
    components: {},
    props: {},
    data: () => {
        return {
            searchText: '',
            condition: {
                notInCsId: null,
                rows: 500,
                page: 1
            },
            workers: [],
            filterWorkers: [],
            workerIds: [],
            isAll: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        this.condition.notInCsId = this.basic.csId;
        this.getWorkers();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        close() {
            this.$emit('update:show', false);
        },
        async getWorkers() {
            //현장관리자면 전체..code 다 가져오기

            const res = await WorkerApi.list(this.condition);
            this.filterWorkers = this.workers = res.content;
        },
        setFilterWorkers() {
            this.filterWorkers = this.workers.filter(
                worker =>
                    worker.displayName.indexOf(this.searchText) > -1 ||
                    worker.company.name.indexOf(this.searchText) > -1
            );
        },
        allSelect() {
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.workerIds = this.filterWorkers.map(item => item.id);
            } else {
                this.workerIds = [];
            }
        },
        addWorkers() {
            this.$emit('setData', this.workerIds);
            this.close();
        }
    }
};
</script>
