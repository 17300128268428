import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';
import authHeader from './Header';

const list = () => httpClient.get(`/weather`, { headers: authHeader() }).then(res => res.data);
const airList = () => httpClient.get(`/airquality`, { headers: authHeader() }).then(res => res.data);
const minMax = (csId, yyyymmdd) =>
    httpClient.put(`/weather/minmax/${csId}/${yyyymmdd}`, {}, { headers: authHeader() }).then(res => res.data);
//const polling = () => httpClient.get(`/weather/now`).then(res => res.data);
export default {
    list: wrapHttpClient(list),
    airList: wrapHttpClient(airList),
    minMax: wrapHttpClient(minMax)
};
