import { DEFAULT_HEADER, JWT_REFRESH_TOKEN, WORKER_ID, CS_ID } from '../const';

export default function authHeader() {
    return {
        ...DEFAULT_HEADER,
        Authorization: localStorage.getItem(JWT_REFRESH_TOKEN),
        CsId: localStorage.getItem(CS_ID),
        Workerid: localStorage.getItem(WORKER_ID)
    };
}
