<template>
    <!-- modal : modal-photo-detail -->
    <div class="modal modal-photo-detail" :class="{ on: classOn }">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-nav">
                        <a class="back"><i /><span class="blind">뒤로가기</span></a>
                    </div>
                    <div class="header-title">
                        <span>사진 상세</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="btn-photo-download" @click="downloadPhoto()"><span>사진 다운로드</span></a>
                    <a class="btn-photo-print" @click="print()"><span>사진 출력</span></a>
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->

            <!-- modal-body -->
            <div class="modal-body">
                <div class="photo-view-box">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="img-box">
                                    <img
                                        ref="workImg"
                                        :src="work.url"
                                        onerror="javascript:this.src='/images/default.png'"
                                        @click="imageToClipBoard"
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-if="!isFirst" class="swiper-button-prev" @click="prePhoto" />
                        <div v-if="!isLast" class="swiper-button-next" @click="nextPhoto" />
                    </div>
                </div>

                <div class="photo-detail-box">
                    <div class="charger-info">
                        <div class="user-summary">
                            <div class="user-thumb">
                                <span class="thumb">
                                    <img
                                        :src="work.register.imgUrl | profileUrl"
                                        onerror="javascript:this.src='/images/example/img-profile.png'"
                                    />
                                </span>
                            </div>
                            <div class="user-info">
                                <p class="user-nm">
                                    {{ work.register.displayName }}&middot;{{ work.register.company.name }}
                                </p>
                                <div>
                                    <p>
                                        <span>{{ work.work.room.name }}</span>
                                    </p>
                                    <p>
                                        <span :class="getClass()">{{ work.typeName }}</span>
                                        <span>{{ work.regDt | formatTime }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="photo-desc-box">
                        <div class="form-box">
                            <div class="field-box">
                                <div v-if="isFileEdit" class="field with-btn">
                                    <input v-model="work.name" type="text" class="txt" maxlength="100" />
                                    <a @click="fileRename"><span>파일명 변경</span></a>
                                </div>
                                <div v-else class="field with-btn">
                                    <input v-model="work.name" type="text" class="txt" maxlength="100" readonly />
                                </div>
                            </div>
                            <p class="desc">
                                {{ getContent() }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- // modal-body -->
        </div>
    </div>
    <!-- // modal : modal-photo-detail -->
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FileApi from '../../api/FileApi';
//import ArrayHelper from '../../helper/ArrayHelper';

export default {
    name: 'PopPhotoDetail',
    components: {},
    props: {
        bindPhotos: {
            type: Array,
            required: false,
            default: null
        }
    },
    data: () => {
        return {
            classOn: false,
            isFileEdit: false,
            photos: [],
            work: { id: null, register: { company: {}, displayName: '' }, work: { room: {} } },
            curIdx: 0,
            isLast: false,
            isFirst: true
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    watch: {
        bindPhotos: {
            // the callback will be called immediately after the start of the observation
            immediate: true,
            handler(val) {
                if (val) this.photos = val;
            }
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        close() {
            this.classOn = false;
            this.$emit('update:show', false);
        },
        selectId(id, isEdit) {
            this.isFileEdit = isEdit;
            this.curIdx = this.photos.findIndex(item => item.id === id);
            this.isLast = this.curIdx >= this.photos.length - 1;
            this.isFirst = this.curIdx === 0;
            //this.work = this.photos.find(item => item.id === id);
            this.work = this.photos[this.curIdx];
            this.classOn = true;
        },
        getContent() {
            if (this.work.type === 'action') return this.work.action.content;
            else return this.work.work.content;
        },
        prePhoto() {
            this.curIdx = this.photos.findIndex(item => item.id === this.work.id);
            this.isFirst = this.curIdx < 2;
            if (this.curIdx > 0) this.work = this.photos[this.curIdx - 1];
        },
        nextPhoto() {
            this.curIdx = this.photos.findIndex(item => item.id === this.work.id);
            this.isLast = this.curIdx >= this.photos.length - 2;
            if (this.curIdx < this.photos.length - 1) this.work = this.photos[this.curIdx + 1];
        },
        async fileRename() {
            if (confirm('파일명을 변경하시겠습니까?')) {
                const param = {
                    fileId: this.work.fileId,
                    fileNo: this.work.fileNo,
                    rename: this.work.name
                };
                await FileApi.rename(param);
                this.close();
            }
        },
        getClass() {
            if (this.work.type === 'action') return 'talk-color';
            else return this.work.type + '-color';
        },
        print() {
            window.print();
        },
        async downloadPhoto() {
            const param = {
                fileId: this.work.fileId,
                fileNo: this.work.fileNo || 1
            };
            await FileApi.fileDownload(param);
        },
        selectText(element) {
            const doc = document;
            if (doc.body.createTextRange) {
                var range = document.body.createTextRange();
                range.moveToElementText(element);
                range.select();
            } else if (window.getSelection) {
                const selection = window.getSelection();
                const range = document.createRange();
                range.selectNodeContents(element);
                selection.removeAllRanges();
                selection.addRange(range);
            }
        },
        imageToClipBoard() {
            const img = this.$refs.workImg;
            img.contenteditable = true;
            this.selectText(img);
            document.execCommand('copy');
            window.getSelection().removeAllRanges();
            img.removeAttribute('contenteditable');
            alert('이미지 복사!');
        }
    }
};
</script>
