import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';
import authHeader from './Header';

const actionOfworkId = workId =>
    httpClient.get(`/wkaction/of/${workId}`, { headers: authHeader() }).then(res => res.data);
const kakaoActionOfworkId = workId =>
    httpClient.get(`/kakao/wkaction/of/${workId}`, { headers: authHeader() }).then(res => res.data);

const detail = id => httpClient.get(`/wkaction/${id}`, { headers: authHeader() }).then(res => res.data);
const regist = param => httpClient.post(`/wkaction/`, param, { headers: authHeader() }).then(res => res.data);
const modify = (id, param) => httpClient.put(`/wkaction/${id}`, param, { headers: authHeader() }).then(res => res.data);
const comment = (id, param) =>
    httpClient.put(`/wkactioncomment/${id}`, param, { headers: authHeader() }).then(res => res.data);
const remove = id => httpClient.delete(`/wkaction/${id}`, { headers: authHeader() }).then(res => res.data);

export default {
    actionOfworkId: wrapHttpClient(actionOfworkId),
    detail: wrapHttpClient(detail),
    regist: wrapHttpClient(regist),
    modify: wrapHttpClient(modify),
    remove: wrapHttpClient(remove),
    kakaoActionOfworkId: wrapHttpClient(kakaoActionOfworkId),
    comment: wrapHttpClient(comment)
};
