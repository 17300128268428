import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';

import authHeader from './Header';

const list = query => httpClient.get(`/board`, { params: query, headers: authHeader() }).then(res => res.data);

const main = type =>
    httpClient
        .get(`/board/main/${type}`, {
            headers: authHeader()
        })
        .then(res => res.data.content);

const listByType = type => httpClient.get(`/board/list/${type}`, { headers: authHeader() }).then(res => res.data);

const detail = id => httpClient.get(`/board/${id}`, { headers: authHeader() }).then(res => res.data.content);
const kakaoDetail = id => httpClient.get(`/kakao/board/${id}`, { headers: authHeader() }).then(res => res.data.content);

const check = id => httpClient.put(`/board/check/${id}`, {}, { headers: authHeader() }).then(res => res.data);
const hide = id => httpClient.put(`/board/hide/${id}`, {}, { headers: authHeader() }).then(res => res.data);
const remove = id => httpClient.delete(`/board/${id}`, { headers: authHeader() }).then(res => res.data);
const regist = param => httpClient.post(`/board`, param, { headers: authHeader() }).then(res => res.data);
const modify = (id, param) => httpClient.put(`/board/${id}`, param, { headers: authHeader() }).then(res => res.data);

export default {
    main: wrapHttpClient(main),
    list: wrapHttpClient(list),
    listByType: wrapHttpClient(listByType),
    detail: wrapHttpClient(detail),
    check: wrapHttpClient(check),
    hide: wrapHttpClient(hide),
    regist: wrapHttpClient(regist),
    modify: wrapHttpClient(modify),
    remove: wrapHttpClient(remove),
    kakaoDetail: wrapHttpClient(kakaoDetail)
};
