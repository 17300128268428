import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';
import authHeader from './Header';

const list = query => httpClient.get(`/wkroom`, { params: query, headers: authHeader() }).then(res => res.data);
const detail = id => httpClient.get(`/wkroom/${id}`, { headers: authHeader() }).then(res => res.data.content);
const regist = param => httpClient.post(`/wkroom/`, param, { headers: authHeader() }).then(res => res.data.content);
const modify = (id, param) =>
    httpClient.put(`/wkroom/${id}`, param, { headers: authHeader() }).then(res => res.data.content);
const remove = id => httpClient.delete(`/wkroom/${id}`, { headers: authHeader() }).then(res => res.data.content);
const myList = () => httpClient.get(`/wkroom/mylist`, { headers: authHeader() }).then(res => res.data.content);

const codeList = query =>
    httpClient.get(`/wkroom/code`, { params: query, headers: authHeader() }).then(res => res.data.content);

export default {
    list: wrapHttpClient(list),
    detail: wrapHttpClient(detail),
    regist: wrapHttpClient(regist),
    modify: wrapHttpClient(modify),
    remove: wrapHttpClient(remove),
    myList: wrapHttpClient(myList),
    codeList: wrapHttpClient(codeList)
};
