<template>
    <div class="modal modal-width-fixed modal-select-group on">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-nav">
                        <a class="back"><i /><span class="blind">뒤로가기</span></a>
                    </div>
                    <div class="header-title">
                        <span>그룹 선택</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="confirm" @click="addRoom"><span>확인</span></a>
                    <span class="gap" />
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->

            <!-- modal-body -->
            <div class="modal-body">
                <div class="search-box">
                    <div class="search">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="그룹명 검색"
                            @keyup="setFilterRooms"
                        />
                    </div>
                </div>

                <div class="list-box">
                    <div class="select-box">
                        <input id="chk-add-group-all" v-model="selectRoom" type="radio" class="group-nm" />
                        <label for="chk-add-group-all">전체 그룹</label>
                    </div>
                    <div class="list-scroll-box">
                        <!-- user-list -->
                        <div class="group-list">
                            <ul>
                                <li v-for="(room, index) in filterRooms" :key="index">
                                    <input
                                        :id="'chk-add-group-' + index"
                                        v-model="selectRoom"
                                        type="radio"
                                        class="group-nm"
                                        :value="room"
                                    />
                                    <label :for="'chk-add-group-' + index">
                                        {{ room.name }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <!-- // user-list -->
                    </div>
                </div>
            </div>
            <!-- // modal-body -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
//import ArrayHelper from '../../helper/ArrayHelper';

import WorkRoomApi from '../../api/WorkRoomApi';

export default {
    name: 'PopGroup',
    components: {},
    data: () => {
        return {
            searchText: '',
            condition: {
                csId: 0
            },
            codeRooms: [],
            filterRooms: [],
            selectRoom: [],
            isAll: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic,
            rooms: state => state.work.roomList
        })
    },
    created() {
        this.condition.csId = this.basic.csId;
    },
    mounted() {
        this.getRooms();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['getMyRooms']),
        close() {
            this.$emit('update:show', false);
        },
        async getRooms() {
            //현장관리자면 전체..code 다 가져오기
            if (this.basic.csAdminYn === 'Y') {
                const res = await WorkRoomApi.codeList(this.condition);
                if (res) {
                    this.codeRooms = res.content.map(item => {
                        return { name: item.name, id: item.id };
                    });
                }
            } else {
                await this.getMyRooms();
                this.codeRooms = this.rooms.map(item => {
                    return { name: item.name, id: item.id };
                });
            }
            this.filterRooms = this.codeRooms;
        },
        setFilterRooms() {
            this.filterRooms = this.codeRooms.filter(item => item.name.indexOf(this.searchText) > -1);
        },
        allSelect() {
            /*
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.roomIds = this.filterRooms.map(item => item.id);
            } else {
                this.roomIds = [];
            }
          */
        },
        addRoom() {
            this.$emit('setData', this.selectRoom);
            this.close();
        }
    }
};
</script>
