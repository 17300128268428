<template>
    <div id="content-wrap" class="content-side-1">
        <!-- [개발] 1단으로 화면 모두 채우는 경우 -->
        <!-- content-side-wrapper 1단 -->
        <div class="content-side-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <ul class="site-nav">
                        <li>
                            <a :class="{ on: selView === 'company' }" @click="() => (selView = 'company')">
                                <span>업체관리</span>
                            </a>
                        </li>
                        <li>
                            <a :class="{ on: selView === 'member' }" @click="() => (selView = 'member')">
                                <span>멤버관리</span>
                            </a>
                        </li>
                        <li>
                            <a :class="{ on: selView === 'group' }" @click="() => (selView = 'group')">
                                <span>그룹관리</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="header-util">
                    <span class="location">{{ basic.site }}</span>
                    <a class="winmin modal-link" @click="defaultSize"> <i /><span class="blind">창축소</span> </a>
                </div>
            </header>
            <!-- // content-header -->
            <site-company v-if="selView === 'company'" />
            <site-worker v-if="selView === 'member'" />
            <site-room v-if="selView === 'group'" />
        </div>
        <!-- // content-side-wrapper -->
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
//import ArrayHelper from '../../helper/ArrayHelper';

import SiteCompany from './SiteCompany';
import SiteWorker from './SiteWorker';
import SiteRoom from './SiteRoom';

import ElectronHelper from '../../helper/ElectronHelper';

export default {
    name: 'Site',
    components: { SiteCompany, SiteWorker, SiteRoom },
    data: () => {
        return {
            selView: 'company'
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {
        //this.condition.csId = this.basic.csId;
    },
    mounted() {
        ElectronHelper.windowDefaultReSize();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        defaultSize() {
            ElectronHelper.windowDefaultReSize();
        }
    }
};
</script>
