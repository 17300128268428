/*
 * object에서 불필요한 key를 제거한 object 반환
 */
//import { ACCESS_TOKEN, SESSION_USER_INFO } from '../const';
export default {
    excludeObject: (payload, removes) => {
        return Object.keys(payload)
            .filter(key => removes.includes(key) === false)
            .reduce((obj, key) => {
                obj[key] = payload[key];
                return obj;
            }, {});
    },
    /*
     * object에서 필요한 key만 표함하여 object 반환
     */
    includeObject: (payload, removes) => {
        return Object.keys(payload)
            .filter(key => removes.includes(key))
            .reduce((obj, key) => {
                obj[key] = payload[key];
                return obj;
            }, {});
    },

    resetObject: (payload, defaultObj = {}) => {
        return Object.keys(payload).map(key => (payload[key] = defaultObj[key]));
    },

    removeEmptyObject: payload => {
        return Object.keys(payload).forEach(key => {
            if (payload[key] === '') delete payload[key];
        });
    },
    cloneJsonObject: object => (object ? JSON.parse(JSON.stringify(object)) : {}),
    //이건 변경이 필요함
    cloneObject: obj => Object.assign({}, obj),
    thumbImgUrl: url => url + '_400X400',
    sThumbImgUrl: url => url + '_130X130'
};
