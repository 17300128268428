import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';
import authHeader from './Header';

const siteTypeOfTagList = siteType =>
    httpClient.get(`/cstag/${siteType}`, { headers: authHeader() }).then(res => res.data);
const siteTypeRegist = (siteType, tag) =>
    httpClient.post(`/cstag/${siteType}/${tag}`, {}, { headers: authHeader() }).then(res => res.data);
const siteTypeRemove = (siteType, tag) =>
    httpClient.delete(`/cstag/${siteType}/${tag}`, { headers: authHeader() }).then(res => res.data);
const workRegist = (workId, tag) =>
    httpClient.post(`/cstag/${workId}/${tag}`, {}, { headers: authHeader() }).then(res => res.data);
const workRemove = (workId, tag) =>
    httpClient.delete(`/cstag/${workId}/${tag}`, { headers: authHeader() }).then(res => res.data);

const tagsOfroom = roomId => httpClient.get(`/wktag/ofroom/${roomId}`, { headers: authHeader() }).then(res => res.data);
const tagsOfwork = workId => httpClient.get(`/wktag/ofwork/${workId}`, { headers: authHeader() }).then(res => res.data);
const kakaoTagsOfwork = workId =>
    httpClient.get(`/kakao/wktag/ofwork/${workId}`, { headers: authHeader() }).then(res => res.data);

const rankOfroom = roomId =>
    httpClient.get(`/wktag/rankofroom/${roomId}`, { headers: authHeader() }).then(res => res.data.content);

const tagsOfsite = csId => httpClient.get(`/wktag/ofsite/${csId}`, { headers: authHeader() }).then(res => res.data);

export default {
    siteTypeOfTagList: wrapHttpClient(siteTypeOfTagList),
    regist: wrapHttpClient(siteTypeRegist),
    remove: wrapHttpClient(siteTypeRemove),
    workRegist: wrapHttpClient(workRegist),
    workRemove: wrapHttpClient(workRemove),
    tagsOfroom: wrapHttpClient(tagsOfroom),
    tagsOfwork: wrapHttpClient(tagsOfwork),
    rankOfroom: wrapHttpClient(rankOfroom),
    tagsOfsite: wrapHttpClient(tagsOfsite),
    kakaoTagsOfwork: wrapHttpClient(kakaoTagsOfwork)
};
