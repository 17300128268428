import axios from 'axios';
//import { DEFAULT_HEADER, API_URL, JWT_TOKEN, JWT_REFRESH_TOKEN, WORKER_ID, CS_ID } from '../../const';

import { DEFAULT_HEADER, API_URL } from '../../const';

//import authHeader from '../../api/Header';
// If you need global defaults, use:
//axios.defaults.baseURL = '/api';
// axios.defaults.headers.common['Accept-Encoding'] = 'gz';
// ...

// TODO: if we need, use a serverinfo,
// TODO: and if question, account Client same setting, they must be used together.

export const httpClient = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
        ...DEFAULT_HEADER
    }
});

export const httpClientOnlyRefresh = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
        ...DEFAULT_HEADER
    }
});

export const uploadClient = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

export const downloadClient = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
        ...DEFAULT_HEADER
    },
    responseType: 'blob'
});
