<template>
    <div class="sel-loc-wrapper">
        <div class="sel-loc-header">
            <strong>현장선택</strong>
            <p>{{ basic.name }}님 현장을 선택하시고 COCO 협업에 참여하세요.</p>
        </div>

        <div class="sel-loc-body">
            <div class="form-box top">
                <div class="search-box">
                    <div class="search">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="현장명 검색"
                            @keyup="setFilterSite"
                        />
                    </div>
                </div>
            </div>
            <ul>
                <li v-for="(site, index) in filterSites" :key="index" @dblclick="selectSite()">
                    <input :id="'loc-' + index" v-model="selectId" type="radio" :value="site.id" />
                    <label :for="'loc-' + index">{{ site.name }}</label>
                </li>
            </ul>
        </div>
        <div class="btn-wrapper">
            <button type="button" @click="selectSite()">
                <span>현장 선택</span>
            </button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AffilationSiteApi from '../api/AffilationSiteApi';
import LoginApi from '../api/LoginApi';
import MutationTypes from '../store/MutationTypes';
import { JWT_TOKEN, JWT_REFRESH_TOKEN, WORKER_ID, CS_ID, CS_IDS } from '../const';
import ElectronHelper from '../helper/ElectronHelper';
//import { alertDialog } from '../helper/DialogHelper';
import AppSetting from '../api/AppSetting';
export default {
    name: 'SelectSite',
    components: {},
    data: () => {
        return { sites: [], selectId: null, csids: [], searchText: '', filterSites: [] };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        AffilationSiteApi.siteOfworker(this.basic.id).then(res => {
            this.filterSites = this.sites = res.map(item => item.site);
            this.csids = res.map(item => item.site.id);
        });
    },
    beforeDestroy() {},
    methods: {
        selectMsg() {
            alert('현장을 선택하세요');
        },
        async selectSite() {
            const session = {
                name: this.basic.name,
                postionName: this.basic.position,
                companyName: this.basic.company,
                id: this.basic.id,
                csId: this.selectId
            };

            const res = await LoginApi.selectSite(session);
            //오류나는건 추후 공통처리한다.
            if (res.content) {
                const basic = {
                    csId: session.csId,
                    name: session.name,
                    position: session.postionName,
                    company: session.companyName,
                    companyId: res.content.companyId,
                    id: session.id,
                    hp: res.content.hp,
                    site: res.content.siteName,
                    imgUrl: res.content.imgUrl,
                    csAdminYn: res.content.csAdminYn,
                    csCompanyAdminYn: res.content.csCompanyAdminYn
                };

                if (basic.id) {
                    //ElectronHelper.setupFcmToken(basic.id);
                    this.getSetting(basic.id);
                }

                this.$store.commit(MutationTypes.SET_MAIN_BASIC, basic);

                const oauth = res.content.token;
                this.$store.commit(MutationTypes.SET_OAUTH, oauth);
                window.localStorage.setItem(CS_ID, session.csId);
                window.localStorage.setItem(CS_IDS, this.csids);
                window.localStorage.setItem(WORKER_ID, session.id);
                window.localStorage.setItem(JWT_TOKEN, oauth.token);
                window.localStorage.setItem(JWT_REFRESH_TOKEN, oauth.refreshToken);
                //await this.doLastAccess();
                this.$router.push({ name: 'Main' });
            }
        },
        async getSetting(workerId) {
            const setting = await AppSetting.detail(workerId);
            ElectronHelper.setupAlarm(workerId, setting);
        },
        setFilterSite() {
            this.filterSites = this.sites.filter(site => site.name.indexOf(this.searchText) > -1);
        }
    }
};
</script>
