import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';
import authHeader from './Header';

const myList = () => httpClient.get(`/cstsite/mylist`, { headers: authHeader() }).then(res => res.data);
const mySite = () => httpClient.get(`/cstsite/mycsId`, { headers: authHeader() }).then(res => res.data);
const detail = id => httpClient.get(`/cstsite/${id}`, { headers: authHeader() }).then(res => res.data);

const list = () =>
    httpClient
        .get(`/cstsite`, { params: { finishYn: 'N', page: 1, rows: 1000, name: '' }, headers: authHeader() })
        .then(res => res.data);

export default {
    myList: wrapHttpClient(myList),
    detail: wrapHttpClient(detail),
    mySite: wrapHttpClient(mySite),
    list: wrapHttpClient(list)
};
