<template>
    <!-- modal : photo editor -->
    <div class="modal modal-photo-editor on">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-nav">
                        <a class="back"><i /><span class="blind">뒤로가기</span></a>
                    </div>
                    <div class="header-title">
                        <span>사진 편집</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="confirm" @click="getPictureBlob"><span>확인</span></a>
                    <span class="gap" />
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->

            <!-- modal-body -->
            <div class="modal-body">
                <div class="photo-editor-box">
                    <div class="img-box">
                        <tui-image-editor
                            ref="editor"
                            :include-ui="useDefaultUI"
                            :options="options"
                            @undoStackChanged="undoStackChanged"
                            @redoStackChanged="redoStackChanged"
                        />
                    </div>
                </div>

                <div class="photo-editor-util">
                    <div class="photo-editor-1">
                        <ul>
                            <li>
                                <a ref="btnUndo" class="undo" disabled @click="undo()">
                                    <i /><span class="blind">실행취소</span>
                                </a>
                            </li>
                            <li>
                                <a ref="btnRedo" class="redo" disabled @click="redo()">
                                    <i /><span class="blind">다시실행</span>
                                </a>
                            </li>
                            <li>
                                <a class="refresh" @click="rotate()"> <i /><span class="blind">로테이션</span> </a>
                            </li>
                            <li>
                                <a class="pen" @click="resetImageEditor()"> <i /><span class="blind">그리기</span> </a>
                            </li>
                            <li class="pen-stroke">
                                <span>펜 두께</span>
                                <div id="slider" class="rangebar" />
                            </li>
                        </ul>
                    </div>

                    <div class="photo-editor-2">
                        <ul>
                            <li>
                                <a class="color-1" :class="onColor('#ffffff')" @click="setBrushColor('#ffffff')">
                                    <span />
                                </a>
                            </li>
                            <li>
                                <a class="color-2" :class="onColor('#fcea1b')" @click="setBrushColor('#fcea1b')">
                                    <span />
                                </a>
                            </li>
                            <li>
                                <a class="color-3" :class="onColor('#f79700')" @click="setBrushColor('#f79700')">
                                    <span />
                                </a>
                            </li>
                            <li>
                                <a class="color-4" :class="onColor('#ea402f')" @click="setBrushColor('#ea402f')">
                                    <span />
                                </a>
                            </li>
                            <li>
                                <a class="color-5" :class="onColor('#9728b2')" @click="setBrushColor('#9728b2')">
                                    <span />
                                </a>
                            </li>
                            <li>
                                <a class="color-6" :class="onColor('#3e4aae')" @click="setBrushColor('#3e4aae')">
                                    <span />
                                </a>
                            </li>
                            <li>
                                <a class="color-7" :class="onColor('#3f97f6')" @click="setBrushColor('#3f97f6')">
                                    <span />
                                </a>
                            </li>
                            <li>
                                <a class="color-8" :class="onColor('#000000')" @click="setBrushColor('#000000')">
                                    <span />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- // modal-body -->
        </div>
    </div>
    <!-- // modal : photo editor -->
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ImageEditor } from '@toast-ui/vue-image-editor';

import FileApi from '../../api/FileApi';
require('webpack-jquery-ui/slider');
require('webpack-jquery-ui/css');
require('jquery-ui-touch-punch');

//import FileApi from '../../api/FileApi';
//import { PICTURE_SORCE_TYPE } from '../../const';
export default {
    name: 'PopImageEditor',
    components: { 'tui-image-editor': ImageEditor },
    data: () => {
        return {
            //workId: 0,
            //roomId: 0,
            color: '#000000',
            fileObj: {
                fileNo: null,
                name: '',
                type: 'blob',
                dataUrl: null,
                data: null
            },
            useDefaultUI: false,
            options: {
                // for tui-image-editor component's "options" prop
                usageStatistics: false,
                cssMaxWidth: 1200,
                cssMaxHeight: 1200
                //cssMaxWidth: document.documentElement.clientWidth,
                //cssMaxHeight: document.documentElement.clientHeight
            },
            MAX_RESOLUTION: 3264 * 2448 * 2,
            supportingFileAPI: !!(window.File && window.FileList && window.FileReader),
            rImageType: /data:(image\/.+);base64,/,
            shapeOpt: {
                fill: '#fff',
                stroke: '#000',
                strokeWidth: 30
            }
        };
    },
    computed: {
        ...mapState({
            work: state => state.work.picture
        })
    },
    created() {},
    mounted() {
        //if (this.$route.name === 'ImageEditor') this.roomId = Number(this.$route.params.roomId);
        //else this.workId = Number(this.$route.params.workId);
        this.resetImageEditor();
        //document.getElementById('camera').click();
        setTimeout(() => {
            $('.rangebar').slider({
                range: 'min',
                min: 1,
                max: 100,
                value: 10
            });

            $('.rangebar').on('slidestop', (event, ui) => {
                this.setBrushWidthRange(ui.value);
            });
        }, 200);
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['setPicture']),
        close() {
            //this.setShowPopManageRoom(false);
            //사진 에디터를 호출..
            this.$emit('close');
        },
        setUrlImage(url) {
            this.$refs.editor.invoke('loadImageFromURL', url, 'FilterImage');
        },
        async setDataUrlImage(file) {
            this.fileObj = file;
            if (file.type === 'server') {
                const res = await FileApi.urlTobase64(file.fileId, file.fileNo);
                this.$refs.editor.invoke('loadImageFromURL', 'data:image/jpeg;base64,' + res.content, 'FilterImage');
            } else this.$refs.editor.invoke('loadImageFromURL', file.dataUrl, 'FilterImage');
        },
        setFileImage(file) {
            //const file = this.$refs.uploadCamera.files[0];
            this.fileObj = file;
            this.$refs.editor.invoke('loadImageFromFile', file.data);
        },
        hexToRGBa(hex, alpha) {
            const r = parseInt(hex.slice(1, 3), 16);
            const g = parseInt(hex.slice(3, 5), 16);
            const b = parseInt(hex.slice(5, 7), 16);
            const a = alpha || 1;
            return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')';
        },
        base64ToBlob(data) {
            let mimeString = '';
            let raw, uInt8Array, i;

            raw = data.replace(this.rImageType, function(header, imageType) {
                mimeString = imageType;
                return '';
            });

            raw = atob(raw);
            const rawLength = raw.length;
            uInt8Array = new Uint8Array(rawLength); // eslint-disable-line
            for (i = 0; i < rawLength; i += 1) {
                uInt8Array[i] = raw.charCodeAt(i);
            }
            return new Blob([uInt8Array], { type: mimeString });
        },
        resetImageEditor() {
            const settings = this.getBrushSettings();
            this.$refs.editor.invoke('stopDrawingMode');
            this.$refs.editor.invoke('startDrawingMode', 'FREE_DRAWING', settings);
        },
        getBrushSettings() {
            const brushWidth = 10; //parseInt($inputBrushWidthRange.val(), 10);
            const brushColor = '#000000'; //brushColorpicker.getColor();

            return {
                width: brushWidth,
                color: this.hexToRGBa(brushColor, 0.5)
            };
        },
        setBrushWidthRange(value) {
            this.$refs.editor.invoke('setBrush', {
                width: parseInt(value, 10)
            });
        },
        setBrushColor(color) {
            this.color = color;
            this.$refs.editor.invoke('setBrush', {
                color: this.hexToRGBa(color, 0.5)
            });
        },
        undo() {
            this.$refs.editor.invoke('undo');
        },
        redo() {
            this.$refs.editor.invoke('redo');
        },
        undoStackChanged(length) {
            if (length > 1) {
                this.$refs.btnUndo.disabled = false;
            } else {
                this.$refs.btnUndo.disabled = true;
            }
        },
        redoStackChanged(length) {
            if (length) {
                this.$refs.btnRedo.disabled = false;
            } else {
                this.$refs.btnRedo.disabled = true;
            }
        },
        rotate() {
            this.$refs.editor.invoke('rotate', 90);
        },
        getPictureBlob() {
            //this.$store.dispatch('showLoading');
            let imageName = this.$refs.editor.invoke('getImageName');
            const dataURL = this.$refs.editor.invoke('toDataURL');
            const blob = this.base64ToBlob(dataURL);

            const type = blob.type.split('/')[1];
            if (imageName.split('.').pop() !== type) {
                const now = new Date();
                imageName = now.getTime() + '.' + type;
            }
            this.fileObj.name = imageName;
            this.fileObj.type = 'blob';
            this.fileObj.dataUrl = dataURL;
            this.fileObj.data = blob;
            this.$emit('close');
        },
        onColor(color) {
            if (this.color === color) return 'on';
            else return '';
        },
        encodeBase64ImageTagviaFileReader(url) {
            return new Promise(resolve => {
                const xhr = new XMLHttpRequest();
                xhr.onload = () => {
                    const reader = new FileReader();
                    reader.onloadend = function() {
                        resolve(reader.result);
                    };
                    reader.readAsDataURL(xhr.response);
                };
                xhr.open('GET', url);
                xhr.responseType = 'blob';
                xhr.send();
            });
        }
    }
};
</script>
