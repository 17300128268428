import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
    position: [],
    occupation: [],
    company: []
};

export default {
    state,
    getters,
    actions,
    mutations
    // plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : []
};
