import BaseModel from './BaseModel';
class WorkCondition extends BaseModel {
    constructor(json) {
        if (!json)
            json = {
                roomId: 0,
                finishYn: '',
                hideYn: '',
                direction: 'desc',
                sort: 'id',
                page: 1,
                rows: 20,
                content: '',
                searchText: '',
                //beginDay: '',
                //endDay: '',
                registerId: null,
                managerIds: [],
                tags: []
            };
        super(json, {});
    }

    get roomId() {
        return this.getPayload().roomId || 0;
    }

    set roomId(value) {
        this.getPayload().roomId = value;
    }

    get registerId() {
        return this.getPayload().registerId;
    }

    set registerId(value) {
        this.getPayload().registerId = value;
    }

    get type() {
        return this.getPayload().type;
    }

    set type(value) {
        this.getPayload().type = value;
    }

    get tags() {
        return this.getPayload().tags;
    }

    set tags(value) {
        //value가 array인지 구분?
        this.getPayload().tags = value;
    }

    get managerIds() {
        return this.getPayload().managerIds;
    }

    set managerIds(value) {
        //value가 array인지 구분?
        this.getPayload().managerIds = value;
    }

    get beginDay() {
        return this.getPayload().beginDay;
    }

    set beginDay(value) {
        //value가 array인지 구분?
        this.getPayload().beginDay = value;
    }

    get endDay() {
        return this.getPayload().endDay;
    }

    set endDay(value) {
        //value가 array인지 구분?
        this.getPayload().endDay = value;
    }

    get content() {
        return this.getPayload().content;
    }

    set content(value) {
        //value가 array인지 구분?
        this.getPayload().content = value;
    }

    get finishYn() {
        return this.getPayload().finishYn;
    }

    set finishYn(value) {
        this.getPayload().finishYn = value;
    }

    get page() {
        return this.getPayload().page;
    }

    set page(value) {
        this.getPayload().page = value;
    }

    get rows() {
        return this.getPayload().rows;
    }

    set rows(value) {
        this.getPayload().rows = value;
    }

    get searchText() {
        return this.getPayload().searchText;
    }

    set searchText(value) {
        this.getPayload().searchText = value;
    }

    reset() {
        this.payload = {
            finishYn: '',
            hideYn: '',
            direction: 'desc',
            sort: 'id',
            managerIds: [],
            tags: [],
            page: 1,
            rows: 20
        };
    }
}

export default WorkCondition;
