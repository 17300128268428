<template>
    <div class="modal modal-alert modal-leave-group on">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-title">
                        <span>그룹 나가기</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->
            <!-- modal-body -->
            <div class="modal-body">
                <p class="msg" v-html="$t('group_leave')" />
                <div class="btn-wrapper">
                    <a class="modal-close" @click="close"><span>취소</span></a>
                    <a class="modal-confirm" @click="remove"><span>확인</span></a>
                </div>
            </div>
            <!-- // modal-body -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
//import WorkRoomApi from '../../api/WorkRoomApi';
//import WorkRoomJoinApi from '../../api/WorkRoomJoinApi';

export default {
    name: 'PopLeaveRoom',
    components: {},
    props: {
        room: {
            type: Object,
            required: true
        }
    },
    data: () => {
        return {};
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        ...mapActions(['removeMyRooms', 'setShowPopLeaveRoom']),
        async remove() {
            /*  if (this.room.master.id === this.basic.id) {
                alert('방장을 이전해야 나가기가 가능합니다.');
                return;
            }
          */
            const param = {
                roomId: this.room.id,
                workerId: this.basic.id
            };
            await this.removeMyRooms(param);
            //this.$emit('remove', this.room.id);
            this.close();
        },
        close() {
            this.setShowPopLeaveRoom(false);
        }
    }
};
</script>
