<template>
    <div class="modal modal-width-fixed modal-add-member on">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-title">
                        <span>멤버 추가</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="confirm" @click="addWorker"><span>확인</span></a>
                    <span class="gap" />
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->

            <!-- modal-body -->
            <div class="modal-body">
                <div class="form-box">
                    <div class="search-box">
                        <div class="search">
                            <input
                                v-model="searchText"
                                type="text"
                                class="txt"
                                placeholder="이름,회사,직위 검색"
                                @keyup="filterWorker"
                            />
                        </div>
                    </div>
                </div>

                <div class="list-box">
                    <div class="select-box">
                        <input id="chk-add-member-all" type="checkbox" class="sel-user" @click="allSelect()" />
                        <label for="chk-add-member-all">전체선택</label>
                        <div class="select-util">
                            <ul>
                                <li>
                                    선택멤버 <span class="cnt">{{ workerIds.length }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="list-scroll-box">
                        <!-- user-list -->
                        <div class="user-list">
                            <ul>
                                <li v-for="(worker, index) in filterWorkers" :key="index">
                                    <input
                                        :id="'chk-add-member-' + index"
                                        v-model="workerIds"
                                        type="checkbox"
                                        class="sel-user"
                                        :value="worker.id"
                                    />
                                    <label class="user-summary" :for="'chk-add-member-' + index">
                                        <div class="user-thumb">
                                            <span class="thumb">
                                                <img
                                                    :src="worker.imgUrl | profileUrl"
                                                    onerror="javascript:this.src='/images/example/img-profile.png'"
                                                />
                                            </span>
                                        </div>
                                        <div class="user-info">
                                            <p class="user-nm">{{ worker.name }}</p>
                                            <div class="user-member">
                                                <div>
                                                    <span>{{ worker.companyName }}</span>
                                                    <span>{{ worker.regDt | formatTime }} 가입</span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <!-- // user-list -->
                    </div>
                    <div class="invite-msg-box">
                        <a @click="showInvitMember">
                            함께 일할 동료가 목록에 없으면 회원가입 및 그룹초대 문자를 보내세요! <span>문자보내기</span>
                        </a>
                    </div>
                </div>
            </div>
            <!-- // modal-body -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
//import ArrayHelper from '../../helper/ArrayHelper';

import WorkerApi from '../../api/WorkerApi';
import WorkRoomJoinApi from '../../api/WorkRoomJoinApi';

export default {
    name: 'PopAddWorker',
    components: {},
    props: {
        room: {
            type: Object,
            required: true
        }
    },
    data: () => {
        return {
            searchText: '',
            condition: {
                inCsId: 0,
                page: 1,
                rows: 1000
            },
            roomName: '',
            workers: [],
            filterWorkers: [],
            workerIds: [],
            isAll: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {
        this.condition.inCsId = this.basic.csId;
    },
    mounted() {
        this.getWorkers();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['setShowPopAddWorker', 'setShowPopManageRoom', 'getMyRooms', 'setShowPopSendHp']),
        close() {
            this.setShowPopAddWorker(false);
            this.setShowPopManageRoom(true);
        },
        filterWorker() {
            this.filterWorkers = this.workers.filter(
                work => work.name.indexOf(this.searchText) > -1 || work.companyName.indexOf(this.searchText) > -1
            );
        },
        async addWorker() {
            await WorkRoomJoinApi.wkroomjoin(this.room.id, { workers: this.workerIds });
            //await this.getMyRooms();
            this.$emit('refresh');
            this.close();
        },
        async getWorkers() {
            this.condition.notInRoomId = this.room.id;
            const res = await WorkerApi.list(this.condition);
            //console.log(this.basic.id);
            this.workers = res.content.map(item => {
                const worker = {};
                worker.id = item.id;
                worker.name = item.displayName;
                worker.positionName = item.positionName;
                worker.companyName = item.company.name;
                worker.hp = item.hp;
                worker.firstName = item.name.substring(0, 1);
                worker.imgUrl = item.imgUrl;
                worker.regDt = item.regDt;
                return worker;
            });
            //const condition = item => item.id === this.basic.id;
            //ArrayHelper.oneRemove(this.workers, condition);
            this.filterWorkers = this.workers;
        },
        allSelect() {
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.workerIds = this.filterWorkers.map(item => item.id);
            } else {
                this.workerIds = [];
            }
        },
        showInvitMember() {
            this.setShowPopSendHp(true);
        }
    }
};
</script>
