import 'core-js/es6/promise';
import '@babel/polyfill';
import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import VueI18n from 'vue-i18n';

import VueHtmlToPaper from 'vue-html-to-paper';

import App from './App';
import store from './store';
import router from './router';
import { getLanguage } from './utils/system';

import geti18nConfig from './plugin/filter/vue-i18n-config';
import VueImageFilter from './plugin/filter/vue-image-filter';
import VueDatetimeFilter from './plugin/filter/vue-datetime-filter';
import VueNumberFilter from './plugin/filter/vue-number-filter';
import VueStringFilter from './plugin/filter/vue-string-filter';
//import LogManager from './module/LogManager';

import gallery from 'img-vuer';

const printOptions = {
    name: '_blank',
    specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
    styles: ['/css/bootstrap.min.css']
};

// TODO: get User's language from config
const language = getLanguage();

///////////////////////
// Vue Plugin List
///////////////////////

Vue.use(VueImageFilter);
Vue.use(VueI18n);
Vue.use(VueDatetimeFilter, { locale: language });
Vue.use(VueNumberFilter);
Vue.use(VueStringFilter);
Vue.use(gallery, {
    swipeThreshold: 150, // default 100
    isIndexShow: true, // show image index, default true
    useCloseButton: true, // trigger gallery close with close button, default true
    preload: true // preload images in the same group, default true
});

Vue.use(VueHtmlToPaper, printOptions);

sync(store, router);

///////////////////////
// Initialize Vue Root App Component
///////////////////////
Vue.config.errorHandler = function(error) {
    console.log(error);
    store.dispatch('hideLoading');
    //LogManager.sendLog(error);
};

new Vue({
    el: '#app',
    i18n: geti18nConfig(language),
    store,
    router,
    render: h => h(App)
});
