<template>
    <div id="container">
        <div class="contents_box">
            <div class="contents">
                <!-- 결재창 -->
                <div class="in_box">
                    <table class="top">
                        <colgroup>
                            <col style="width:auto;" />
                            <col style="width:3%;" />
                            <col style="width:10%;" />
                            <col style="width:10%;" />
                            <col style="width:10%;" />
                            <col style="width:10%;" />
                        </colgroup>
                        <tr>
                            <th rowspan="2" class="title">
                                공사일보
                            </th>
                            <th rowspan="2" class="l-border">결<br />재</th>
                            <th>담당</th>
                            <th>팀장</th>
                            <th>공사</th>
                            <th>소장</th>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </table>

                    <!-- 기본정보 -->
                    <table class="basic">
                        <colgroup>
                            <col style="width:12.5%" />
                            <col style="width:12.5%" />
                            <col style="width:12.5%" />
                            <col style="width:10%" />
                            <col style="width:2.5%" />
                            <col style="width:12%" />
                            <col style="width:10%" />
                            <col style="width:2.5%" />
                            <col style="width:12%" />
                            <col style="width:10%" />
                            <col style="width:2.5%" />
                        </colgroup>
                        <tr>
                            <th>현장명</th>
                            <td id="siteName" colspan="4" class="center">
                                -
                            </td>
                            <th>일자</th>
                            <td id="todayInfo" colspan="5" class="center">
                                -
                            </td>
                        </tr>
                        <tr>
                            <th>날씨</th>
                            <td id="sky" class="center">
                                -
                            </td>
                            <th>최저기온</th>
                            <td id="tempMin" class="center bnone">
                                -
                            </td>
                            <td class="center">
                                ℃
                            </td>
                            <th>최고기온</th>
                            <td id="tempMax" class="center bnone">
                                -
                            </td>
                            <td class="center">
                                ℃
                            </td>
                            <th>강수량</th>
                            <td id="rainfall" class="center bnone">
                                -
                            </td>
                            <td class="center">
                                ㎜
                            </td>
                        </tr>
                    </table>
                </div>
                <!-- 결재창 //-->

                <!-- 작업사항 -->
                <div class="in_box">
                    <table id="table1">
                        <colgroup>
                            <col style="width:10%" />
                            <col style="width:12%" />
                            <col style="width:5%" />
                            <col style="width:34%" />
                            <col style="width:5%" />
                            <col style="width:34%" />
                        </colgroup>
                        <tr>
                            <th rowspan="2">
                                공종구분
                            </th>
                            <th rowspan="2">
                                협력업체
                            </th>
                            <th colspan="2">
                                금일작업현황
                            </th>
                            <th colspan="2">
                                명일작업현황
                            </th>
                        </tr>
                        <tr>
                            <th>인원</th>
                            <th>세부작업내용</th>
                            <th>인원</th>
                            <th>세부작업내용</th>
                        </tr>

                        <!-- 기본 내용-->
                        <tbody />
                        <!-- 기본 내용 //-->
                    </table>
                </div>
                <!-- inbox //-->
                <!-- 작업사항 //-->

                <!-- 출역현황 -->
                <div class="in_box half">
                    <table id="table2">
                        <colgroup>
                            <col style="width:16%" />
                            <col style="width:26%" />
                            <col style="width:26%" />
                            <col style="width:16%" />
                            <col style="width:16%" />
                        </colgroup>
                        <tr>
                            <th colspan="5">
                                출역현황
                            </th>
                        </tr>
                        <tr>
                            <th>공종</th>
                            <th>업체명</th>
                            <th>직종</th>
                            <th>금일</th>
                            <th>누계</th>
                        </tr>

                        <!-- 공종 rowspan="num" num = 해당 공종의 업체수 합 + 업체들의 직종수 합 (소계의 수가 업체수의 합),
							 업체명 rowspan="num" num = 업체들의 직종수 합 + 1 -->
                        <tbody />

                        <!-- 최종 합계 -->
                        <tr class="total">
                            <td class="center" colspan="3">
                                합계
                            </td>
                            <td class="right">
                                2
                            </td>
                            <td class="right">
                                4
                            </td>
                        </tr>
                    </table>
                </div>
                <!-- inbox half //-->
                <!-- 충력현황 //-->

                <!-- 장비현황 -->
                <div class="in_box half">
                    <table id="table3">
                        <colgroup>
                            <col style="width:45%" />
                            <col style="width:23%" />
                            <col style="width:16%" />
                            <col style="width:16%" />
                        </colgroup>
                        <tr>
                            <th colspan="4">
                                장비현황
                            </th>
                        </tr>
                        <tr>
                            <th>장비명</th>
                            <th>규격</th>
                            <th>금일</th>
                            <th>누계</th>
                        </tr>

                        <!-- 동일 장비명인 경우 규격에 따라 rowspan 수 지정-->
                        <tbody />
                    </table>
                </div>
                <!-- inbox half //-->
                <!-- 장비현황 //-->
            </div>
            <!-- contents //-->
        </div>
        <!-- contents_box //-->
    </div>
</template>

<script>
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import CodeApi from '../../api/CodeApi';
import WeatherApi from '../../api/WeatherApi';
import WorkDailyApi from '../../api/WorkDailyApi';
import { _ } from 'vue-underscore';
import { convertCrToBr } from '../../utils/string';

export default {
    name: 'ReportPrint',
    components: {},
    data: () => {
        return {
            notYetprintReport: true,
            processList: [],
            csId: null,
            today: null,
            ready1: false,
            ready2: false,
            ready3: false
        };
    },
    computed: {},
    created() {
        window.resizeTo(900, 800);
    },
    mounted() {
        $.fn.rowspan = function(colIdx, isStats) {
            return this.each(function() {
                var that;
                $('tr', this).each(function(row) {
                    console.log(row);
                    $('td', this)
                        .eq(colIdx)
                        .filter(':visible')
                        .each(function(col) {
                            console.log(col);
                            if (
                                $(this).html() === $(that).html() &&
                                (!isStats ||
                                    (isStats &&
                                        $(this)
                                            .prev()
                                            .html() ===
                                            $(that)
                                                .prev()
                                                .html()))
                            ) {
                                var rowspan = $(that).attr('rowspan');
                                if (!rowspan) rowspan = 1;
                                rowspan = Number(rowspan) + 1;

                                $(that).attr('rowspan', rowspan);

                                // do your action for the colspan cell here
                                $(this).hide();

                                //$(this).remove();
                                // do your action for the old cell here
                            } else {
                                that = this;
                            }

                            // set the that if not already set
                            that = that === null ? this : that;
                        });
                });
            });
        };

        this.csId = this.getUrlParameter('csId');
        this.today = this.getUrlParameter('today');

        CodeApi.list('0050').then(res => {
            this.processList = res.content;
        });
        this.makeInfoTable();
        this.make1Table();
        this.make2Table();
        this.make3Table();
    },
    beforeDestroy() {},
    methods: {
        printReport() {
            if (this.ready1 && this.ready2 && this.ready3 && this.notYetprintReport) {
                // if (true) {
                this.notYetprintReport = true;
                //print();

                var today = this.getUrlParameter('today');
                //var divHeight = $('#container').height();
                //var divWidth = $('#container').width();
                //var ratio = divHeight / divWidth;

                html2canvas($('#container')[0]).then(function(canvas) {
                    var doc = new jsPDF('p', 'mm', 'a4'); //jspdf객체 생성
                    var imgData = canvas.toDataURL('image/png'); //캔버스를 이미지로 변환

                    var margin = 2;
                    var imgWidth = 210 - 2 * margin;
                    var pageHeight = 295;
                    var imgHeight = (canvas.height * imgWidth) / canvas.width;
                    var heightLeft = imgHeight;

                    //var doc = new jsPDF('p', 'mm');
                    var position = 10;

                    doc.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);

                    heightLeft -= pageHeight;

                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
                        heightLeft -= pageHeight;
                    }

                    doc.save('작업일보' + today + '.pdf'); //pdf저장
                });
            }
        },
        async makeInfoTable() {
            const todayFormat = this.today.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
            const minMax = await WeatherApi.minMax(this.csId, todayFormat);

            $('#siteName').html(minMax.content.site.name);
            $('#tempMin').html(minMax.content.minVal);
            $('#tempMax').html(minMax.content.maxVal);
            $('#sky').html(minMax.content.sky);
            $('#rainfall').html(minMax.content.rainfall);

            var week = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
            var dayOfWeek = week[new Date(todayFormat).getDay()];
            $('#todayInfo').html(this.today + ' (' + dayOfWeek + ')');
        },
        async make1Table() {
            const that = this;
            const res = await WorkDailyApi.detailByToday(this.today);
            $('#table1 tr:gt(1)').remove();
            var html = '';
            if (res.content.length === 0) {
                html = '<tr><td class="center" colspan="6">조회 된 내용이 없습니다</td></tr>';
            } else {
                $.each(res.content, function(idx, data) {
                    html += that.makeTable1Tr(data);
                });
            }
            $('#table1').append(html);
            $('#table1').rowspan(0);
            this.ready1 = true;
            this.printReport();
        },
        async make2Table() {
            const res = await WorkDailyApi.listByOccupation(this.today);
            const tmp = this;
            $('#table2 tr:gt(1)').remove();
            var html = '';
            if (res.content.length === 0) {
                html = '<tr><td class="center" colspan="5">조회 된 내용이 없습니다</td></tr>';
            } else {
                var list = res.content;
                var subPutCount = 0;
                var subTotalCount = 0;

                var putCountTotal = 0;
                var totalCountTotal = 0;

                $.each(list, function(idx, data) {
                    html += tmp.makeTable2Tr(data);
                    subPutCount += data.putCount;
                    putCountTotal += data.putCount;
                    subTotalCount += data.putCount + data.preCountSum;
                    totalCountTotal += data.putCount + data.preCountSum;

                    if (idx !== 0) {
                        //처음/마지막 제외
                        if (idx !== list.length - 1) {
                            if (data.companyId !== list[idx + 1].companyId) {
                                //다음 업체랑 다른 경우
                                html += tmp.makeTable2SubTotalTr(
                                    data.wdMaster.processCode,
                                    data.wdMaster.company.name,
                                    subPutCount,
                                    subTotalCount
                                );
                                subPutCount = 0;
                                subTotalCount = 0;
                            }
                        } else {
                            html += tmp.makeTable2SubTotalTr(
                                data.wdMaster.processCode,
                                data.wdMaster.company.name,
                                subPutCount,
                                subTotalCount
                            );
                            html += tmp.makeTable2TotalTr(putCountTotal, totalCountTotal);

                            subPutCount = 0;
                            subTotalCount = 0;
                        }
                    }
                });
            }
            $('#table2').append(html);
            $('#table2').rowspan(0);
            $('#table2').rowspan(1);
            this.ready2 = true;
            this.printReport();
        },
        async make3Table() {
            const res = await WorkDailyApi.listByEquip(this.today);
            const that = this;

            $('#tab3Table tr:not(:first)').remove();

            var html = '';

            if (res.content.length === 0) {
                html = '<tr><td class="center" colspan="4">조회 된 내용이 없습니다</td></tr>';
            } else {
                $.each(res.content, function(idx, data) {
                    html += that.makeTable3Tr(data);
                });
            }
            $('#table3').append(html);
            $('#table3').rowspan(0);

            this.ready3 = true;
            this.printReport();
        },
        getUrlParameter(sParam) {
            var sPageURL = decodeURIComponent(window.location.search.substring(1)),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : sParameterName[1];
                }
            }
        },
        makeTable1Tr(row) {
            //console.log(row.processCode);
            //if(this.processList.length === 0 )

            var html = '';
            html +=
                '<tr><td class="center table1processName">' +
                _.where(this.processList, {
                    code: row.processCode
                })[0].name +
                '</td>';
            html += '<td>' + row.company.name + '</td>';
            html += '<td class="center">' + this.comma(row.todayMemberCnt) + '</td>';
            html += '<td>' + convertCrToBr(row.todayContent) + '</td>';
            html += '<td class="center">' + this.comma(row.yesterdayMemberCnt) + '</td>';
            html += '<td>' + convertCrToBr(row.yesterDay) + '</td></tr>';

            return html;
        },
        makeTable2Tr(row) {
            var html = '';
            html +=
                '<tr><td class="center table2processName" >' +
                _.where(this.processList, {
                    code: row.wdMaster.processCode
                })[0].name +
                '</td>';
            html += '<td class="table2companyName">' + row.wdMaster.company.name + '</td>';
            html += '<td>' + row.id.code.name + '</td>';
            html += '<td class="right">' + this.comma(row.putCount) + '</td>';
            html += '<td class="right">' + this.comma(Number(row.preCountSum) + Number(row.putCount)) + '</td></tr>';

            return html;
        },
        makeTable2SubTotalTr(processCode, companyName, putCount, totalCount) {
            var html = '';
            html +=
                '<tr class="subtotal"><td class="center table2processName" >' +
                _.where(this.processList, {
                    code: processCode
                })[0].name +
                '</td>';
            html += '<td class="table2companyName">' + companyName + '</td>';
            // html += '<td class="center">소계</td>';
            // html += '<td class="right">' + this.comma(putCount) + '</td>';
            // html += '<td class="right">' + this.comma(totalCount) + '</td></tr>';
            return html;
        },
        makeTable2TotalTr(putCount, totalCount) {
            var html = '';
            html += '<tr class="total">';
            html += '<td class="center" colspan="3">합계</td>';
            html += '<td class="right">' + this.comma(putCount) + '</td>';
            html += '<td class="right">' + this.comma(totalCount) + '</td>';
            html += '</tr>';
            return html;
        },
        makeTable3Tr(row) {
            var html = '';
            html += '<tr><td class="table3processName">' + row.id.code.name + '</td>';
            html += '<td>' + row.id.code.spec + '</td>';
            html += '<td class="right">' + this.comma(row.putCount) + '</td>';
            html += '<td class="right">' + this.comma(Number(row.preCountSum) + Number(row.putCount)) + '</td></tr>';

            return html;
        },
        comma(str) {
            if (!str) {
                return 0;
            }
            str = String(str);
            return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
    }
};
</script>
