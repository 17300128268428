<template>
    <div id="notice-list" class="content-side-wrapper">
        <!-- content-header -->
        <header class="content-header">
            <div class="header-box">
                <div class="header-title">
                    <span>알람</span>
                </div>
            </div>
            <div class="header-util">
                <a class="winmin modal-link" @click="defaultSize"> <i /><span class="blind">창축소</span> </a>
            </div>
        </header>
        <!-- // content-header -->
        <!-- notice-list-box -->
        <div class="notice-list-box">
            <p class="title">
                <span>전체</span>
                <span class="cnt">{{ alarms.total }}</span>
            </p>
            <!-- notice-list -->
            <div ref="listBox" class="notice-list alarm" @scroll="morePage">
                <ul>
                    <li v-for="(alarm, index) in alarms.content" :key="index">
                        <a class="notice-summary" @click="goLink(alarm)">
                            <div class="notice-info">
                                <p class="notice-subject">{{ alarm.msg }}</p>
                                <div class="notice-meta">
                                    <div>
                                        <span>
                                            {{ roomName(alarm) }}
                                        </span>
                                        <span>{{ alarm.lastDt | longFormatTime }}</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- // notice-list -->
        </div>
        <!-- // group-list-box -->
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AlarmApi from '../../api/AlarmApi';
import PageModel from '../../model/PageModel';
import ElectronHelper from '../../helper/ElectronHelper';

import MutationTypes from '../../store/MutationTypes';
import { JWT_TOKEN, JWT_REFRESH_TOKEN, WORKER_ID, CS_ID } from '../../const';
import LoginApi from '../../api/LoginApi';

export default {
    name: 'AlarmList',
    components: {},
    data: () => {
        return {
            alarms: new PageModel(),
            page: 1,
            lastId: null
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        this.alarms.page = this.page;
        this.doSearch();
    },
    beforeDestroy() {
        if (this.lastId) AlarmApi.check(this.lastId);
    },
    methods: {
        ...mapActions(['hideLoading']),
        async doSearch() {
            const query = {
                ...this.alarms.query()
            };
            const response = await AlarmApi.myAllList(query);
            if (response) {
                //this.boards = new PageModel(response);
                this.alarms.bind(response, true);
                //if (response.content.length > 0) AlarmApi.check(response.content[0].id);
                if (response.content.length > 0) this.lastId = response.content[0].id;
            }
            this.hideLoading();
        },
        morePage() {
            const box = this.$refs.listBox;
            if (box.scrollTop + box.clientHeight >= box.scrollHeight) {
                //this.condition.page = this.condition.page + 1;
                //console.log(this.alarms);
                if (this.alarms.isMore) {
                    this.alarms.page = this.page++;
                    //this.alarms.page = this.alarms.page + 1;
                    this.doSearch();
                }
            }
        },
        defaultSize() {
            ElectronHelper.windowDefaultSize();
        },
        roomName(alarm) {
            if (alarm.type === 'work') {
                if (alarm.room) return alarm.room.name;
                return alarm.name || '작업';
            } else if (alarm.type === 'notice') return '공지';
            else return '전체알람';
        },
        goLink(alarm) {
            //만약 다름.. csId가...

            if (alarm.csId !== this.basic.csId) {
                if (confirm('다른 현장의 알림입니다.\n현장을 변경하시겠습니까?')) {
                    this.changeSite(alarm);
                } else return;
            }
            if (alarm.type === 'notice') this.$router.push({ path: alarm.link });
            else this.$router.push({ path: alarm.link + '/' + alarm.room.id });
        },
        async changeSite(alarm) {
            const session = {
                name: this.basic.name,
                postionName: this.basic.position,
                companyName: this.basic.company,
                id: this.basic.id,
                csId: alarm.csId
            };

            const res = await LoginApi.selectSite(session);
            //오류나는건 추후 공통처리한다.
            if (res.content) {
                const basic = {
                    csId: session.csId,
                    name: session.name,
                    position: session.postionName,
                    company: session.companyName,
                    companyId: res.content.companyId,
                    id: session.id,
                    hp: res.content.hp,
                    site: res.content.siteName,
                    imgUrl: res.content.imgUrl,
                    csAdminYn: res.content.csAdminYn,
                    csCompanyAdminYn: res.content.csCompanyAdminYn
                };

                this.$store.commit(MutationTypes.SET_MAIN_BASIC, basic);

                const oauth = res.content.token;
                this.$store.commit(MutationTypes.SET_OAUTH, oauth);
                window.localStorage.setItem(CS_ID, session.csId);
                window.localStorage.setItem(WORKER_ID, session.id);
                window.localStorage.setItem(JWT_TOKEN, oauth.token);
                window.localStorage.setItem(JWT_REFRESH_TOKEN, oauth.refreshToken);
                //await this.doLastAccess();
                //this.$router.push({ path: alarm.link });
            }
        }
    }
};
</script>
