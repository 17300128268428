import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';

import authHeader from './Header';

const list = (id, query) =>
    httpClient.get(`/comment/listof/${id}`, { params: query, headers: authHeader() }).then(res => res.data);
const regist = param => httpClient.post(`/comment`, param, { headers: authHeader() }).then(res => res.data);

const remove = id => httpClient.delete(`/comment/${id}`, { headers: authHeader() }).then(res => res.data);

export default {
    list: wrapHttpClient(list),
    regist: wrapHttpClient(regist),
    remove: wrapHttpClient(remove)
};
