import getters from './getters';
import actions from './actions';
import mutations from './mutations';

/* model */
import WeatherModel from '../../../model/WeatherModel';
import CountModel from '../../../model/CountModel';

import { lastAccessTime } from '../../../utils/date';

const state = {
    oauth: {
        token: null,
        refreshToken: null,
        tempToken: null,
        csId: null,
        workId: null
    },
    basic: {
        site: '',
        csId: 0,
        name: '',
        position: '',
        company: '',
        companyId: 0,
        id: 0,
        hp: null,
        token: null,
        refreshToken: null,
        super: 'N',
        asteriskPhone: null,
        imgUrl: ''
    },
    weather: new WeatherModel({}),
    count: new CountModel({}),
    board: { notice: [], daily: [] },
    work: { my: [], todo: [] },
    setting: {},
    isMain: true,
    isLoading: false,
    lastAccess: lastAccessTime()
};

export default {
    state,
    getters,
    actions,
    mutations
    // plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : []
};
