<template>
    <div class="sel-loc-wrapper">
        <div class="sel-loc-header">
            <strong>비밀번호 찾기</strong>
            <p>초기화된 비밀번호가 문자 메시지로 발송됩니다.</p>
        </div>

        <div class="form-box">
            <div class="field-box">
                <div class="label">
                    휴대번호
                </div>
                <div class="field">
                    <input v-model="number" type="tel" class="txt" placeholder="- 없이 입력(ex. 01012345678)" />
                </div>
            </div>
            <div class="field-box">
                <div class="label">
                    성명
                </div>
                <div class="field">
                    <input v-model="name" class="txt" placeholder="성명을 입력하세요." />
                </div>
            </div>
            <div class="btn-wrapper-findpw">
                <button type="button" @click="doFindPasswd">
                    <span>비밀번호 찾기</span>
                </button>
            </div>
            <div class="btn-wrapper-login-gray">
                <button type="button" @click="goLogin">
                    <span>로그인 하기</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LoginApi from '../api/LoginApi';
import MenuHelper from '../helper/MenuHelper';

export default {
    name: 'FindPasswd',
    components: {},
    data: () => {
        return {
            number: '',
            name: ''
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        ...mapActions(['showLoading', 'hideLoading']),
        goLogin() {
            MenuHelper.login(this.$router);
        },
        async doFindPasswd() {
            try {
                this.showLoading();
                await LoginApi.findPasswd({
                    number: this.number,
                    name: this.name
                });
                this.hideLoading();
                alert('SMS로 임시 비밀번호를 발급하였습니다.');
                this.$router.push({ path: '/login' });
            } catch (error) {
                this.hideLoading();
                if (error.response) {
                    alert(error.response.data.msg);
                }
            }
        }
    }
};
</script>
