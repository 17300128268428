<template>
    <div id="content-wrap" :class="contentClass">
        <alarm-list ref="alarmList" />
        <alarm-none v-if="showType === 'none'" ref="alarmNone" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import AlarmList from './AlarmList';
import AlarmNone from './AlarmNone';

/* POPUP Layer관련 처리 */
export default {
    name: 'Notice',
    components: { AlarmList, AlarmNone },
    data: () => {
        return {
            contentClass: 'content-side-1',
            showType: 'none'
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {
        /* window resize체 대한 이벤트를 추가한다*/
        window.addEventListener('resize', this.onResize);
    },
    mounted() {
        //this.myInfo();
        this.onResize();
    },
    beforeDestroy() {
        /* window resize체 대한 이벤트를 삭제한다*/
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        ...mapActions([]),
        onResize() {
            const width = document.body.clientWidth;
            if (width > 800) {
                if (this.contentClass === 'content-side-1') {
                    this.contentClass = 'content-side-1';
                }
            } else {
                if (this.showType === 'none') this.contentClass = 'content-side-1';
            }
        },
        backContentClass() {
            if (this.contentClass === 'content-side-2') this.contentClass = 'content-side-1';
        },
        back() {
            this.showType = 'none';
        },
        refresh() {
            this.$refs.alarmList.resetSearch();
            this.showType = 'none';
        }
    }
};
</script>
