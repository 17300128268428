<template>
    <div ref="filterEquipsLayer" class="modal modal-width-fixed modal-select-item">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-nav">
                        <a class="back"><i /><span class="blind">뒤로가기</span></a>
                    </div>
                    <div class="header-title">
                        <span>장비 선택</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="confirm" @click="doConfirm"><span>확인</span></a>
                    <span class="gap" />
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->

            <!-- modal-body -->
            <div class="modal-body">
                <div class="search-box">
                    <div class="search">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="장비명 검색"
                            @keyup="setFilterEquip"
                        />
                    </div>
                </div>

                <div class="list-box">
                    <div class="select-box">
                        <input id="chk-add-item-all" type="checkbox" class="sel-tag" @click="allSelect()" />
                        <label for="chk-add-item-all">전체 장비선택</label>
                    </div>
                    <div class="list-scroll-box">
                        <!-- user-list -->
                        <div class="item-list">
                            <ul>
                                <li v-for="(equip, index) in filterEquips" :key="index">
                                    <input
                                        :id="'chk-add-item-' + index"
                                        v-model="selectEquips"
                                        type="checkbox"
                                        class="sel-item"
                                        :value="equip"
                                    />
                                    <label :for="'chk-add-item-' + index">
                                        {{ equip.id.code.name }}
                                        <span class="sm">{{ equip.id.code.spec }}</span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <!-- // user-list -->
                    </div>
                </div>
            </div>
            <!-- // modal-body -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
//import ArrayHelper from '../../helper/ArrayHelper';
import WorkDailyApi from '../../api/WorkDailyApi';

export default {
    name: 'PopEquip',
    components: {},
    props: {},
    data: () => {
        return {
            equips: [],
            filterEquips: [],
            selectEquips: [],
            searchText: '',
            isAll: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        //this.$emit('setEquips', this.selectEquips);
        //this.bind();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        close() {
            this.$refs.filterEquipsLayer.classList.remove('on');
            //this.tempTag = [];
        },
        setFilterEquip() {
            this.filterEquips = this.equips.filter(
                equip =>
                    equip.id.code.name.indexOf(this.searchText) > -1 || equip.id.code.spec.indexOf(this.searchText) > -1
            );
        },
        delSearchText() {
            this.searchText = '';
            this.setFilterEquip();
        },
        doConfirm() {
            //console.log(this.workerIds);
            this.$emit('setEquips', this.selectEquips);
            this.$refs.filterEquipsLayer.classList.remove('on');
            this.selectEquips = [];
        },
        async bind(today) {
            const response = (await WorkDailyApi.companyEquips(today)) || { content: [] };
            //console.log(response);
            this.equips = response.content; //.map(item => item);
            this.filterEquips = this.equips;
            console.log(this.filterEquips);
        },
        allSelect() {
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.selectEquips = this.filterEquips.map(item => item);
            } else {
                this.selectEquips = [];
            }
        },
        open() {
            this.$refs.filterEquipsLayer.classList.add('on');
        }
    }
};
</script>
