import store from '../store';

export const isAuth = regId => {
    return store.state.main.basic.id === regId || store.state.main.basic.super === 'Y';
};

export const isWorkDailyAuth = () => {
    return (
        store.state.main.basic.csAdminYn === 'Y' ||
        store.state.main.basic.csCompanyAdminYn === 'Y' ||
        store.state.main.basic.super === 'Y'
    );
};
