<template>
    <div class="modal modal-alert modal-invite-member on">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-title">
                        <span>회원가입 및 그룹초대</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->
            <!-- modal-body -->
            <div class="modal-body">
                <p class="msg">
                    그룹에서 함께 활동하고 싶은 동료에게<br />
                    COCO 회원가입 URL을 전송하세요.
                </p>
                <div class="form-box">
                    <div class="field-box">
                        <div class="field with-btn">
                            <input v-model="sendHp" type="number" class="txt" placeholder="-없이 입력" />
                            <a @click="inviteCoCo()"><span>문자전송</span></a>
                        </div>
                    </div>
                </div>
                <div class="btn-wrapper">
                    <a class="modal-close" @click="close"><span>취소</span></a>
                    <a class="modal-confirm" @click="close"><span>확인</span></a>
                </div>
            </div>
            <!-- // modal-body -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SiteCompanyApi from '../../api/SiteCompanyApi';

export default {
    name: 'PopInviteMember',
    components: {},
    data: () => {
        return {
            sendHp: ''
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        ...mapActions(['setShowPopSendHp', 'showLoading', 'hideLoading']),
        close() {
            this.setShowPopSendHp(false);
        },
        async inviteCoCo() {
            if (this.sendHp && confirm('문자를 전송하시겠습니까?')) {
                this.showLoading();
                const params = {
                    csId: this.basic.csId,
                    number: this.sendHp
                };
                const res = await SiteCompanyApi.sendSms(params);
                if (res) {
                    this.hideLoading();
                    alert('전송이 완료되었습니다.');
                    this.sendHp = '';
                    this.close();
                }
                this.hideLoading();
            }
        }
    }
};
</script>
