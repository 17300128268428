<template>
    <div id="content-wrap" class="content-side-1">
        <div class="content-side-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-title">
                        <span>사진함</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="photo-download" @click="downloadPhoto"> <i /><span class="blind">다운로드</span> </a>
                    <a class="winmin modal-link" @click="defaultSize"> <i /><span class="blind">창축소</span> </a>
                </div>
            </header>
            <!-- // content-header -->

            <!-- photo-box-wrapper -->
            <div class="photo-box-wrapper">
                <!-- photo-box-header -->
                <div class="photo-box-header">
                    <div class="photo-box-l">
                        <ul class="select-info">
                            <li>
                                <span class="selected">{{ selectImgs.length }}개 선택</span>
                            </li>
                            <li>
                                <a @click="allRelease">
                                    <span class="selected">선택삭제</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- // photo-box-header -->

                <div ref="listBox" class="photo-list" @scroll="morePage">
                    <template v-for="(val, name) in imgList">
                        <div>
                            <p class="date">
                                {{ name }}
                            </p>
                            <ul>
                                <li v-for="(img, index) in val" :key="index">
                                    <input v-model="selectImgs" type="checkbox" class="modal-link" :value="img" />
                                    <a class="modal-link">
                                        <img :src="img.imgUrl | thumbUrl" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </template>
                </div>
            </div>
            <!-- // photo-box-wrapper -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
//import Datepicker from 'vuejs-datepicker';
//import { ko } from 'vuejs-datepicker/dist/locale';
/* POPUP Layer관련 처리 */
//import PopGroup from './PopGroup';
//import PopRegister from './PopRegister';
//import PopCompany from './PopCompany';
//import PopPhotoDetail from './PopPhotoDetail';

//import { toDayFormatAddDay, toDayFormatAddMonth, toDayFormat } from '../../utils/date';

//import WorkApi from '../../api/WorkApi';
import FileApi from '../../api/FileApi';
import PictureRoomApi from '../../api/PictureRoomApi';
import ElectronHelper from '../../helper/ElectronHelper';

export default {
    name: 'PhotoBox',
    components: {
        //Datepicker,
        //PopGroup,
        //PopRegister,
        //PopCompany,
        //PopPhotoDetail
    },
    data: () => {
        return {
            imgList: [],
            selectImgs: [],
            condition: {
                page: 1,
                rows: 100
            },
            totalRows: 0,
            originList: []
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        //window.resizeTo(1000, 1000);
        ElectronHelper.windowExpand();

        document.addEventListener('mouseup', e => {
            if (
                $(e.target).closest('.select-list').length === 0 &&
                $(e.target).closest('.a.sel').length === 0 &&
                $(e.target).closest('.drp-calendar').length === 0
            ) {
                $('.select-list').removeClass('on');
            }
        });
        //this.condition.page = 1;
        this.doSearch(1);
    },
    beforeDestroy() {
        document.removeEventListener('mouseup', e => {
            if (
                $(e.target).closest('.select-list').length === 0 &&
                $(e.target).closest('.a.sel').length === 0 &&
                $(e.target).closest('.drp-calendar').length === 0
            ) {
                $('.select-list').removeClass('on');
            }
        });
    },
    methods: {
        ...mapActions(['showLoading', 'hideLoading']),
        callDate(date) {
            if (date) {
                date = new Date(date);
                return date.toISOString().substring(0, 10);
            }
        },
        setRoom(room) {
            if (room) {
                this.textGroup = room.name;
                this.condition.roomId = room.id;
            } else {
                this.textGroup = this.condition.roomId = null;
            }
            this.doSearch(1);
        },
        setCompany(company) {
            if (company) {
                this.textCompany = company.name;
                this.condition.companyId = company.id;
            } else {
                this.textCompany = this.condition.companyId = null;
            }
            this.doSearch(1);
        },
        setWorkers(ids) {
            this.condition.workerIds = ids.join(',').toString();
            this.doSearch(1);
        },
        async doSearch(page) {
            if (page > 0) this.condition.page = page;
            else this.condition.page = 1;
            this.imgList = [];
            this.selectImgs = [];
            const response = await PictureRoomApi.myList(this.condition);
            if (response) {
                if (this.condition.page > 1) this.originList.push(...response.content);
                else this.originList = response.content;

                //const temp = response.content;
                //this.originList = response.content;
                if (this.condition.page > 1)
                    this.imgList = {
                        ...this.imgList,
                        ...this.groupBy(this.originList, 'deviceYm')
                    };
                else this.imgList = this.groupBy(this.originList, 'deviceYm');
                if (response.pageable) this.totalRows = response.pageable.total || 0;
            }
        },
        async downloadPhoto() {
            if (this.selectImgs.length > 0) {
                this.showLoading();
                const param = this.selectImgs.map(item => {
                    return {
                        fileId: item.fileId,
                        fileNo: 1
                    };
                });
                await FileApi.downloadZip(param);
                this.hideLoading();
            }
        },
        allSelect() {
            this.isAll = !this.isAll;
        },
        async allRelease() {
            const param = this.selectImgs.map(item => item.id);
            await PictureRoomApi.removeIn({ list: param });
            this.doSearch(1);
        },
        morePage() {
            const box = this.$refs.listBox;
            if (box.scrollTop + box.clientHeight >= box.scrollHeight) {
                if (this.totalRows > this.originList.length) this.bind(this.condition.page + 1);
            }
        },
        defaultSize() {
            ElectronHelper.windowDefaultReSize();
        },
        groupBy(xs, key) {
            return xs.reduce(function(rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        }
    }
};
</script>
