<template>
    <div class="content-side-wrapper on">
        <div class="null-wrapper">
            <div class="null-box">
                <img src="/images/img-notice-none.png" alt="" />
                <p>알람을 선택하시면<br />해당 알림내용으로 이동합니다.</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

/* POPUP Layer관련 처리 */
export default {
    name: 'NoticeNone',
    components: {},
    data: () => {
        return {};
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        //this.myInfo();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([])
    }
};
</script>
