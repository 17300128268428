<template>
    <div class="modal modal-width-fixed modal-manage-group on">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-title">
                        <span>그룹 관리</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="confirm" @click="modifyRoom"><span>확인</span></a>
                    <span class="gap" />
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->
            <!-- modal-body -->
            <div class="modal-body">
                <div class="form-box">
                    <div class="field-box">
                        <div class="label">
                            그룹명
                        </div>
                        <div class="field">
                            <input
                                v-model="roomName"
                                type="text"
                                class="txt"
                                placeholder="최대 12자 이내"
                                maxlength="12"
                                :readonly="isReadOnly"
                            />
                        </div>
                    </div>
                    <div class="field-box">
                        <div class="label">
                            방장
                        </div>
                        <div class="field with-btn">
                            <input type="text" class="txt" readonly :value="room.master.displayName" />
                            <a v-if="room.master.id === basic.id" @click="setRoomMaster">
                                <span>방장선택</span>
                            </a>
                        </div>
                    </div>
                    <div class="search-box">
                        <div class="search">
                            <input
                                v-model="searchText"
                                type="text"
                                class="txt"
                                placeholder="이름,회사,직위 검색"
                                @keyup="filterWorker"
                            />
                        </div>
                    </div>
                </div>

                <div class="list-box">
                    <div class="select-box">
                        <input id="chk-manage-group-all" type="checkbox" class="sel-user" @click="allSelect()" />
                        <label for="chk-manage-group-all">전체선택</label>

                        <div class="select-util">
                            <ul>
                                <li><a @click="clickRemove">선택삭제</a></li>
                                <li><a @click="clickAdd">멤버추가</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="list-scroll-box">
                        <!-- user-list -->
                        <div class="user-list">
                            <ul>
                                <li v-for="(worker, index) in filterWorkers" :key="index">
                                    <input
                                        v-if="masterId !== worker.id"
                                        :id="'chk-manage-group-' + index"
                                        v-model="workerIds"
                                        type="checkbox"
                                        class="sel-user"
                                        :value="worker.id"
                                    />
                                    <label class="user-summary" :for="'chk-manage-group-' + index">
                                        <div class="user-thumb">
                                            <span class="thumb">
                                                <img
                                                    :src="worker.imgUrl | profileUrl"
                                                    onerror="javascript:this.src='/images/example/img-profile.png'"
                                                />
                                            </span>
                                        </div>
                                        <div class="user-info">
                                            <p class="user-nm">{{ worker.name }}</p>
                                            <div class="user-member">
                                                <div>
                                                    <span>{{ worker.companyName }}</span>
                                                    <span>{{ worker.regDt | formatTime }} 가입</span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <!-- // user-list -->
                    </div>
                    <div class="invite-msg-box">
                        <a @click="showInvitMember">
                            함께 일할 동료가 목록에 없으면 회원가입 및 그룹초대 문자를 보내세요! <span>문자보내기</span>
                        </a>
                    </div>
                </div>
            </div>
            <!-- // modal-body -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ArrayHelper from '../../helper/ArrayHelper';

import WorkRoomApi from '../../api/WorkRoomApi';
import WorkerApi from '../../api/WorkerApi';
import WorkRoomJoinApi from '../../api/WorkRoomJoinApi';

export default {
    name: 'PopManageRoom',
    components: {},
    props: {
        room: {
            type: Object,
            required: true
        }
    },
    data: () => {
        return {
            searchText: '',
            condition: {
                inCsId: 0,
                page: 1,
                rows: 1000
            },
            roomName: '',
            workers: [],
            filterWorkers: [],
            workerIds: [],
            isAll: false,
            isReadOnly: true,
            masterId: null
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {
        this.condition.inCsId = this.basic.csId;
    },
    mounted() {
        this.getWorkers();
        this.roomName = this.room.name;
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['removeMyRooms', 'setShowPopManageRoom', 'setShowPopAddWorker', 'setShowPopSendHp']),
        close() {
            this.setShowPopManageRoom(false);
        },
        filterWorker() {
            this.filterWorkers = this.workers.filter(
                work => work.name.indexOf(this.searchText) > -1 || work.companyName.indexOf(this.searchText) > -1
            );
        },
        async setRoomMaster() {
            //this.$emit('changeOwner');
            if (this.room.master.id === this.basic.id) {
                this.$emit('changeOwner');
            }
        },
        async modifyRoom() {
            if (this.roomName) {
                if (this.isReadOnly) {
                    this.$emit('refresh');
                    this.close();
                    return;
                }
                this.room.name = this.roomName;
                const res = await WorkRoomApi.modify(this.room.id, this.room);
                if (res) {
                    this.$emit('refresh');
                    this.close();
                    //MenuHelper.room(this.$router, res.id);
                }
            } else {
                alert('그룹명을 입력하세요.');
            }
        },
        async getWorkers() {
            this.isReadOnly = !(this.room.master.id === this.basic.id);

            this.masterId = this.room.master.id;

            const res = await WorkerApi.listOfroom(this.room.id);
            const condition = item => item.id === this.basic.id;
            ArrayHelper.oneRemove(res, condition);

            this.workers = res.map(item => {
                const worker = {};
                worker.id = item.id;
                worker.name = item.displayName;
                worker.positionName = item.positionName;
                worker.companyName = item.company.name;
                worker.hp = item.hp;
                worker.firstName = item.name.substring(0, 1);
                worker.imgUrl = item.imgUrl;
                worker.regDt = item.regDt;
                return worker;
            });

            this.filterWorkers = this.workers;
        },
        allSelect() {
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.workerIds = this.filterWorkers.map(item => item.id);
            } else {
                this.workerIds = [];
            }
        },
        async clickRemove() {
            if (this.workerIds.length > 0 && confirm('선택삭제 하시겠습니까?')) {
                const res = await WorkRoomJoinApi.removeInWorkers(this.room.id, { workers: this.workerIds });
                if (res) {
                    for (var workerId of this.workerIds) {
                        const condition = item => item.id === workerId;
                        ArrayHelper.oneRemove(this.workers, condition);
                    }
                    this.$emit('refresh');
                }
            }
        },
        clickAdd() {
            this.setShowPopManageRoom(false);
            this.setShowPopAddWorker(true);
        },
        showInvitMember() {
            this.setShowPopSendHp(true);
        }
    }
};
</script>
