<template>
    <div class="modal modal-width-fixed modal-select-group on">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-nav">
                        <a class="back"><i /><span class="blind">뒤로가기</span></a>
                    </div>
                    <div class="header-title">
                        <span>업체 선택</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="confirm" @click="setCompany"><span>확인</span></a>
                    <span class="gap" />
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->

            <!-- modal-body -->
            <div class="modal-body">
                <div class="search-box">
                    <div class="search">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="업체명 검색"
                            @keyup="setFilterCompanys"
                        />
                    </div>
                </div>

                <div class="list-box">
                    <div class="select-box">
                        <input id="chk-add-comapny-all" v-model="item" type="radio" class="group-nm" />
                        <label for="chk-add-comapny-all">전체 업체</label>
                    </div>
                    <div class="list-scroll-box">
                        <!-- user-list -->
                        <div class="group-list">
                            <ul>
                                <li v-for="(company, index) in filterCompanys" :key="index">
                                    <input
                                        :id="'chk-add-company-' + index"
                                        v-model="item"
                                        type="radio"
                                        class="group-nm"
                                        :value="company"
                                    />
                                    <label :for="'chk-add-company-' + index">
                                        {{ company.name }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <!-- // user-list -->
                    </div>
                </div>
            </div>
            <!-- // modal-body -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
//import ArrayHelper from '../../helper/ArrayHelper';

import SiteCompanyApi from '../../api/SiteCompanyApi';

export default {
    name: 'PopCompany',
    components: {},
    data: () => {
        return {
            searchText: '',
            condition: {
                csId: 0
            },
            companys: [],
            filterCompanys: [],
            item: [],
            isAll: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {
        this.condition.csId = this.basic.csId;
    },
    mounted() {
        this.getCompany();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['getMyRooms']),
        close() {
            this.$emit('update:show', false);
        },
        async getCompany() {
            //현장관리자면 전체..code 다 가져오기
            if (this.basic.csAdminYn === 'Y') {
                const res = await SiteCompanyApi.list(this.basic.csId);
                if (res) {
                    this.companys = res.content.map(item => {
                        return { name: item.company.name, id: item.company.id };
                    });
                }
            } else {
                this.companys.push({ name: this.basic.company, id: this.basic.companyId });
            }
            this.filterCompanys = this.companys;
        },
        setFilterCompanys() {
            this.filterCompanys = this.companys.filter(item => item.name.indexOf(this.searchText) > -1);
        },
        setCompany() {
            this.$emit('setData', this.item);
            this.close();
        }
    }
};
</script>
