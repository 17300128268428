import { httpClient } from '../network/httpclient/HttpClientFactory';
import authHeader from './Header';
import reAuthHeader from './ReHeader';

const login = param => httpClient.post(`/login`, param).then(res => res.data);
const refreshToken = param => httpClient.post(`refreshToken`, param, { headers: reAuthHeader() }).then(res => res.data);
const selectSite = param => httpClient.post(`/selectSite`, param, { headers: authHeader() }).then(res => res.data);
const findPasswd = param => httpClient.post(`/findPasswd`, param, { headers: authHeader() }).then(res => res.data);

const checkAuthNo = param =>
    httpClient.post(`/auth/checkAuthNo`, param, { headers: authHeader() }).then(res => res.data);
const sendAuthNoUsingSms = param =>
    httpClient.post(`/auth/sendAuthNoUsingSms`, param, { headers: authHeader() }).then(res => res.data);
const checkJoinNo = param =>
    httpClient.post(`/auth/checkJoinNo`, param, { headers: authHeader() }).then(res => res.data);

export default {
    login,
    refreshToken, //rapping 하지 않는다.
    selectSite,
    findPasswd,
    checkAuthNo,
    sendAuthNoUsingSms,
    checkJoinNo
};
