<template>
    <div id="content-wrap" :class="contentClass">
        <div class="content-side-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-title">
                        <span>설정</span>
                    </div>
                </div>
            </header>
            <!-- // content-header -->
            <!-- setting-main-wrapper -->
            <div class="setting-main-wrapper">
                <div class="profile-box">
                    <div class="profile-pic">
                        <span>
                            <img :src="basic.imgUrl | profileUrl" onerror="javascript:this.src='/images/default.png'" />
                        </span>
                        <label for="upload-profile" class="profile-camera" @click="editImage">
                            <i /><span class="blind">사진변경</span>
                        </label>
                    </div>

                    <div class="profile-info">
                        <p>{{ basic.site }}</p>
                        <p>{{ basic.name }} {{ basic.position }}&middot;{{ basic.company }}</p>
                        <p>{{ basic.asteriskPhone }}</p>
                    </div>
                    <ul class="setting-nav">
                        <li><a class="modal-link" @click="() => (showSite = true)">현장변경</a></li>
                        <li><a @click="showMyInfo">내 정보 수정</a></li>
                        <li class="noarrow">
                            <a class="noarrow">
                                <div class="setting-item">
                                    <p>업무처리 알림 설정</p>
                                </div>
                                <div class="action">
                                    <div class="switch">
                                        <input
                                            id="chk-1-2"
                                            v-model="appsetting.workYn"
                                            type="checkbox"
                                            class="blind"
                                            @change="saveSetting()"
                                        />
                                        <label for="chk-1-2"><span class="blind">On</span></label>
                                        <div class="radius-select" />
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="noarrow">
                            <a class="noarrow">
                                <div class="setting-item">
                                    <p>공지 알림 설정</p>
                                </div>
                                <div class="action">
                                    <div class="switch">
                                        <input
                                            id="chk-1-3"
                                            v-model="appsetting.noticeYn"
                                            type="checkbox"
                                            class="blind"
                                            @change="saveSetting()"
                                        />
                                        <label for="chk-1-3"><span class="blind">On</span></label>
                                        <div class="radius-select" />
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li v-show="false" class="noarrow">
                            <a class="noarrow">
                                <div class="setting-item">
                                    <p>알림소리 설정</p>
                                </div>
                                <div class="action">
                                    <div class="switch">
                                        <input
                                            id="chk-1-4"
                                            v-model="appsetting.soundYn"
                                            type="checkbox"
                                            class="blind"
                                            @change="saveSetting()"
                                        />
                                        <label for="chk-1-4"><span class="blind">On</span></label>
                                        <div class="radius-select" />
                                    </div>
                                </div>
                            </a>
                        </li>

                        <li><a @click="openPictureRoom">사진함 보기</a></li>
                        <li><a @click="showInvitMember">초대문자 보내기</a></li>
                        <li><a @click="policy">이용약관</a></li>
                        <li><a @click="privacy">개인정보방침</a></li>
                        <li>
                            <a @click="siteOut">[{{ basic.site }}] 현장탈퇴</a>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- // setting-main-wrapper -->
        </div>
        <privacy v-if="showType === 'showPrivacy'" @back="backContentClass" />
        <policy v-else-if="showType === 'showPolicy'" @back="backContentClass" />
        <div v-else-if="showType === 'showModify'" class="content-side-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-nav">
                        <a class="back" @click="backContentClass"> <i /><span class="blind">뒤로가기</span> </a>
                    </div>
                    <div class="header-title">
                        <span>내 정보 수정</span>
                    </div>
                </div>
            </header>
            <!-- // content-header -->
            <!-- modify-info-wrapper -->
            <div class="modify-info-wrapper">
                <p class="modify-title">
                    개인정보 및 비밀번호를 관리하세요.
                </p>

                <div class="form-box">
                    <div class="field-box">
                        <div class="label">
                            휴대번호ID
                        </div>
                        <div class="field">
                            <input v-model="worker.asteriskPhone" type="text" class="txt blue" readonly />
                        </div>
                    </div>

                    <div class="field-box">
                        <div class="label">
                            비밀번호
                        </div>
                        <div class="field with-btn">
                            <input type="password" class="txt" readonly value="1234" />
                            <a class="modal-link" @click="() => (showPasswd = true)"><span>변경하기</span></a>
                        </div>
                    </div>

                    <div class="field-box">
                        <div class="label">
                            성명
                        </div>
                        <div class="field">
                            <input v-model="worker.name" type="text" class="txt" readonly value="홍길동" />
                        </div>
                    </div>

                    <div class="field-box">
                        <div class="label">
                            소속업체
                        </div>
                        <div class="field">
                            <input v-model="worker.company.name" type="text" class="txt" readonly />
                        </div>
                    </div>

                    <div class="field-box">
                        <div class="label">
                            직위
                        </div>
                        <div class="field">
                            <select v-model="worker.positionCode">
                                <option value="">
                                    선택
                                </option>
                                <option v-for="(code, index) in position" :key="index" :value="code.code">
                                    {{ code.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="field-box">
                        <div class="label">
                            직종
                        </div>
                        <div class="field">
                            <select v-model="worker.occupationsCode" @change="onChageOccupation">
                                <option value="">
                                    선택
                                </option>
                                <option v-for="(code, index) in occupation" :key="index" :value="code.code">
                                    {{ code.name }}
                                </option>
                                <option value="etc">
                                    기타 직접 입력
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="field-box">
                        <div class="label" />
                        <div class="field">
                            <input
                                v-if="isEtc || worker.occupationsEtc"
                                v-model="worker.occupationsEtc"
                                type="text"
                                class="txt"
                            />
                        </div>
                    </div>

                    <div class="btn-box">
                        <a class="cancel" @click="backContentClass"><span>취소</span></a>
                        <a class="confirm" @click="modify"><span>확인</span></a>
                    </div>
                </div>
            </div>
            <!-- // modify-info-wrapper -->
        </div>
        <div v-else class="content-side-wrapper">
            <div class="null-wrapper">
                <div class="null-box">
                    <img src="/images/icon-setting-intro.png" alt="" />
                    <p>접속현장과 개인정보를 관리하세요.</p>
                </div>
            </div>
        </div>
        <passwd v-if="showPasswd" :show.sync="showPasswd" />
        <pop-site v-if="showSite" :show.sync="showSite" />
        <pop-invite-member v-if="showPopSendHp" />
        <pop-picture-room v-if="showPictureRoom" @close="closePictureRoom" @setImgs="closePictureRoom" />

        <input id="gallery" ref="uploadGallery" type="file" class="blind" accept="image/*" @change="doGalleryAttach" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import MutationTypes from '../../store/MutationTypes';

//import FileHelper from '../../helper/FileHelper';

import WorkerApi from '../../api/WorkerApi';
import FileApi from '../../api/FileApi';

import Passwd from './Passwd';
import PopSite from '../popup/PopSite';
import PopInviteMember from '../popup/PopInviteMember';
import PopPictureRoom from '../popup/PopPictureRoom';

import AppSetting from '../../api/AppSetting';

import { WORKER_ID } from '../../const';

import ElectronHelper from '../../helper/ElectronHelper';

//import { confirmDialog } from '../../helper/DialogHelper';

import Policy from './Policy.vue';
import Privacy from './Privacy.vue';

/* POPUP Layer관련 처리 */
export default {
    name: 'Setting',
    components: { Passwd, PopSite, PopInviteMember, PopPictureRoom, Policy, Privacy },
    data: () => {
        return {
            showPasswd: false,
            showType: '',
            showModify: false,
            showSite: false,
            showPrivacy: false,
            worker: {
                company: { id: 0 },
                positionCode: '',
                occupationsCode: '',
                fileId: ''
            },
            isEtc: false,
            img: '',
            contentClass: 'content-side-1',
            showPictureRoom: false,
            appsetting: {
                autoYn: true,
                workYn: true,
                noticeYn: true,
                soundYn: false
            }
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic,
            position: state => state.code.position,
            occupation: state => state.code.occupation,
            company: state => state.code.company,
            showPopSendHp: state => state.work.showPopSendHp
        })
    },
    created() {
        /* window resize체 대한 이벤트를 추가한다*/
        window.addEventListener('resize', this.onResize);
    },
    mounted() {
        this.onResize();
        this.getPositionCodes();
        this.getOccupationCodes();
        this.getCompany();

        this.img = this.basic.imgUrl;
        this.bind();
        //this.myInfo();
    },
    beforeDestroy() {
        /* window resize체 대한 이벤트를 삭제한다*/
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        ...mapActions([
            'getPositionCodes',
            'getOccupationCodes',
            'getCompany',
            'setShowType',
            'setShowPopSendHp',
            'logout'
        ]),
        onResize() {
            const width = document.body.clientWidth;
            if (width > 800) {
                if (this.contentClass === 'content-side-1') this.contentClass = 'content-side-2';
            }
        },
        backContentClass() {
            if (this.contentClass === 'content-side-2') this.contentClass = 'content-side-1';
        },
        onChageOccupation() {
            if (this.worker.occupationsCode === 'etc') {
                //this.worker.occupationsCode = null;
                this.isEtc = true;
            } else {
                this.isEtc = false;
            }
        },
        async myInfo() {
            this.worker = await WorkerApi.detail(this.basic.id);
            if (!this.worker.occupationsCode) this.worker.occupationsCode = '';
        },
        async modify() {
            //if (this.worker.occupationsCode === 'etc') this.worker.occupationsCode = null;
            const response = await WorkerApi.modify(this.basic.id, this.worker);
            if (response) {
                alert('수정하였습나다');
                //this.worker.occupationsCode = 'etc';
                const basic = {
                    csId: this.basic.csId,
                    name: response.name,
                    position: response.positionName,
                    company: response.company.name,
                    companyId: response.compnay.id,
                    id: response.id,
                    hp: response.hp,
                    site: this.basic.name,
                    asteriskPhone: response.asteriskPhone,
                    imgUrl: response.imgUrl
                };
                this.$store.commit(MutationTypes.SET_MAIN_BASIC, basic);
            }
        },
        showMyInfo() {
            if (this.contentClass === 'content-side-1') this.contentClass = 'content-side-2';
            //this.showModify = true;
            this.showType = 'showModify';
            this.myInfo();
        },
        editImage() {
            this.$refs.uploadGallery.value = '';
            this.$refs.uploadGallery.click();
        },
        async doGalleryAttach() {
            const fileObject = this.$refs.uploadGallery.files[0];
            const fileName = fileObject.name;
            if (fileObject) {
                this.worker = await WorkerApi.detail(this.basic.id);
                const formData = new FormData();
                formData.append('file_id', this.worker.fileId > 0 ? this.worker.fileId : '');
                formData.append('file_no', 1);
                formData.append('file_name', fileName);
                formData.append('file', fileObject);
                const res = await FileApi.imgUpload(formData);

                this.worker.fileId = res.content.fileId;
                const response = await WorkerApi.modify(this.worker.id, this.worker);
                if (response) {
                    const basic = {
                        csId: this.basic.csId,
                        name: response.name,
                        position: response.positionName,
                        company: response.company.name,
                        companyId: response.company.id,
                        id: response.id,
                        hp: response.hp,
                        site: this.basic.name,
                        asteriskPhone: response.asteriskPhone,
                        imgUrl: response.imgUrl
                    };
                    this.$store.commit(MutationTypes.SET_MAIN_BASIC, basic);
                }
            }
        },
        showInvitMember() {
            this.setShowPopSendHp(true);
        },
        goLogout() {
            //화면사이즈를 줄이고 창을 닫자
            this.logout().then(() => {
                this.$router.push({ path: '/login' });
            });
        },
        openPictureRoom() {
            this.showPictureRoom = true;
        },
        closePictureRoom() {
            this.showPictureRoom = false;
        },
        async bind() {
            const setting = await AppSetting.detail(this.basic.id || localStorage.getItem(WORKER_ID));
            this.appsetting = {
                autoYn: setting.autoYn === 'Y' ? true : false,
                workYn: setting.workYn === 'Y' ? true : false,
                noticeYn: setting.noticeYn === 'Y' ? true : false,
                soundYn: setting.soundYn === 'Y' ? true : false
            };
        },
        async saveSetting() {
            const setting = {
                worker: this.basic.id,
                autoYn: this.appsetting.autoYn ? 'Y' : 'N',
                workYn: this.appsetting.workYn ? 'Y' : 'N',
                noticeYn: this.appsetting.noticeYn ? 'Y' : 'N',
                soundYn: this.appsetting.soundYn ? 'Y' : 'N'
            };

            await AppSetting.upsert(setting);
            ElectronHelper.setupAlarm(this.basic.id, setting);
        },
        policy() {
            if (this.contentClass === 'content-side-1') this.contentClass = 'content-side-2';
            this.showType = 'showPolicy';
        },
        privacy() {
            if (this.contentClass === 'content-side-1') this.contentClass = 'content-side-2';
            this.showType = 'showPrivacy';
            //this.showPrivacy = true;
        },
        async siteOut() {
            if (confirm(this.basic.site + '을 탈퇴 하시겠습니까?')) {
                const res = await WorkerApi.siteLeave(this.basic.id);
                if (res.content) this.goLogout();
                //this.$router.push({ path: '/login' });
            }
        }
    }
};
</script>
