<template>
    <div class="site-wrapper">
        <div class="site-box-left">
            <div class="site-search-box">
                <div class="search-box">
                    <div class="search">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="업체명 검색"
                            @keyup="setFilterCompanys"
                        />
                    </div>
                </div>
                <div class="btn-box">
                    <a class="delete" @click="removeCompany"><i /><span>선택삭제</span></a>
                </div>
            </div>

            <div class="site-list-box">
                <div class="site-list-header">
                    <ul>
                        <li>
                            <span>
                                <input type="checkbox" @click="allSelect()" />
                            </span>
                            <span>No</span>
                            <span>업체명</span>
                            <span>대표/담당자</span>
                        </li>
                    </ul>
                </div>

                <div class="site-list-body">
                    <ul>
                        <li
                            v-for="(company, index) in filterCompanys"
                            :key="index"
                            :class="{ on: selCompany.id === company.company.id }"
                            @click="setCompany(company.company)"
                        >
                            <span>
                                <input v-model="selIds" type="checkbox" :value="company.company.id" />
                            </span>
                            <span>{{ index + 1 }}</span>
                            <span>{{ company.company.name }}</span>
                            <span>{{ company.company.represention }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="site-box-right">
            <div class="reg-info-header">
                <span>업체 등록 및 수정</span>
                <div class="btn-box">
                    <a class="reg-company" @click="() => (showCompany = true)"><i /><span>업체추가</span></a>
                    <a class="add-new" @click="clickCancel"><i /><span>신규등록</span></a>
                </div>
            </div>
            <div class="reg-info-box">
                <div class="form-box">
                    <div class="field-box">
                        <div class="label">
                            업체명
                        </div>
                        <div class="field">
                            <input v-model="selCompany.name" type="text" class="txt" />
                        </div>
                    </div>

                    <div class="field-box">
                        <div class="label">
                            대표/담당자
                        </div>
                        <div class="field">
                            <input v-model="selCompany.represention" type="text" class="txt" />
                        </div>
                    </div>

                    <div class="field-box">
                        <div class="label">
                            연락처
                        </div>
                        <div class="field">
                            <input v-model="selCompany.contractNo" type="text" class="txt" />
                        </div>
                    </div>

                    <div class="field-box">
                        <div class="label">
                            공정코드
                        </div>
                        <div class="field">
                            <div class="select-box">
                                <a class="sel modal-link" @click="onProcessCode">
                                    <span>{{ selCompany.processName || '전체' }} 공정</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="btn-box">
                        <a class="cancel" @click="clickCancel"><span>취소</span></a>
                        <a class="confirm" @click="clickConfirm">
                            <span>{{ confirmText }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <pop-process-code v-if="showCode" refs="processCode" :show.sync="showCode" @setData="setCode" />
        <pop-company v-if="showCompany" :show.sync="showCompany" :exist-data="companys" @setData="regCompany" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
//import ArrayHelper from '../../helper/ArrayHelper';

import PopProcessCode from './PopProcessCode';
import PopCompany from './PopCompany';

import SiteCompanyApi from '../../api/SiteCompanyApi';
import CompanyApi from '../../api/CompanyApi';

export default {
    name: 'SiteCompany',
    components: { PopProcessCode, PopCompany },
    data: () => {
        return {
            selIds: [],
            searchText: '',
            companys: [],
            confirmText: '등록',
            selCompany: { id: null, processName: '전체', processCode: '005000' },
            filterCompanys: [],
            showCode: false,
            showCompany: false,
            isAll: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {
        //this.condition.csId = this.basic.csId;
    },
    mounted() {
        this.getCompany();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        async getCompany() {
            const res = await SiteCompanyApi.list(this.basic.csId);
            this.filterCompanys = this.companys = res.content;
        },
        setFilterCompanys() {
            this.filterCompanys = this.companys.filter(item => item.company.name.indexOf(this.searchText) > -1);
        },
        async setCompany(company) {
            this.selCompany = company;
            const res = await SiteCompanyApi.detail(this.basic.csId, company.id);
            if (res.content.processCode) {
                this.selCompany.processCode = res.content.processCode.code || '005000';
                this.selCompany.processName = res.content.processCode.name || '전체';
            } else {
                this.selCompany.processCode = '005000';
                this.selCompany.processName = '전체';
            }

            this.confirmText = '수정';
        },
        clickCancel() {
            this.selCompany = { id: null };
            this.confirmText = '등록';
        },
        async clickConfirm() {
            if (confirm('새로운 업체를 등록하시겠습니까?')) {
                this.selCompany.sortNo = 100;
                const res = await CompanyApi.regist(this.selCompany);
                const params = {};
                params.site = { id: this.basic.csId };
                params.company = { id: res.content.id };
                params.processCode = { code: this.selCompany.processCode || '005000' };
                await SiteCompanyApi.regist(params);
                this.getCompany();
            }
        },
        onProcessCode() {
            this.showCode = true;
            /*
            this.$refs.processCode.setItem({
                name: this.selCompany.processName,
                code: this.selCompany.processCode
            });
            */
        },
        setCode(code) {
            this.selCompany.processName = code.name;
            this.selCompany.processCode = code.code;
        },
        async regCompany(items) {
            for (var company of items) {
                const params = {};
                params.site = { id: this.basic.csId };
                params.company = { id: company.id };
                await SiteCompanyApi.regist(params);
            }
            this.getCompany();
        },
        allSelect() {
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.selIds = this.filterCompanys.map(item => item.company.id);
            } else {
                this.selIds = [];
            }
        },
        async removeCompany() {
            if (this.selIds.length > 0 && confirm('삭제하시겠습니까?')) {
                for (var id of this.selIds) {
                    await SiteCompanyApi.remove(this.basic.csId, id);
                }
                this.selIds = [];
                this.getCompany();
            }
        }
    }
};
</script>
