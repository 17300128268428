import LogApi from '../api/LogApi';

const PREFIX_APP_AGENT = 'COCO_PC';

const isWebView = () => {
    const broswerInfo = navigator.userAgent;
    return broswerInfo.indexOf(PREFIX_APP_AGENT) > -1 ? true : false;
};

const getAppVersion = () => {
    const broswerInfo = navigator.userAgent;
    return broswerInfo.substring(broswerInfo.indexOf(PREFIX_APP_AGENT + '/') + PREFIX_APP_AGENT.length + 1);
};

const doLastAccess = () => {
    const param = {
        appVer: isWebView() ? getAppVersion() : process.env.VERSION,
        platform: 'pc-electron',
        userAgent: navigator.userAgent
    };
    LogApi.accessLog(param);
};

const windowExpand = () => {
    console.log('request-extend-resize-default');
};

const windowWorkDailyExpand = () => {
    console.log('request-extend-resize-daily');
};

const windowDefaultSize = () => {
    console.log('request-default-size');
};

const windowDefaultReSize = () => {
    console.log('request-resize-size');
};

const windowDefaultDaily = () => {
    console.log('request-daily-size');
};

const imageClipboard = imgSrc => {
    console.log('electron-img-copy|' + imgSrc);
};

const closeLoading = () => {
    console.log('electron-loding-out');
};

const setupFcmToken = workerId => {
    console.log('request-fcm-token|' + workerId);
};

const setupAlarm = (workerId, setting) => {
    // work, notice, sound
    const txt = workerId + '|' + setting.workYn + '|' + setting.noticeYn + '|' + setting.soundYn;
    console.log('request-alarm-setting|' + txt);
};

export default {
    isWebView,
    getAppVersion,
    doLastAccess,
    windowExpand,
    windowWorkDailyExpand,
    closeLoading,
    imageClipboard,
    windowDefaultSize,
    windowDefaultDaily,
    windowDefaultReSize,
    setupFcmToken,
    setupAlarm
};
