<template>
    <div class="modal modal-width-fixed modal-select-group on">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-nav">
                        <a class="back"><i /><span class="blind">뒤로가기</span></a>
                    </div>
                    <div class="header-title">
                        <span>업체 선택</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="confirm" @click="setCompany"><span>확인</span></a>
                    <span class="gap" />
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->

            <!-- modal-body -->
            <div class="modal-body">
                <div class="search-box">
                    <div class="search">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="업체명 검색"
                            @keyup="setFilterCompanys"
                        />
                    </div>
                </div>

                <div class="list-box">
                    <div class="select-box">
                        <input id="chk-add-comapny-all" type="checkbox" class="group-nm" @click="allSelect()" />
                        <label for="chk-add-comapny-all">전체 그룹</label>
                    </div>
                    <div class="list-scroll-box">
                        <!-- user-list -->
                        <div class="group-list">
                            <ul>
                                <li v-for="(company, index) in filterCompanys" :key="index">
                                    <input
                                        :id="'chk-add-company-' + index"
                                        v-model="items"
                                        type="checkbox"
                                        class="group-nm"
                                        :value="company"
                                    />
                                    <label :for="'chk-add-company-' + index">
                                        {{ company.name }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <!-- // user-list -->
                    </div>
                </div>
            </div>
            <!-- // modal-body -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
//import ArrayHelper from '../../helper/ArrayHelper';

//import SiteCompanyApi from '../../api/SiteCompanyApi';
import CompanyApi from '../../api/CompanyApi';

export default {
    name: 'PopSiteCompany',
    components: {},
    props: {
        existData: {
            type: Array,
            required: false,
            default: null
        }
    },
    data: () => {
        return {
            searchText: '',
            companys: [],
            filterCompanys: [],
            items: [],
            isAll: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        this.getCompanys();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        close() {
            this.$emit('update:show', false);
        },
        async getCompanys() {
            //현장관리자면 전체..code 다 가져오기
            const res = await CompanyApi.codeList();
            if (res.content) {
                console.log(this.existData);
                const excludeIds = this.existData.map(item => item.company.id);
                this.companys = res.content.filter(i => !excludeIds.includes(i.id));

                //res.content.filter(i => !this.existData.includes(i.id));
            }
            this.filterCompanys = this.companys; //= res.content;
        },
        setFilterCompanys() {
            this.filterCompanys = this.companys.filter(item => item.name.indexOf(this.searchText) > -1);
        },
        setCompany() {
            this.$emit('setData', this.items);
            this.close();
        },
        allSelect() {
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.items = this.filterCompanys.map(item => item);
            } else {
                this.items = [];
            }
        }
    }
};
</script>
