<template>
    <div class="site-wrapper">
        <div class="site-box-left">
            <div class="site-search-box">
                <div class="search-box">
                    <div class="search">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="업체 및 회원명 검색"
                            @input="setFilterWorker"
                        />
                    </div>
                </div>
                <div class="btn-box">
                    <a class="delete" @click="removeWorker"><i /><span>선택삭제</span></a>
                </div>
            </div>

            <div class="site-list-box">
                <div class="site-list-header">
                    <ul>
                        <li>
                            <span>
                                <input type="checkbox" @click="allSelect()" />
                            </span>
                            <span>No</span>
                            <span>업체명</span>
                            <span>성명</span>
                            <span>승인여부</span>
                            <span>가입일</span>
                        </li>
                    </ul>
                </div>

                <div class="site-list-body">
                    <ul>
                        <li
                            v-for="(worker, index) in filterWorkers"
                            :key="index"
                            :class="{ on: selWorker.id === worker.worker.id }"
                            @click="setWorker(worker)"
                        >
                            <span>
                                <input v-model="selIds" type="checkbox" :value="worker.worker.id" />
                            </span>
                            <span>{{ index + 1 }}</span>
                            <span>{{ worker.worker.company.name }}</span>
                            <span>{{ worker.worker.name }}</span>
                            <span>{{ worker.approveYn }}</span>
                            <span>{{ worker.regDt | longFormatTime }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="site-box-right">
            <div class="reg-info-header">
                <span>현장멤버 및 현장관리자 등록 관리</span>
                <div class="btn-box">
                    <a class="reg-company" @click="() => (showWorker = true)"><i /><span>새멤버 추가</span></a>
                </div>
            </div>
            <div class="reg-info-box">
                <div class="reg-info-box">
                    <div class="form-box">
                        <div class="field-box">
                            <div class="label">
                                성명
                            </div>
                            <div class="field">
                                <input v-model="selWorker.name" type="text" class="txt" readonly />
                            </div>
                        </div>

                        <div class="field-box">
                            <div class="label">
                                연락처
                            </div>
                            <div class="field">
                                <input v-model="selWorker.hp" type="text" class="txt" readonly />
                            </div>
                        </div>

                        <div class="field-box">
                            <div class="label">
                                업체명
                            </div>
                            <div class="field">
                                <input v-model="selWorker.company.name" type="text" class="txt" readonly />
                            </div>
                        </div>

                        <div class="field-box">
                            <div class="label">
                                직위/기타
                            </div>
                            <div class="field justify-space">
                                <input v-model="selWorker.positionName" type="text" class="txt half" readonly />
                                <input v-model="selWorker.positionEtc" type="text" class="txt half" readonly />
                            </div>
                        </div>

                        <div class="field-box">
                            <div class="label">
                                직종/기타
                            </div>
                            <div class="field justify-space">
                                <input v-model="selWorker.occupationsName" type="text" class="txt half" readonly />
                                <input v-model="selWorker.occupationsEtc" type="text" class="txt half" readonly />
                            </div>
                        </div>

                        <div class="field-box">
                            <div class="label">
                                승인여부
                            </div>
                            <div class="field">
                                <div class="field-inbox">
                                    <input id="rdo-1-1" v-model="selWorker.approveYn" type="radio" value="Y" />
                                    <label for="rdo-1-1">승인</label>

                                    <input id="rdo-1-2" v-model="selWorker.approveYn" type="radio" value="N" />
                                    <label for="rdo-1-2">미승인</label>
                                </div>
                            </div>
                        </div>

                        <div class="field-box">
                            <div class="label">
                                현장관리자
                            </div>
                            <div class="field justify-space">
                                <div class="field-inbox chk">
                                    <input
                                        id="chk12-1"
                                        v-model="isCsAdmin"
                                        type="checkbox"
                                        value="N"
                                        :disabled="itsMe(selWorker.id)"
                                    />
                                    <label for="chk12-1"> {{ getTxtCsAdmin(selWorker) }}</label>
                                </div>
                                <a class="send-sms"><span>APP 문자발송</span></a>
                            </div>
                        </div>

                        <div class="btn-box">
                            <a class="cancel" @click="clickCancel"><span>취소</span></a>
                            <a class="confirm" @click="clickConfirm"><span>확인</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pop-worker v-if="showWorker" :show.sync="showWorker" :exist-data="workers" @setData="regWorker" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
//import ArrayHelper from '../../helper/ArrayHelper';

import PopWorker from './PopWorker';

import AffilationSiteApi from '../../api/AffilationSiteApi';
export default {
    name: 'SiteWorker',
    components: { PopWorker },
    data: () => {
        return {
            selIds: [],
            searchText: '',
            workers: [],
            confirmText: '등록',
            selWorker: { id: null, name: '', company: {}, isCsAdmin: false, approveYn: 'N' },
            filterWorkers: [],
            showWorker: false,
            isCsAdmin: false,
            isAll: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {
        //this.condition.csId = this.basic.csId;
    },
    mounted() {
        this.getWorkers();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        itsMe(id) {
            return this.basic.id === id;
        },
        getTxtCsAdmin(user) {
            if (user.company.id === 2) return '현장관리자';
            else return '업체관리자';
        },
        async getWorkers() {
            const condition = {
                csId: this.basic.csId,
                page: 1,
                rows: 500,
                approveYn: null
            };
            const res = await AffilationSiteApi.listOfsite(condition);
            this.filterWorkers = this.workers = res.content;
        },
        setFilterWorker() {
            this.filterWorkers = this.workers.filter(
                item =>
                    item.worker.name.indexOf(this.searchText) > -1 ||
                    item.worker.company.name.indexOf(this.searchText) > -1
            );
        },
        async setWorker(item) {
            const res = await AffilationSiteApi.detail(this.basic.csId, item.worker.id);
            const csAdmin = await AffilationSiteApi.csAdminOfworker(this.basic.csId, item.worker.id);
            if (res.content) this.selWorker = { ...res.content.worker, approveYn: 'N' };
            this.selWorker.approveYn = res.content.approveYn;
            if (csAdmin) this.selWorker.csAdminYn = true;
            else this.selWorker.isCsAdmin = false;

            this.isCsAdmin = this.selWorker.csAdminYn;
        },
        clickCancel() {
            this.selWorker = { id: null, name: '', company: {}, csAdminYn: false, approveYn: 'N' };
        },
        async clickConfirm() {
            if (confirm('멤버를 수정하시겠습니까?')) {
                const res = await AffilationSiteApi.modify(this.basic.csId, this.selWorker.id, this.selWorker);
                if (res) {
                    if (this.selWorker.isCsAdmin !== this.isCsAdmin) {
                        if (this.isCsAdmin) {
                            const param = {
                                csId: this.basic.csId,
                                workerId: this.selWorker.id
                            };
                            await AffilationSiteApi.csAdminRegist(param);
                        } else await AffilationSiteApi.csAdminRemove(this.basic.csId, this.selWorker.id);
                    }
                }

                this.getWorkers();
            }
        },
        async regWorker(items) {
            for (var id of items) {
                const params = {};
                params.csId = this.basic.csId;
                params.workerId = id;
                params.approveYn = 'Y';
                //console.log(params);
                await AffilationSiteApi.regist(params);
            }
            this.getWorkers();
        },
        async removeWorker() {
            if (this.selIds.length > 0 && confirm('삭제하시겠습니까?')) {
                const idList = [];
                for (var id of this.selIds) {
                    const params = {};
                    params.csId = this.basic.csId;
                    params.workerId = id;
                    //console.log(params);
                    idList.push(params);
                }
                await AffilationSiteApi.removeList({ list: idList });
                this.selIds = [];
                this.getWorkers();
            }
        },
        allSelect() {
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.selIds = this.filterWorkers.map(item => item.worker.id);
            } else {
                this.selIds = [];
            }
        }
    }
};
</script>
