import MutationTypes from '../../MutationTypes';
import CompanyApi from '../../../api/CompanyApi';
import CodeApi from '../../../api/CodeApi';
export default {
    getPositionCodes: async ({ commit }) => {
        const list = await CodeApi.list('0020');
        const codes = list.content.map(code => ({ code: code.code, name: code.name }));
        commit(MutationTypes.SET_POSITION_CODE, codes);
    },
    getOccupationCodes: async ({ commit }) => {
        const list = await CodeApi.list('0030');
        const codes = list.content.map(code => ({ code: code.code, name: code.name }));
        commit(MutationTypes.SET_OCCUPATION_CODE, codes);
    },
    getCompany: async ({ commit }) => {
        const list = await CompanyApi.codeList();
        const codes = list.content;
        commit(MutationTypes.SET_COMPANY, codes);
    }
};
