<template>
    <div class="work-detail-box">
        <div class="work-detail-header">
            <div class="user-summary">
                <div class="user-thumb">
                    <span class="thumb">
                        <img
                            :src="item.register.imgUrl | profileUrl"
                            onerror="javascript:this.src='/images/example/img-profile.png'"
                        />
                    </span>
                </div>
                <div class="user-info">
                    <p class="user-nm">
                        {{ item.register.displayName }}&middot;
                        {{ item.register.company.name }}
                    </p>
                    <div class="user-member">
                        <div>
                            <span v-if="work.register.id === item.register.id" class="work-color">작업</span>
                            <span v-else class="work-color">조치</span>
                            <span>{{ item.regDt | longFormatTime }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <a v-if="isAuth()" class="more-nav" @click="toggleMoreBox(refIdx)">
                <i /><span class="blind">더보기</span>
            </a>
            <div :ref="'moreBox' + refIdx" class="more-nav-box" :data-key="refIdx">
                <ul>
                    <li><a class="view-modify" @click="showModify(item.id)">내용 수정</a></li>
                    <li><a @click="removeAction(item.id)">삭제</a></li>
                </ul>
            </div>
            <p class="comment" :inner-html.prop="convertCrToBr(item.content) | withLinks" />
        </div>

        <div v-if="imgs.length > 0" class="work-swiper swiper-container">
            <swiper :options="swiperOption">
                <swiper-slide v-for="(img, index) in imgs" :key="index" class="swiper-slide">
                    <img :src="img.url" onerror="javascript:this.src='/images/default.png'" @click="showPhoto" />
                </swiper-slide>
            </swiper>
            <div class="swiper-button-prev" :class="'action' + refIdx" />
            <div class="swiper-button-next" :class="'action' + refIdx" />
        </div>

        <div class="work-detail-body">
            <!-- tag-file-list-box -->
            <div class="tag-file-list-box">
                <ul>
                    <li v-for="(file, index) in docFileList" :key="index">
                        <a class="filename" @click="download(file)">
                            <span>{{ file.name }}</span>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- // tag-file-list-box -->
        </div>
        <div class="work-detail-etc">
            <a class="view-comment" @click="showComment(item.commentId)">
                <div class="comment-write"><span>댓글쓰기</span></div>
                <div class="comment-count">
                    <i />
                    <span>댓글 {{ item.countOfComment }}</span>
                </div>
            </a>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import FileApi from '../../api/FileApi';
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import { convertCrToBr } from '../../utils/string';

export default {
    name: 'ActionItem',
    components: { swiper, swiperSlide },
    props: {
        item: {
            type: Object,
            required: true
        },
        work: {
            type: Object,
            required: true
        },
        refIdx: {
            type: Number,
            required: true
        }
    },
    data: () => {
        return {
            isMore: false,
            imgs: [],
            /*swiperOption: {
                //direction: 'vertical',
                slidesPerView: 1,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },*/
            docFileList: []
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        }),
        swiperOption() {
            // `this` points to the vm instance
            return {
                //direction: 'vertical',
                slidesPerView: 1,
                navigation: {
                    nextEl: '.swiper-button-next.action' + this.refIdx,
                    prevEl: '.swiper-button-prev.action' + this.refIdx
                }
            };
        }
    },
    created() {},
    mounted() {
        if (this.item.file) this.imgs = this.item.file.detail || [];
        if (this.item.docFileId) {
            FileApi.list(this.item.docFileId).then(res => {
                this.docFileList = res;
            });
        }
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['showLoading', 'hideLoading', 'setModifyWorkActionId', 'setCommentId']),
        convertCrToBr,
        removeAction(id) {
            this.$refs['moreBox' + this.refIdx].classList.remove('on');
            this.$emit('remove', id);
        },
        async download(file) {
            await FileApi.fileDownload(file.id);
        },
        isAuth() {
            //return false;
            return this.item.register.id === this.basic.id;
        },
        showModify(id) {
            this.$refs['moreBox' + this.refIdx].classList.remove('on');
            const param = { workId: this.work.id, workActionId: id };
            this.setModifyWorkActionId(param);
        },
        toggleMoreBox(index) {
            this.$refs['moreBox' + index].classList.toggle('on');
        },
        showComment(commentId) {
            const param = { workActionId: this.item.id, commentId, type: 'commentOfaction' };
            this.setCommentId(param);
            //this.$emit('show', 'comment');
        },
        showPhoto() {
            console.log(this.item, this.imgs);
            this.$emit('showPhoto', this.item, this.imgs);
        }
    }
};
</script>
