import { profileUrl, thumbUrl, sThumbUrl } from '../../utils/image';

const VueImageFilter = {
    install(Vue) {
        Vue.filter('profileUrl', profileUrl);
        Vue.filter('thumbUrl', thumbUrl);
        Vue.filter('sThumbUrl', sThumbUrl);
    }
};

export default VueImageFilter;
