export const APPLICATION_PHASE = process.env.APPLICATION_PHASE || 'REAL';
export const APPLICATION_VERSION = process.env.VERSION;

export const PAGINATION = {
    first: true,
    last: false,
    numberOfElements: 10,
    size: 10,
    number: 1,
    totalPages: 1,
    totalElements: 1
};

/** 너무 세분화하지 말고 HEADER 를 구분하는 정도
 *  default :: json string
 */
export const API_URL = '/api';
export const DEFAULT_HEADER = { 'Content-Type': 'application/json' };
/*
export const FIELD_PAGE = 'page';
export const FIELD_SIZE = 'size';
*/

/* component별 사이즈 정리 */
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_ROUTE = '/';

export const MAIN_LIST_SIZE = 10;

export const CS_ID = 'csId';
export const CS_IDS = 'csIds';
export const WORKER_ID = 'workerId';
export const JWT_TOKEN = 'token';
export const JWT_REFRESH_TOKEN = 'refreshtoken';

export const SESSION_USER_INFO = 'userInfo';
export const SUPER_USER = 'admin';

export const WEATHER_SKY = {
    맑음: 'icon-01',
    구름조금: 'icon-02',
    구름많음: 'icon-03',
    흐림: 'icon-04',
    비: 'icon-05',
    소나기: 'icon-06',
    눈: 'icon-07',
    소낙눈: 'icon-08',
    '눈/비': 'icon-09',
    낙뢰: 'icon-10',
    안개: 'icon-11',
    황사: 'icon-12'
};

export const WORK_TYPE = {
    work: '작업',
    talk: '토크',
    picture: '사진'
};

export const WORK_TYPE_CSS = {
    work: 'work',
    talk: 'talk',
    picture: 'photo'
};

export const IMG_THUMB = '400X400';
