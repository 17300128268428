import MutationTypes from '../../MutationTypes';

export default {
    [MutationTypes.SET_MAIN_WEATHER]: (state, payload) => {
        state.weather = payload;
    },
    [MutationTypes.SET_MAIN_COUNT]: (state, payload) => {
        state.count = payload;
    },
    [MutationTypes.SET_MAIN_WORK]: (state, payload) => {
        state.work = payload;
    },
    [MutationTypes.SET_MAIN_BOARD]: (state, payload) => {
        state.board = payload;
    },
    [MutationTypes.SET_MAIN_BASIC]: (state, payload) => {
        state.basic = payload;
    },
    [MutationTypes.SET_OAUTH]: (state, payload) => {
        state.oauth = payload;
    },
    [MutationTypes.SET_IS_LOADING]: (state, payload) => {
        state.isLoading = payload;
    }
};
