import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';
import authHeader from './Header';

const list = typeCode => httpClient.get(`/code/list/${typeCode}`, { headers: authHeader() }).then(res => res.data);
const detail = code => httpClient.get(`/code/${code}`, { headers: authHeader() }).then(res => res.data);
export default {
    list: wrapHttpClient(list),
    detail: wrapHttpClient(detail)
};
