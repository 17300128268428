<template>
    <div class="site-wrapper">
        <div class="site-box-left">
            <div class="site-search-box">
                <div class="search-box">
                    <div class="search">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="그룹명 검색"
                            @input="setFilterRoom"
                        />
                    </div>
                </div>
                <div class="btn-box">
                    <a class="delete" @click="removeRoom"><i /><span>선택삭제</span></a>
                </div>
            </div>

            <div class="site-list-box">
                <div class="site-list-header">
                    <ul>
                        <li>
                            <span>
                                <input type="checkbox" @click="allSelect()" />
                            </span>
                            <span>No</span>
                            <span>그룹명</span>
                            <span>멤버수</span>
                            <span>생성일</span>
                        </li>
                    </ul>
                </div>

                <div class="site-list-body">
                    <ul>
                        <li
                            v-for="(room, index) in filterRooms"
                            :key="index"
                            :class="{ on: selRoom.id === room.id }"
                            @click="setRoom(room)"
                        >
                            <span>
                                <input
                                    v-if="room.countOfMember === 0"
                                    v-model="selIds"
                                    type="checkbox"
                                    :value="room.id"
                                />
                            </span>
                            <span>{{ index + 1 }}</span>
                            <span>{{ room.name }}</span>
                            <span>{{ room.countOfMember }}</span>
                            <span>{{ room.regDt | longFormatTime }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="site-box-right">
            <div class="reg-info-header">
                <span>그룹 및 소속멤버 관리</span>
            </div>
            <div class="reg-info-box">
                <div class="reg-info-box">
                    <div class="form-box">
                        <div class="field-box">
                            <div class="label">
                                그룹명
                            </div>
                            <div class="field">
                                <input v-model="selRoom.name" type="text" class="txt" />
                            </div>
                        </div>

                        <div class="field-box">
                            <div class="label">
                                방장
                            </div>
                            <div class="field with-btn">
                                <input
                                    type="text"
                                    class="txt"
                                    readonly
                                    :value="selRoom.master.name + ' ' + selRoom.master.company.name"
                                />
                                <a @click="changeOwner"><span>방장변경</span></a>
                            </div>
                        </div>

                        <div class="btn-box">
                            <a class="cancel" @click="clickCancel"><span>취소</span></a>
                            <a class="confirm" @click="clickConfirm"><span>확인</span></a>
                        </div>
                    </div>
                </div>
                <div class="reg-info-box">
                    <div class="form-box">
                        <div class="member-list-box">
                            <div class="member-list-header">
                                <p>소속멤버</p>
                                <div class="select-util">
                                    <ul>
                                        <li><a @click="clickRemoveWorker">선택삭제</a></li>
                                        <li><a @click="clickAddWorker">멤버추가</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="site-list-box">
                                <div class="site-list-header">
                                    <ul>
                                        <li>
                                            <span><input type="checkbox" @click="joinAllSelect()"/></span>
                                            <span>No</span>
                                            <span>멤버명</span>
                                        </li>
                                    </ul>
                                </div>

                                <div class="site-list-body" style="height:345px;">
                                    <ul>
                                        <li v-for="(worker, index) in joinWorkers" :key="index">
                                            <span>
                                                <input v-model="selWorkerIds" type="checkbox" :value="worker.id" />
                                            </span>
                                            <span>{{ index + 1 }}</span>
                                            <span>
                                                <span>{{ worker.displayName }}</span>
                                                <span class="sm">{{ worker.company.name }}</span>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pop-add-worker v-if="showPopAddWorker" :room="selRoom" @refresh="refreshWorkers" />
        <pop-owner-change ref="popOwnerChange" @refresh="refreshOwner" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
//import ArrayHelper from '../../helper/ArrayHelper';

import WorkRoomApi from '../../api/WorkRoomApi';
import WorkerApi from '../../api/WorkerApi';
import WorkRoomJoinApi from '../../api/WorkRoomJoinApi';

import PopAddWorker from '../popup/PopAddWorker';
import PopOwnerChange from '../popup/PopOwnerChange';

export default {
    name: 'SiteRoom',
    components: { PopAddWorker, PopOwnerChange },
    data: () => {
        return {
            selIds: [],
            searchText: '',
            rooms: [],
            confirmText: '등록',
            selRoom: { id: null, name: '', master: { name: '', company: { name: '' } } },
            filterRooms: [],
            showRoom: false,
            isCsAdmin: false,
            isAll: false,
            isJoinAll: false,
            joinWorkers: [],
            selWorkerIds: []
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic,
            showPopAddWorker: state => state.work.showPopAddWorker
        })
    },
    created() {
        //this.condition.csId = this.basic.csId;
    },
    mounted() {
        this.getRooms();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['setShowPopAddWorker']),
        async getRooms() {
            const condition = {
                csId: this.basic.csId,
                page: 1,
                rows: 500
            };
            const res = await WorkRoomApi.list(condition);
            this.filterRooms = this.rooms = res.content;
        },
        setFilterRoom() {
            this.filterRooms = this.rooms.filter(item => item.name.indexOf(this.searchText) > -1);
        },
        async setRoom(item) {
            this.selRoom = item;
            this.getJoinWorkers();
        },
        async getJoinWorkers() {
            this.joinWorkers = await WorkerApi.listOfroom(this.selRoom.id);
        },
        async refreshWorkers() {
            await this.getRooms();
            this.joinWorkers = await WorkerApi.listOfroom(this.selRoom.id);
        },
        async refreshOwner() {
            await this.getRooms();
            this.selRoom = this.rooms.find(item => item.id === this.selRoom.id);
        },
        clickCancel() {
            this.selRoom = { id: null, name: '', company: {}, csAdminYn: false };
        },
        async clickConfirm() {},
        async clickAddWorker() {
            this.setShowPopAddWorker(true);
        },
        async clickRemoveWorker() {
            if (this.selWorkerIds.length > 0 && confirm('삭제하시겠습니까?')) {
                await WorkRoomJoinApi.removeInWorkers(this.selRoom.id, { workers: this.selWorkerIds });
            }
            this.selWorkerIds = [];
            this.refreshWorkers();
        },
        async removeRoom() {
            //const idList = [];
            for (var id of this.selIds) {
                //const params = {};
                //params.csId = this.basic.csId;
                //params.roomId = id;
                //console.log(params);
                //idList.push(params);
                const res = await WorkRoomApi.remove(id);
                console.log(res);
            }
            this.getRooms();
        },
        allSelect() {
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.selIds = this.filterRooms.map(item => item.id);
            } else {
                this.selIds = [];
            }
        },
        changeOwner() {
            this.$refs.popOwnerChange.open(this.selRoom);
        },
        joinAllSelect() {
            this.isJoinAll = !this.isJoinAll;
            if (this.isJoinAll) {
                this.selWorkerIds = this.joinWorkers.map(item => item.id);
            } else {
                this.selWorkerIds = [];
            }
        }
    }
};
</script>
