<template>
    <div ref="filterOccupationLayer" class="modal modal-width-fixed modal-select-occupations">
        <div class="modal-wrapper">
            <!-- content-header -->
            <header class="content-header">
                <div class="header-box">
                    <div class="header-nav">
                        <a class="back"><i /><span class="blind">뒤로가기</span></a>
                    </div>
                    <div class="header-title">
                        <span>직종 선택</span>
                    </div>
                </div>
                <div class="header-util">
                    <a class="confirm" @click="doConfirm"><span>확인</span></a>
                    <span class="gap" />
                    <a class="modal-close" @click="close"><i /><span class="blind">닫기</span></a>
                </div>
            </header>
            <!-- // content-header -->

            <!-- modal-body -->
            <div class="modal-body">
                <div class="search-box">
                    <div class="search">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="직종 검색"
                            @keyup="setFilterOccupation"
                        />
                    </div>
                </div>

                <div class="list-box">
                    <div class="select-box">
                        <input id="chk-add-occupation-all" type="checkbox" class="sel-tag" @click="allSelect()" />
                        <label for="chk-add-occupation-all">전체 직종선택</label>
                    </div>
                    <div class="list-scroll-box">
                        <!-- user-list -->
                        <div class="item-list">
                            <ul>
                                <li v-for="(occupation, index) in filterOccupations" :key="index">
                                    <input
                                        :id="'chk-add-occupation-' + index"
                                        v-model="selectOccupations"
                                        type="checkbox"
                                        class="sel-item"
                                        :value="occupation"
                                    />
                                    <label :for="'chk-add-occupation-' + index">
                                        {{ occupation.id.code.name }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <!-- // user-list -->
                    </div>
                </div>
            </div>
            <!-- // modal-body -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import WorkDailyApi from '../../api/WorkDailyApi';

export default {
    name: 'PopOccupation',
    components: {},
    props: {},
    data: () => {
        return {
            occupations: [],
            filterOccupations: [],
            selectOccupations: [],
            searchText: '',
            isAll: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        //this.$emit('setOccupations', this.selectOccupations);
        //this.bind();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        close() {
            this.$refs.filterOccupationLayer.classList.remove('on');
            //this.tempTag = [];
        },
        setFilterOccupation() {
            this.filterOccupations = this.occupations.filter(
                occupation => occupation.id.code.name.indexOf(this.searchText) > -1
            );
        },
        delSearchText() {
            this.searchText = '';
            this.setFilterOccupation();
        },
        doConfirm() {
            //console.log(this.workerIds);
            this.$emit('setOccupations', this.selectOccupations);
            this.$refs.filterOccupationLayer.classList.remove('on');
            this.selectOccupations = [];
        },
        async bind(today) {
            const response = (await WorkDailyApi.companyOccupations(today)) || { content: [] };
            this.occupations = response.content.map(item => item);
            this.filterOccupations = this.occupations;
        },
        allSelect() {
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.selectOccupations = this.filterOccupations.map(item => item);
            } else {
                this.selectOccupations = [];
            }
        },
        open() {
            this.$refs.filterOccupationLayer.classList.add('on');
        }
    }
};
</script>
