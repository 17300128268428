import DataHelper from '../helper/DataHelper';

class BaseModel {
    constructor(baseJSON, options) {
        Object.assign(this, {
            ...options
        });
        this.payload = baseJSON;
    }

    getPayload() {
        //if (!this.payload.id) this.payload.whoregist = window.whoregist;
        return this.payload;
    }

    clonePayload() {
        return DataHelper.cloneObject(this.payload);
    }

    reset() {
        this.payload = {};
    }
}

export default BaseModel;
